import React from "react";
import "./App.css";
import Login from "./components/pages/Login/Login";
import Register from "./components/pages/Register/Register";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import VerifyUser from "./components/pages/VerifyUser/VerifyUser";
import CreatePassword from "./components/pages/CreatePassword/CreatePassword";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./routes/PrivateRoute";
import Wallet from "./components/pages/Wallet/Wallet";
import Stalking from "./components/pages/Stalking/Stalking";
import Swap from "./components/pages/Swap/Swap";
import Referral from "./components/pages/Referral/Referral";
import SocialActivity from "./components/pages/SocailActivity/SocialActivity";
import { Provider } from "react-redux";
import { store } from "./uiapi/redux/store";
import Offers from "./components/pages/Offers/Offers";
import Settings from "./components/pages/Settings/Settings";
import SupportCenter from "./components/pages/SupportCenter/SupportCenter";
import GuestRoute from "./routes/GuestRoute";
import YTPHistory from "./components/pages/YTPHIstory/YTPHistory";
import ResetPassword from "./components/pages/ResetPassword/ResetPassword";
import OffersTask from "./components/pages/OffersTask/OffersTask";

function App() {
  return (
    <Provider store={store}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<GuestRoute children={<Login />} />} />
              <Route path="/register" element={<Register />} />
              <Route path="/verify-email" element={<VerifyUser />} />
              <Route path="/create-password" element={<CreatePassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/dashboard"
                element={<PrivateRoute element={<Dashboard />} />}
              />
              <Route
                path="/wallet"
                element={<PrivateRoute element={<Wallet />} />}
              />
              <Route
                path="/staking"
                element={<PrivateRoute element={<Stalking />} />}
              />
              <Route
                path="/swap"
                element={<PrivateRoute element={<Swap />} />}
              />
              <Route
                path="/referral"
                element={<PrivateRoute element={<Referral />} />}
              />
              <Route
                path="/social-activity"
                element={<PrivateRoute element={<SocialActivity />} />}
              />
              <Route
                path="/offers"
                element={<PrivateRoute element={<Offers />} />}
              />
              <Route
                path="/offers-task"
                element={<PrivateRoute element={<OffersTask />} />}
              />
               <Route
                path="/profile"
                element={<PrivateRoute element={<>Profile</>} />}
              />
               <Route
                path="/settings"
                element={<PrivateRoute element={<Settings />} />}
              />
               <Route
                path="/support-center"
                element={<PrivateRoute element={<SupportCenter />} />}
              />
               <Route
                path="/ytp-history"
                element={<PrivateRoute element={<YTPHistory />} />}
              />
            </Routes>
          </Router>
        </AuthProvider>
    </Provider>
  );
}

export default App;
