import styled from 'styled-components';
import './table.css';
import { TableCell, TableContainer, TableRow } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)`
`;

export const StyledTableHeadCell = styled(TableCell)`
  white-space: nowrap;
  && {
    font-weight: bold;
    padding: 20px;
    background-color: #fafafa;
    border-bottom: unset !important;
    white-space: nowrap;
  }
`;

export const Title = styled.div`
  padding: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #fafafa;
  }
  &:hover {
    background-color: #F2F2F2;
  }
`;

export const NoDataContainer = styled.div`
  padding: 20px;
  display: flex; 
  justify-content: center;
  align-items: center;
  text-align: center;
`;


export const StyledTableBodyCell = styled(TableCell)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

  }

  abbr {
    text-decoration: none;
  }
`;