import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from '../../../utils/domUtils';

interface CountrySelectProps {
  onCountryChange: any;
}

export default function CountrySelect({ onCountryChange }: CountrySelectProps) {

  const handleCountryChange = (event: any, newValue: any) => {
    onCountryChange(newValue); // Pass the selected country to the parent component
  };

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: '50%', marginLeft: '20px' }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={handleCountryChange}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          style={{ height: '30px' }}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
