import React, { useEffect, useRef, useState } from "react";
import {
  HeaderContainer,
  Logo,
  MobileNav,
  NavItem,
  RightGrid,
  CurrencyDisplay,
  // NotificationBell,
  ProfilePic,
  KebabMenu,
  LeftGrid,
  LinkContainer,
  Divider,
  UserName,
  UserDropdown,
  CurrencyDisplayContent,
  SmallLogo,
  ArrowIcon,
} from "./Header.styles";
import logoImage from "../../../assets/images/yp-logo.png"; // Update with your logo path
import smallLogo from "../../../assets/images/ytp-logo.png"; // Update with your logo path
// import bellIcon from "../../../assets/images/bell.png"; // Update with your bell icon path
import { useDispatch, useSelector } from "react-redux";
import { NavLinks, getActiveNavLinks, getMobileNavigationLinks, getNavLinks, getProfileDropdownMenu } from "../../../utils/domUtils";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getFiatRateList } from "../../../uiapi/network/services/userService";
import {
  UserReducerType,
  userInitialState,
} from "../../../uiapi/redux/reducers/userReducers";
import { setActiveCurrencyInStore, setFiatRates, setToken, setUserData } from "../../../uiapi/redux/actions/userActions";
// import Notification from "./Notification";
import MenuIcon from '@mui/icons-material/Menu';
import { ResponseType } from "../../../uiapi/interface/uiModelTypes";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userState: UserReducerType = useSelector((state: any) => state.user);
  const currentPath = location?.pathname || '';
  const leftLinks: NavLinks[] = getNavLinks("left");
  const rightLinks: NavLinks[] = getNavLinks("right");
  const profileDropdown = getProfileDropdownMenu();

  //States
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [showDropdown, setDropdown] = useState(false);
  const [YTPvalue, setYTPValue] = useState("");
  const [activeCurrency, setActiveCurrency] = useState(userState.activeCurrency || 'INR');
  const [showCurrencyDropdown, setCurrencyDropdown] = useState(false);
  const [activeLink, setActiveLink] = useState<string>(getActiveNavLinks(currentPath));
  const [fiatList, setFiatList] = useState<Record<string, string>>({});
  const currencyRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigationDropdownRef = useRef<HTMLDivElement>(null);

  const handleLink = (e:any , element: NavLinks) => {
    e.stopPropagation();
    setIsNavVisible(!isNavVisible);
    setActiveLink(element.name);
    navigate(element.redirect);
  };

  const handleCurrencyChange = (value: string) => {
    setYTPValue(fiatList?.[value]);
    setCurrencyDropdown(!showCurrencyDropdown);
    setActiveCurrency(value);
    dispatch(setActiveCurrencyInStore(value));
  };

  const handleProfileLink = (data: NavLinks) => {
    setActiveLink('');
    setDropdown(false);

    if(data.name === "Logout") {
      dispatch(setUserData(userInitialState));
      dispatch(setToken(''));
      navigate(data.redirect);
    }
    else if(data.name === "Request Call Back") {
      window.open(`${process.env.REACT_APP_RequestCallBack}`, "_blank");
    } else {
      navigate(data.redirect);
    }
  };

  //Fetch the currencies name and its rate for 1ytp from server
  const fetchCurrencyDropdownData = async () => {
    try {
      const fiatRateList:ResponseType = await getFiatRateList();

      if(fiatRateList && fiatRateList?.success && fiatRateList?.data) {
        setFiatList(fiatRateList.data);
        dispatch(setFiatRates(fiatRateList.data));
        setYTPValue((fiatRateList.data?.[activeCurrency]) || 0)
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCurrencyDropdownData();
  },[]);

  useEffect(()=>{
    setActiveLink(getActiveNavLinks(currentPath));
  },[currentPath]);

  return (
    <HeaderContainer>
      <LeftGrid>
        <Logo src={logoImage} onClick={() => navigate('/dashboard')} alt="Logo" />
        <SmallLogo src={smallLogo} onClick={() => navigate('/dashboard')} alt="Logo" />
        <LinkContainer>
          {leftLinks.map((element, index) => {
            return (
              <NavItem
                key={index}
                className={`${element.name === activeLink ? "active" : ""}`}
                onClick={(e) => handleLink(e,element)}
              >
                {element.name}
              </NavItem>
            );
          })}
        </LinkContainer>
      </LeftGrid>
      <RightGrid>
        <LinkContainer>
          {rightLinks.map((element, index) => {
            return (
              <NavItem
                className={`${element.name === activeLink ? "active" : ""}`}
                key={index}
                onClick={(e) => handleLink(e,element)}
              >
                {element.name}
              </NavItem>
            );
          })}
        </LinkContainer>
        <CurrencyDisplay tabIndex={1} onBlur={() => setCurrencyDropdown(false)} ref={currencyRef}>
          1 YTP = {YTPvalue} {activeCurrency}{" "}
          <KeyboardArrowDownIcon
            style={{ cursor: "pointer" }}
            onClick={() => setCurrencyDropdown(!showCurrencyDropdown)}
          />
          {showCurrencyDropdown && (
            <CurrencyDisplayContent>
              {Object.entries(fiatList).map(([item], index) => {
                return (
                  <a onClick={() => handleCurrencyChange(item)} key={index}>
                    {item}
                  </a>
                );
              })}
            </CurrencyDisplayContent>
          )}
        </CurrencyDisplay>
        {/* <Notification /> */}
        <Divider />
        <UserName
          onClick={() => setDropdown(!showDropdown)}
          tabIndex={1}
          onBlur={()=> setDropdown(false)}
          ref={dropdownRef}
        >
          <ProfilePic>
            {userState.userData?.avatar ? (
              <img src={`${userState.userData?.avatar}`} />
            ) : (
              <>
                {userState.userData?.first_name?.charAt(0) || ''}{" "}
                {userState.userData?.last_name?.charAt(0) || ''}
              </>
            )}
          </ProfilePic>
          <span>
            {userState.userData?.first_name || ''}{" "}
            {userState.userData?.last_name || ''}
          </span>
          <ArrowIcon style={{ height: "20px", width: "20px", marginLeft: "10px" }}>
            {" "}
            <KeyboardArrowDownIcon />{" "}
          </ArrowIcon>
          {showDropdown && (
          <UserDropdown>
            {profileDropdown.map((data, index) => {
              return (
                <a key={index} onClick={(e) => handleProfileLink(data)}>{data.name}</a>
              )
            })}
          </UserDropdown>
          )}
        </UserName>
      </RightGrid>
      <KebabMenu tabIndex={1} onBlur={() => setIsNavVisible(false)} ref={navigationDropdownRef} onClick={() => setIsNavVisible(!isNavVisible)}><MenuIcon />

      {isNavVisible && (
        <MobileNav>
          {getMobileNavigationLinks().map((element, index) => {
            return (
              <NavItem
                className={`${element.name === activeLink ? "active" : ""}`}
                onClick={(e) => handleLink(e,element)}
                key={index}
              >
                {element.name}
              </NavItem>
            );
          })}
        </MobileNav>
      )}
      </KebabMenu>
    </HeaderContainer>
  );
};

export default Header;
