import React, { useState } from "react";
import './form.css';
import { Alert, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, TextField } from "@mui/material";
import { loginUser } from "../../../uiapi/network/services/userService";
import { LoginData, ResponseType } from "../../../uiapi/interface/uiModelTypes";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../Validation/validation";
import { useDispatch } from "react-redux";
import { setToken, setUserData } from "../../../uiapi/redux/actions/userActions";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface FormData {
    email: string;
    password: string;
    rememberMe: boolean;
    phone: number;
}

interface Errors {
    email: string;
    password: string;
    phone: string;
}

export default function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<FormData>({
        email: "",
        password: "",
        rememberMe: false,
        phone: 0
    });

    const [errors, setErrors] = useState<Errors>({
        email: "",
        password: "",
        phone: ""
    });

    const [backendError, setBackendError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [phoneValue, setPhoneValue] = useState<any>();

    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));

        // Clear validation error when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        setBackendError("");
    };

    const validateForm = (): boolean => {
        let isValid = true;
        const newErrors: Errors = {
            email: "",
            password: "",
            phone: ""
        };

        if (!showPhone && !formData.email.trim()) {
            newErrors.email = "Email is required";
            isValid = false;
        }

        if (!formData.password.trim()) {
            newErrors.password = "Password is required";
            isValid = false;
        }
        
        if (!showPhone && !isValidEmail(formData.email)) {
            newErrors.email = "Invalid email format";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        setBackendError("");

        if (validateForm()) {
            setLoading(true); // Set loading to true when submitting

            const loginData: LoginData = {
                email: formData.email,
                password: formData.password
            }

            const loginDataWithPhone: LoginData = {
                password: formData.password,
                phone_no: formData.phone || 0
            }

            try {
                const login: ResponseType = await loginUser(showPhone ? loginDataWithPhone : loginData);

                if (login && login.success) {
                    dispatch(setToken(login.data?.token || ''))
                    dispatch(setUserData(login.data?.user));
                    navigate('/dashboard');
                } else {
                    setBackendError(login?.message || '');
                }
            } catch (error) {
                console.error("An error occurred during login:", error);
            } finally {
                setLoading(false); // Set loading to false after the request completes
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handlePhoneNo = () => {
        setBackendError("");
        setShowPhone(!showPhone);
    }

    const handleForgotPassword = async () => {
        setBackendError("");
        navigate('/reset-password');
    }

    const handlePhoneChange = (value: any) => {
        setPhoneValue(value);
        setFormData((prevData) => ({
            ...prevData,
            phone: value,
        }));
    };

    return (
        <div>
            <div className="login-form">
                {showPhone ?
                <PhoneInput
                    country={'in'}
                    value={phoneValue}
                    onChange={handlePhoneChange}
                    placeholder="Your Phone Number"
                    enableSearch={true}
                />
                :
                <TextField
                    className="text-field"
                    label="Your Email"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="email"
                    type="text"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />}
                <TextField
                    className="text-field"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    type={showPassword ? "text" : "password"} // Use ternary operator to toggle password visibility
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{ // Add InputProps to include the icon button
                        endAdornment: (
                            <IconButton onClick={togglePasswordVisibility} edge="end" tabIndex={-1}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        ),
                    }}
                />
                <FormControlLabel
                    control={<Checkbox name="rememberMe" checked={formData.rememberMe} onChange={handleChange} />}
                    label="Remember me"
                    style={{ marginTop: '20px' }}
                />
            </div>
            <Button onClick={handleSubmit} variant="contained" fullWidth className="login-button" disabled={loading}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
            </Button>

            {backendError && <Alert className="backend-error" color="error">{backendError}</Alert>}

            {!showPhone && <a onClick={handleForgotPassword} className="forgot-password-link">
                Forgot Password?
            </a>}
            <a onClick={handlePhoneNo} className="forgot-password-link">
                {!showPhone ? 'or, Login via Phone no.' : 'or, Login via Email id.'}
            </a>
        </div>
    );
}
