import React from "react";
import { DisclaimerContainer, DisclaimerContent } from "./Disclaimer.styles";
import { Disclaimer1, Disclaimer2, Disclaimer3, Disclaimer4 } from "../../../utils/constants";
import { CardTitle, Divider } from "../Styles/CommonStyles";

interface DisclaimerProps {
    title?: string;
    list?: string[];
}

export default function Disclaimer(props: DisclaimerProps) {
    return(
        <DisclaimerContainer>
            <CardTitle>Disclaimer:</CardTitle>
            <Divider />
            {props.title && <div style={{marginTop: '20px'}}>{props.title} </div>}
            {props.list ? 
            <DisclaimerContent>
                {props.list.map((element) => {
                    return (
                        <li key={element}>{element}</li>
                    )
                })}
            </DisclaimerContent>
            :            
            <DisclaimerContent>
                <li>{Disclaimer1}</li>
                <li>{Disclaimer2}</li>
                <li>{Disclaimer3}</li>
                <li>{Disclaimer4}</li>
            </DisclaimerContent>
            }
        </DisclaimerContainer>
    )
}