import React, { useEffect } from "react";
import { Answer, ChatButton, ConnectContainer, FAQContainer, FAQItemWrapper, PlusMinusIcon, Question, SubTitle, Title } from "./FAQ.styles";
import { CardTitle, Divider } from "../Styles/CommonStyles";
import { FAQItem } from "../../../utils/domUtils";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import Avatar from '../../../assets/images/support-pic.svg';
import { getFAQDetails } from "../../../uiapi/network/services/utilService";
import { ResponseType } from "../../../uiapi/interface/uiModelTypes";

interface FAQProps {
  code: string;
}

export default function FAQ(props: FAQProps) {

    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
    const [faqData, setFaqData] = React.useState<FAQItem[]>([]);

    const toggleAnswer = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const fetchData = async() => {
      const faqData:ResponseType = await getFAQDetails(props.code);

      if(faqData && faqData.success && faqData?.data.length > 0) {
        let data: FAQItem[] = [];
        faqData.data.forEach((element: any) => {
          data.push({
            question: element.name,
            answer: element.description
          });     
        });
        setFaqData(data);
      } else {

      }
    }

    useEffect(()=> {
      fetchData();
    },[]);

    return (
      <FAQContainer>
        <CardTitle>FAQ:</CardTitle>
        <Divider />
        {faqData.length > 0 && faqData.map((item, index) => (
          <FAQItemWrapper key={index}>
            <Question onClick={() => toggleAnswer(index)}>
              <div>{item.question}</div>
              <PlusMinusIcon>{activeIndex === index ?  <RemoveCircleOutlineRoundedIcon/> : <AddCircleOutlineRoundedIcon />}</PlusMinusIcon>
            </Question>
            {activeIndex === index && <Answer>{item.answer}</Answer>}
          </FAQItemWrapper>
        ))}
        <ConnectContainer>
            <img src={Avatar} />
            <Title>Still have questions?</Title>
            <SubTitle>Can’t find the answer you’re looking for? Please chat to our friendly team.</SubTitle>
            <ChatButton>Get in touch</ChatButton>
        </ConnectContainer>
      </FAQContainer>
    );
}