import { BankAccountData, KYCData } from '../../interface/requestBodyTypes';
import * as types from '../../interface/uiModelTypes';
import { store } from '../../redux/store';
import { axiosWrapper } from './axiosWrapper';


export async function loginUser(loginData: types.LoginData) {

    const apiEndpoint: string = "/api/v1/users/auth/login/";

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: loginData,
        credentials: false,
    };

    return axiosWrapper(request);

}

export async function getOtp(otpdata: types.OtpData) {

    const apiEndpoint: string = "/api/v1/users/auth/first-register/";


    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: otpdata,
        credentials: false,
    };

    return axiosWrapper(request);
}

export async function getResetLink(email: string) {

    const apiEndpoint: string = "/api/v1/users/auth/resend-email-otp/";

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: {
            email: email
        },
        credentials: false,
    };

    return axiosWrapper(request);
}

export async function validateOtp(VerifyEmail: types.VerifyEmailData) {

    const apiEndpoint: string = "/api/v1/users/auth/verify-email/";

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: VerifyEmail,
        credentials: false,
    };

    return axiosWrapper(request);
}

export async function createPassword(createPassword: types.CreatePasswordData) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = "/api/v1/users/auth/second-register/";

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: createPassword,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function forgotPassword(changePassword: types.ChangePasswordData) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = "/api/v1/users/auth/forgot-password/";

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: changePassword,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}



export async function getYTPData(currency: string) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/ytp/value/fiat/${currency}/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getFiatList() {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/currency/fiat/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getFiatRateList() {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/coin/YTP/value/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}


export async function getCurrenciesValue(ticker: string = 'ytp') {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/coin/${ticker}/value/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}


export async function getConversionValue(ticker: string = 'ytp') {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/coin/${ticker}/coins-value/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function createKYC(data: KYCData) {

    const formData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('dob', data.dob);
    formData.append('gender', data.gender);
    formData.append('country', data.country);
    formData.append('id_proof', data.id_proof);
    formData.append('address_proof', data.address_proof);
    formData.append('selfie', data.selfie, 'photo.png');

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/kyc/create/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: formData,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getUserDetails() {

    const apiEndpoint: string = "/api/v1/users/auth/first-register/";
    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getKYCList() {

    const apiEndpoint: string = "/api/v1/finance/kyc/list/";
    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getBankDetailsList() {

    const apiEndpoint: string = "/api/v1/finance/bank-account-details/list/";
    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getNotifications() {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/coin/value/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function createBankDetails(data: BankAccountData) {

    const formData = new FormData();
    formData.append('account_holder_name', data.account_holder_name);
    formData.append('account_number', data.account_number);
    formData.append('ifsc_code', data.ifsc_code);
    formData.append('bank_name', data.bank_name);
    formData.append('upi_id', data.upi_id);

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/bank-account-details/create/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: formData,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export const getGraphData = (range: number) => {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `https://wallet.yatripay.com/api/v1/ytp/price/chart/${range}`;

    const request: types.axiosRequest = {
        method: 'get',
        fullUrl: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}