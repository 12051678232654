import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CopyIcon from "../../../../assets/images/Copy.svg"; // Import your copy icon image
import QRCode from "../../../../assets/images/QRcode.svg";
import Dropdown from "../../../common/Dropdown/Dropdown";
import { getCoinList } from "../../../../uiapi/network/services/utilService";
import QRCodeComponent from "./QRcode";
import { Alert, Tooltip } from "@mui/material";
import { ResponseType } from "../../../../uiapi/interface/uiModelTypes";

const DepositCard = styled.div`
  width: 100%;
`;

const TabComponentContainer = styled.div`
  padding: 20px 0px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  @media (max-width: 630px) {
    flex-direction: column;
  }
`;

const QRCodeWrapper = styled.div`
  margin-left: 10px;

  @media (max-width: 630px) {
    margin-top: 20px;
    margin-left: unset;
  }
`;

const CopyIconImage = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 20px;
  color: pink;
  cursor: pointer;
`;

const ValueField = styled.div`
  position: relative;
  width: 50%;
  margin-right: 10px;
  margin-left: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 40px 12px 12px; /* Add extra padding for the icon */
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  box-sizing: border-box; /* Ensure padding doesn't affect input width */

  &:focus {
    outline: none;
    border: 1px solid #bdbdbd;
  }
`;

interface Props {
   data: any;
   callWallet: (data: string) => void;
   alert: string;
}

const DepositCardComponent = (props: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopyText = () => {
    navigator.clipboard.writeText(props.data?.address).then(() => {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 500);
    })
    .catch((error) => {
      console.error("Unable to copy referral link:", error);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setError('');
      setLoading(true);
      try {
        const liveCrypto:ResponseType = await getCoinList();
        if (liveCrypto && liveCrypto?.success && liveCrypto?.data?.length > 0) {
          const list: any[] = liveCrypto.data.slice(0, 10).map((element: any) => ({
            label: element.name,
            value: element.ticker,
          }));
          setData(list);
        }
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  const handleDataChange = (value: any) => {
    setError('');
    props.callWallet(value.value || '');
  }

  return (
    <DepositCard>
      {props.alert && <Alert style={{marginTop: '20px'}} color="error">{props.alert}</Alert>}
      <TabComponentContainer>
        <div>
          <ContentContainer>
            <Dropdown
              width={"50%"}
              placeholder={"Select"}
              options={data}
              selected={"Yatripay"}
              handleDataChange={handleDataChange}
            />
            <ValueField>
              <Input
                type="text"
                placeholder={props.data?.address ? "" : `No Address Found for the respective coin`}
                value={props.data?.address || ''}
                disabled
              />
              <Tooltip 
                title={"Copied"} 
                open={showTooltip}
                onClose={() => setShowTooltip(false)}
                disableFocusListener
                disableHoverListener
                disableTouchListener             
              >
                <CopyIconImage
                  src={CopyIcon}
                  alt="copy"
                  onClick={handleCopyText}
                />
              </Tooltip>
            </ValueField>

            {/* QR code */}
            <QRCodeWrapper>
              {props.data?.qr_code_image && <QRCodeComponent base64ImageData={props.data?.qr_code_image || ''} />}
            </QRCodeWrapper>
          </ContentContainer>
        </div>
      </TabComponentContainer>
    </DepositCard>
  );
};

export default DepositCardComponent;
