import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const TicketCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
    color: #212529 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
`;

const HelperText = styled.div`
    color: #878787 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
`;

const DataRow = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DataItem = styled.div`
  flex-basis: calc(33.33% - 10px);
  margin-bottom: 10px;
  text-align: center;
  color: #878787;
  border-right: 2px solid #F2F2F2;

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
    border-right: unset;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const Ticket: React.FC = () => {
  const [data, setData] = useState<any>({
    totalTickets: '2',
    openTickets: '3',
    closedTickets: '45'
  });

  useEffect(() => {
    // Fetch data from backend API
    // fetchData();
  }, []);

  const fetchData = async () => {
    try {

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <TicketCard>
      <TitleRow>
        <Title>Tickets</Title>
      </TitleRow>
      <DataRow>
        <DataItem>Total Tickets <div style={{color: "black", fontWeight:'600'}}>{data.totalTickets}<span style={{color: '#878787', fontWeight:'400'}}> </span></div></DataItem>
        <DataItem>Open Tickets <div style={{color: "black",fontWeight:'600'}}>{data.openTickets}<span style={{color: '#878787',fontWeight:'400'}}> </span></div></DataItem>
        <DataItem>Closed Tickets <div style={{color: "black",fontWeight:'600'}}>{data.closedTickets}<span style={{color: '#878787',fontWeight:'400'}}> </span></div></DataItem>
      </DataRow>
    </TicketCard>
  );
};

export default Ticket;
