import React, { useEffect, useState } from "react";
import {
  CardTitleRow,
  Divider,
} from "../../../common/Styles/CommonStyles";
import {
  TaskConatiner,
  TasksListContainer,
  TaskBox,
  TaskHeplerText,
  TaskDetailsBox,
  DataRow,
  OffersContainer,
  StepsAway,
  TaskButton,
} from "./TaskCard.styles";
import LockIcon from "@mui/icons-material/Lock";
import HourGlass from "../../../../assets/images/hourglass.png";
import {
  createTask,
  getSubTaskList,
  getTasksList,
} from "../../../../uiapi/network/services/utilService";
import {
  ResponseType,
  SubTask,
  Task,
} from "../../../../uiapi/interface/uiModelTypes";
import { UserReducerType } from "../../../../uiapi/redux/reducers/userReducers";
import { useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import { getButtonText } from "../../../../utils/domUtils";

interface TaskCardProps {}

const TaskCard = (props: TaskCardProps) => {
  const userState: UserReducerType = useSelector((state: any) => state.user);
  const [activeTaskDetails, setActiveTaskDetails] = useState<SubTask[]>([]);
  const [activeTaskIndex, setActiveTaskIndex] = useState<number>(0);
  const [taskList, setTaskList] = useState<Task[]>([]);

  const isAllTaskDone = taskList.length > 0 && (taskList?.[taskList?.length - 1].id === activeTaskIndex ? 
    (activeTaskDetails?.[activeTaskDetails.length - 1]?.status === "COMPLETED")  ? true : false : false);

  const fetchData = async () => {
    const list: ResponseType = await getTasksList();

    if (list.success && list.data?.length > 0) {
      setTaskList(list?.data || []);
      list?.data?.filter(async (item: Task) => {
        if (item?.is_active) {
          setActiveTaskIndex(item?.id - 1);
          const subtask: ResponseType = await getSubTaskList(item?.id || 1);
          
          if (subtask.success) {
            setActiveTaskDetails(subtask?.data);
          } else {
          }
          return true;
        }
        return false;
      });
    }
  };

  const handleStartTask = async (taskId: number) => {
    const task: ResponseType = await createTask(
      userState?.userData?.id,
      taskId
    );

    if (task.success) {
      fetchData();
    }
  };

  const renderTaskDetails = () => {
    if (!activeTaskDetails) return null;

    return (
      <TaskDetailsBox>
        <h3>Task {activeTaskIndex + 1}</h3>
        <Divider />
        {activeTaskDetails?.map((item: SubTask) => (
          <ul>
            <li>{item?.name || ""}</li>
            <TaskButton
              status={(item?.status).toLowerCase() || "start"}
              onClick={() => handleStartTask(item?.id)}
              disabled={(item?.status).toLowerCase() !== "start"}
            >
              {getButtonText(item?.status)}
            </TaskButton>
          </ul>
        ))}
      </TaskDetailsBox>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TaskConatiner>
      <OffersContainer>
        <img src={taskList?.[activeTaskIndex]?.banner} />
      </OffersContainer>
      <CardTitleRow>
        <TasksListContainer>
          {taskList.map((item: Task, index) => {
            return (
              <DataRow key={index}>
                {item?.is_locked && !item?.is_active ? (
                  <LockIcon
                    style={{ color: "#ffdb58", height: "20px", width: "20px" }}
                  />
                ) : (
                  <>
                    {!item.is_locked && !item?.is_active ? (
                      <VerifiedIcon
                        color="success"
                        style={{ height: "20px", width: "20px" }}
                      />
                    ) : (
                      <img
                        src={HourGlass}
                        alt="hourglass"
                        style={{ height: "20px", width: "20px" }}
                      />
                    )}
                  </>
                )}
                <TaskHeplerText>Task {item?.id}</TaskHeplerText>
                <TaskBox
                  locked={item?.is_locked && !item.is_active}
                  completed={!item.is_locked && !item.is_active}
                >
                  {!item.is_locked && !item?.is_active
                    ? "Successfully Completed 😎"
                    : item.name || ""}
                </TaskBox>
              </DataRow>
            );
          })}
        </TasksListContainer>
        {renderTaskDetails()}
      </CardTitleRow>
      <StepsAway isTaskDone={isAllTaskDone}>
        {isAllTaskDone
          ? `Congratulations you have completed all the tasks. 🥳`
          : `Hurry! You’re just ${
              taskList?.length - activeTaskIndex
            } steps away`}
      </StepsAway>
    </TaskConatiner>
  );
};

export default TaskCard;
