import { Button, Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { MuiButton } from '../Styles/CommonStyles';


export const CardContainer = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 20px 20px;
  margin-left: 10px;
  // height: 96%;

  @media(max-width: 899px) {
    margin-top: 25px;
    margin-left: unset;
  }

  @media(max-width: 420px) {
    padding: 2px 5px 40px 5px;
  }
`;

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: #000; // Adjust the color to match the design
  }
`;

export const StyledTab = styled(Tab)`
  width: 50%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 25px;
  letter-spacing: 0em;
  &.Mui-selected {
    color: #000 !important; // Adjust the color to match the design
  }
`;

export const TabPanelContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const Input = styled.input`
  width: 85%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px !important;
  height: 30px;
  border: 1px solid #E0E0E0;
  border-radius: 4px;

  &:focus {
    outline: none;
    border: 1px solid #bdbdbd;
  }
`;

// export const Button = styled.button`
//   background-color: #B11F72; // Match the color to your design
//   color: #fff;
//   display: block;
//   border: none;
//   height: 52px;
//   padding: 10px 5%;
//   border-radius: 10px;
//   cursor: pointer;
//   font-weight: bold;
//   font-family: poppins;
//   font-size: 16px;
//   margin-top: 10px;
//   margin-bottom: 0px;

//   &:disabled {
//     cursor: not-allowed;
//     opacity: 0.6;
//   }
// `;


export const AvailableBalanceButton = styled.div`
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0px 20px;
  background-color: #B11F72;
  color: white;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

export const ModalDescriptionContainer = styled.div`
  background: #FFE4F3;
  margin-top: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #B11F72;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;

  div {
    padding: 10px;
  }
`

export const PaymentButtonContainer = styled.div`
  display: flex;
  // width: 30%;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;

`;

interface Props {
  isActive: boolean;
}

export const PaymentButton = styled.div<Props>`
  padding: 10px 20px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  border-radius: 10px;
  border: ${(props) => (props).isActive ? "2px solid #B11F72" : "1.5px solid #E0E0E0"};
  margin-bottom: 10px;
  cursor: pointer;
  color: ${(props) => (props).isActive ? "#B11F72" : "black"};
`;

export const ValueField = styled.div`
  position: relative;
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
`;

export const InputFields = styled.input`
  width: 100%;
  padding: 12px 40px 12px 12px; /* Add extra padding for the icon */
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  box-sizing: border-box; /* Ensure padding doesn't affect input width */

  &:focus {
    outline: none;
    border: 1px solid #bdbdbd;
  }
`;

export const CopyIconImage = styled.img`
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  width: 20px;
  color: pink;
  cursor: pointer;
`;

export const UPIIDContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  
  @media(max-width: 900px) {
    flex-direction: column;
  }

`

export const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #B11F72;
    margin-right: 10px;
  }
  
`;

export const QRImage = styled.div`
   height: 90%;
   width: 90%;

  @media(max-width: 900px) {
    height: 50%;
    width: 50%;
  }
`;

export const SubmitButton = styled(MuiButton)`
  width: 40% !important;
  margin: auto !important;
  display: block !important;
`;

export const AddMoneyContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

`

export const AddMoney = styled.div`
  width: 50px;
  cursor: pointer;
  margin-top: 15px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
`;

export const BankLink = styled.a`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;

`;