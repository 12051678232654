import React, { useEffect, useState } from "react";
import {
  getStakingDataBasedOnType
} from "../../../uiapi/network/services/utilService";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import {
  LockedStakingContainer,
  Title,
  Content,
  YatriPayText,
  ProgressBarContainer,
  DaysContainer,
  Day,
  Statistics,
  Stat,
  ModalTitle,
  AvailableBalanceButton,
  BuySubscriptionContainer,
  RightContent,
  ModalRightTitle,
  RowContainer,
  RowLabel,
  RowValue,
  LeftContent,
  TextFieldContainer,
  MinMaxStake,
  StyledTextField,
  AgreementLink,
  InputFields,
  ValueField,
  InputTextSupport

} from "./LockedStakingCard.styles";
import { Alert, Box, Checkbox, Modal, TextField, useTheme } from "@mui/material";
import CrossIcon from "../../../assets/images/Cross.svg";
import {
  ModalDescriptionContainer,
  SubmitButton,
} from "../YTPSell/YTPSell.styles";
import { SubTitle } from "../FAQ/FAQ.styles";
import Dropdown from "../../common/Dropdown/Dropdown";
import { convertEpoch } from "../../../utils/utils";
import { createStake, getWalletBalance } from "../../../uiapi/network/services/walletService";
import { ResponseType, StakingProgram } from "../../../uiapi/interface/uiModelTypes";
import { Option } from "../../../uiapi/interface/uiModelTypes";
import { MuiButton } from "../Styles/CommonStyles";


interface SummaryDate {
  [key: string]: any; // Define the type of values in summaryDate
}

interface StakingCardProps {
    data: StakingProgram;
    availableSubTypes: Option[];
}

interface ModalData {
  'subscription_type': string,
  'subscription_validity': number,
  'min_stake': number,
  'max_stake': number,
  'subscription_price': number,
  'per_annum': number,
  'quota_left': number,
  'staking_id': number
}


const LockedStakingCard = (props: StakingCardProps) => {
  const theme = useTheme();

  const [lockAmount, setLockAmount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [walletData, setWalletData] = useState<any>({});
  const [summaryData, setSummaryData] = useState<SummaryDate>(
    {
      "Start Date": props.data.start_at,
      "End Date": props.data?.deactive_at || '',
      "Return Period": props.data.return_period,
      "Per Annum": props.data.per_annum,
      "Estimated Return": 0,
      "Subscription Price": props.data.price,
      "Locked Amount": '0 YTP',
      "Transaction Fees": 0,
      "Total Amount": 0
    }
  );
  const [selectedDropdown, setSelectedDropdown] = useState<string>(props.data.name);
  const [serverError, setServerError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [modalData, setModalData] = useState<ModalData>({
    'subscription_type': props.data?.name || '',
    'subscription_validity': props.data?.validity || 7,
    'max_stake': props.data?.max_stake,
    'min_stake': props.data?.min_stake,
    'subscription_price': props?.data.price,
    'per_annum': props.data?.per_annum,
    'quota_left': props.data?.quota_left,
    'staking_id': props.data?.id
  });

  let totalAmount:number =  (lockAmount - (-summaryData?.['Subscription Price']));
  let returnAmount:number =  ((lockAmount/100)*(modalData.per_annum/365)*modalData?.subscription_validity) - (-lockAmount) || 0;

  async function fetchData() {
    const walletBalance:ResponseType = await getWalletBalance();

    if(walletBalance && walletBalance?.success && walletBalance?.data) {
      //Update state with fetched data
      setWalletData(walletBalance.data);
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  const handleStakeNowClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setServerError("");
    setRememberMe(false);
    setLockAmount(0);
    setModalOpen(false);
  };

  const handleLockAmount = (e: any) => {
    setServerError("");
    setLockAmount(e.target.value);

  }

  const handleCreateStake = async() => {
    const response = await createStake(modalData.staking_id, totalAmount, rememberMe);

    if(response.data) {
      setModalOpen(false);
    } else {
      setServerError(response);
    }
  }

  const handleCheckbox = (e: any) => {
    setRememberMe(e.target.checked || false);
  }

  const handleDataChange = async(selected: Option) => {
    setSelectedDropdown(selected.label);
    setServerError("");

    const data:ResponseType = await getStakingDataBasedOnType(selected.label || '');

    if(data && data?.success && data?.data) {
      setModalData({
        'subscription_type': data.data?.name,
        'subscription_validity': data.data?.validity,
        'subscription_price': data.data?.price,
        'max_stake': data.data?.max_stake,
        'min_stake': data.data?.min_stake,
        'per_annum': data.data?.per_annum,
        'quota_left': data.data?.quota_left,
        'staking_id': data.data?.id
      });
      setSummaryData({
        "Start Date": data.data?.start_at,
        "End Date": data.data?.deactive_at || '',
        "Return Period": data.data?.return_period,
        "Per Annum": data.data?.per_annum,
        "Estimated Return": 0,
        "Subscription Price": data?.data.price,
        "Locked Amount": 0,
        "Transaction Fees": 0,
        "Total Amount": 0
      });
    }
  }

  return (
    <LockedStakingContainer>
      <Title>Staking</Title>
      <Content>
        <YatriPayText>YatriPay</YatriPayText>
        <ProgressBarContainer>
          <CircularProgressBar
            percentage={props.data.per_annum}
            radius={50}
            strokeWidth={5}
          />
        </ProgressBarContainer>
        <DaysContainer>
            <Day
              selected={true}
            >
              {`${props.data.validity} Days`}
            </Day>
        </DaysContainer>
        <MuiButton onClick={handleStakeNowClick}>Stake Now</MuiButton>
      </Content>
      <Statistics>
        <Stat>
          <div>Subscriptions</div>
          <div>{props.data.subscribers}</div>
        </Stat>
        <Stat>
          <div>Rewards Earned</div>
          <div>{props.data?.referral_reward.toFixed(2)} YTP</div>
        </Stat>
        <Stat>
          <div>Quota Left</div>
          <div>{props.data?.quota_left.toFixed(2)} YTP</div>
        </Stat>
      </Statistics>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            border: "none",
            boxShadow: 24,
            padding: "20px 60px",
            borderRadius: "8px",
            width: "40%",
            [theme.breakpoints.down(1300)]: {
              width: "60%",
            }, 
            [theme.breakpoints.down(1200)]: {
              height: "80vh",
              overflow: "auto",
            },
             
          }}
        >
          <img
            src={CrossIcon}
            onClick={handleModalClose}
            style={{ cursor: "pointer", position: "absolute", right: 10 }}
          />
          <ModalTitle id="modal-title">Buy Subscription for Staking</ModalTitle>
          <div>
            <AvailableBalanceButton>
              Available Balance : {walletData?.balance?.toFixed(2) || 0} YTP
            </AvailableBalanceButton>
          </div>
          <BuySubscriptionContainer>
            <LeftContent>
              <Dropdown
                width={"100%"}
                options={props.availableSubTypes}
                placeholder={"Subscription Type"}
                selected={selectedDropdown}
                handleDataChange={handleDataChange}
              />
              <TextFieldContainer>
                  <ValueField>
                    <InputFields
                      type="text"
                      placeholder="Subscription Validity"
                      disabled
                    />
                    <InputTextSupport>{`${modalData && modalData?.subscription_validity || 0} Days`}</InputTextSupport>
                  </ValueField>
              </TextFieldContainer>
              <MinMaxStake>
                <span>Min. Stake : {modalData?.min_stake}</span>
                <span>Max. Stake :  {modalData?.max_stake}</span>
              </MinMaxStake>
              <TextFieldContainer>
                  <ValueField>
                    <InputFields
                      type="text"
                      placeholder="Subscription Price"
                      disabled
                    />
                    <InputTextSupport>{`${modalData.subscription_price === 0 ? 'Free' : modalData.subscription_price }`}</InputTextSupport>
                  </ValueField>
              </TextFieldContainer>
              <TextFieldContainer>
                <StyledTextField
                  className="text-field"
                  label="Lock Amount"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="number"
                  name="amount"
                  onChange={handleLockAmount}
                />
              </TextFieldContainer>
              <ModalDescriptionContainer style={{padding: '20px 0px'}}>
                Available Quota: {modalData.quota_left.toFixed(2)} YTP
              </ModalDescriptionContainer>
            </LeftContent>
            <RightContent>
              <ModalRightTitle>Summary</ModalRightTitle>
              {Object.entries(summaryData).map(([key, value]) => (
                <RowContainer key={key}>
                    <RowLabel>{key}</RowLabel>
                    <RowValue>{(key === "Start Date" || key === "End Date") ? (value ? convertEpoch(value) : '') : (key === "Return Period" ? ('Daily') : 
                    (key === "Per Annum" ? `${value} %` : ( key === "Locked Amount" ?  `${lockAmount} YTP`  : ( key === "Total Amount" ?  `${totalAmount} YTP` :  (key === "Estimated Return" ?   returnAmount.toFixed(4)  : `${value} YTP`))))) }</RowValue>
                </RowContainer>
             ))}
            </RightContent>
          </BuySubscriptionContainer>
          { serverError && <Alert  style={{ textAlign: 'center', marginTop: '10px' }} color="error"> {serverError} </Alert>}
          <SubTitle style={{ textAlign: "center", padding: "20px " }}>
            <Checkbox name="rememberMe" onChange={handleCheckbox} />I have read and I
            agree to <AgreementLink href={`${process.env.REACT_APP_Agreement}`} target="_blank">YatriPay Staking Service Agreement</AgreementLink>
          </SubTitle>
          <SubmitButton disabled={!rememberMe} onClick={handleCreateStake}>Confirm</SubmitButton>
        </Box>
      </Modal>
    </LockedStakingContainer>
  );
};

export default LockedStakingCard;
