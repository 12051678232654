import { Button, Grid, TextField } from "@mui/material";
import styled from "styled-components";

export const LockedStakingContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  height: 90%;
  flex-direction: column;
  // max-width: 396px;
  margin: auto;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ModalTitle = styled.div`
  color: #212529 !important;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #f2f2f2;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

export const AvailableBalanceButton = styled.div`
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0px 20px;
  background-color: #b11f72;
  color: white;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

export const ModalLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Dropdown = styled.select`
  /* Define your dropdown styles here */
`;

export const Input = styled.input`
  /* Define your input styles here */
`;

export const ModalRight = styled.div`
  border: 1px solid green;
  background: #fafafa;
  padding: 20px;

  border-radius: 10px;
`;

export const ModalRightTitle = styled.div`
  color: #b11f72 !important;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 25px;
  letter-spacing: 0em;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RowLabel = styled.div`
  font-weight: bold;
  padding: 10px 10px;
  white-space: nowrap;
`;

export const RowValue = styled.div`
  padding: 10px 10px;
`;

export const Title = styled.div`
  color: #212529 !important;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 2px solid #f2f2f2;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

export const Content = styled.div`
  display: flex;
  padding: 20px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const YatriPayText = styled.div`
  margin-right: 10px;
  color: #212529 !important;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  @media (max-width: 1300px) {
    margin-bottom: 20px;
  }
`;

export const ProgressBarContainer = styled.div`
  background: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

interface progress {
  percentage: any;
}
export const ProgressBar = styled.div<progress>`
  background: conic-gradient(
    #595c5f ${(props) => props.percentage}%,
    #f2f2f2 0
  );
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Percentage = styled.div`
  color: #212529;
  font-size: 14px;
  font-weight: bold;
`;

export const DaysContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

interface DayProps {
  selected?: boolean;
  hoverColor?: string;
}

export const Day = styled.div<DayProps>`
  color: ${(props) => (props.selected ? "white" : "#212529")} !important;
  padding: 10px;
  cursor: pointer;
  border: 1.52px solid
    ${(props) => (props.selected ? props.hoverColor || "#B11F72" : "#F2F2F2")};
  background-color: ${(props) =>
    props.selected ? props.hoverColor || "#B11F72" : "transparent"};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  margin: 5px;
  border-radius: 8px;
  text-align: center;

  &:hover {
    // color: white !important;
    // background-color: ${(props) => props.hoverColor || "#B11F72"};
  }
`;

export const MuiButton = styled(Button)`
  background-color: #b11f72 !important; // Match the color to your design
  color: #fff !important;
  display: block !important;
  border: none !important;
  height: 52px !important;
  min-width: unset !important;
  padding: 10px 2% !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  font-weight: bold !important;
  font-family: poppins !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-transform: capitalize !important;

  &:hover {
    background-color: #b11f72 !important; // Match the color to your design
  }
`;

export const Statistics = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 2px solid #f2f2f2;
  border-radius: 10px;
  padding: 15px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Stat = styled.div`
  color: #878787;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 20px;

  div:nth-child(2) {
    color: #212529;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
`;

export const BuySubscriptionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  width: 50%;
  position: relative;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  width: 50%;
  margin-left: 10px;
  position: relative;
  flex-direction: column;
  background: #fafafa;
  border-radius: 10px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const TextFieldContainer = styled.div`
  margin-top: 20px;


`;

export const StyledTextField = styled(TextField)`
  &:focus {
    outline: none !important;
    border: none !important;
  }
`;

export const MinMaxStake = styled.div`
  margin-top: 20px;

  span:nth-child(2) {
    margin-left: 20px;
  }
`;

export const AgreementLink = styled.a`
  text-decoration: none;
  color: #b11f72;
`;