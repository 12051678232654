import React, { useState } from "react";
import styled from "styled-components";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DropdownWrapper = styled.div<{ fullWidth: string }>`
  position: relative;
  width:  ${({ fullWidth }) => fullWidth };

  @media (max-width: 650px) {
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "unset")};
    margin-top: ${({ fullWidth }) => (fullWidth ? "20px" : "0")};
  }
`;

const DropdownButton = styled.button`
  width: 100%;
  padding: 12px 12px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 999;
`;

const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fa;
    border-radius: 8px;
  }

`;

interface Option {
  label: string;
  value: string;
  selected?: boolean;
}

const Dropdown: React.FC<{
  width: string;
  options: Option[];
  placeholder: string;
  selected?: string;
  handleDataChange?: (data: Option) => void;
  styles?: any;
}> = ({ width, options, placeholder, selected, handleDataChange, styles }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(selected ? selected : "");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option: Option) => {
    if(handleDataChange) {
      handleDataChange(option);
    }

    setValue(option.label);

    setIsOpen(false);
  };

  return (
    <DropdownWrapper fullWidth={width} style={styles}>
      <DropdownButton onClick={toggleDropdown}>
        {value || placeholder}
        <ArrowDropDownIcon
          style={{
            position: "absolute",
            right: "10px",
            color: "#B11F72"
          }}
        />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          {options.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
