import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const SelfieContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  background-color: #b11f72;
  color: #fff;
  display: inline-block;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-family: Poppins;
  font-size: 14px;
  width: 50%;
  margin-bottom: 10px;
`;

interface SelfieProps {
  handleSelfie: any;
}

const SelfieComponent = (props: SelfieProps) => {
  const [photo, setPhoto] = useState<string | null>(null);
  const [showPhoto, setShowPhoto] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleStartCamera = async () => {
    try {
      const stream = await navigator?.mediaDevices?.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const handleTakePhoto = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext('2d');
      if (context && videoRef.current.srcObject) {
        context.drawImage(videoRef.current, 0, 0);
        const data = canvas.toDataURL('image/png');
        setPhoto(data);
        setShowPhoto(true);

        const byteString = atob(data.split(',')[1]);
        const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        props.handleSelfie(blob);
        // Stop the camera stream
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;
      }
    }
  };

  const handleRetakePhoto = () => {
    setShowPhoto(false);
    setPhoto(null);
    handleStartCamera(); // Restart camera
  };

  return (
    <SelfieContainer>
      {!showPhoto && (
        <>
          <Button onClick={handleStartCamera}>Start Camera</Button>
          <Button onClick={handleTakePhoto}>Take Photo</Button>
          <video ref={videoRef} autoPlay muted style={{ display: 'block', marginBottom: '10px' }}></video>
        </>
      )}
      {showPhoto && photo && (
        <>
          <img src={photo} alt="Selfie" style={{ width: '100%', maxWidth: '300px', borderRadius: '8px', marginBottom: '10px' }} />
          <Button onClick={handleRetakePhoto}>Retake Photo</Button>
        </>
      )}
    </SelfieContainer>
  );
};

export default SelfieComponent;
