import React from "react";
import styled from "styled-components";
import {
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TelegramIcon
} from "react-share";
import './SocialActivityCard.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShopIcon from '@mui/icons-material/Shop';

const SocialActivityCardContaianer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: #212529 !important;
  text-align: left;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

const DataRow = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media(max-width: 732px) {
    flex-direction: column;
    margin-top: 10px;
    align-items: start;

    button {
      margin-top: 10px;

    }

    a {
      margin-top: 10px;
    }
  }
`;

const StyledButton = styled.a`
  padding: 10px 15px !important;
  border-radius: 8px;
  box-sizing: border-box;
  margin-right: 10px;
  width: 150px;
  display: flex;
  text-decoration: none !important;
  align-items: center;
`; 

interface Props {
  icons: any
}

const SocialActivityCard = (props: Props) => {

  const getShareIcon = (name: string, logo: string) => {
      switch(name) {
        case "Facebook":
          return <FacebookShareButton
            style={{ background: "#b3d0ff" }}
            children={
              <>
                <FacebookIcon size={32} round={true} />
                <span style={{ color: "#0965FE" }}>Facebook</span>
              </>
            }
            url={`${process.env.REACT_APP_RegisterUrl}`}
          />
        case "Twitter":
          return <TwitterShareButton
            style={{ background: "#b9dff8" }}
            children={
              <>
                <TwitterIcon size={32} round={true} />
                <span style={{ color: "#4AADED" }}>Twitter</span>
              </>
            }
            url={`${process.env.REACT_APP_RegisterUrl}`}
          />
        case "Telegram":
          return (
            <TelegramShareButton
              style={{ background: "#b9dff8" }}
              children={
                <>
                  <TelegramIcon size={32} round={true} />
                  <span style={{ color: "#4AADED" }}>Telegram</span>
                </>
              }
              url={`${process.env.REACT_APP_RegisterUrl}`}
            />
          );
        case "Instagram":
          return (
            <StyledButton
              style={{ background : "linear-gradient(45deg, hsl(232, 84%, 80%), hsl(248, 63%, 80%), hsl(291, 47%, 80%), hsl(337, 58%, 80%), hsl(350, 79%, 80%), hsl(2, 98%, 80%))"}}
              href={process.env.REACT_APP_RegisterUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon style={{height: '32px' , width: '32px', marginRight: '5px'}} />
              <span style={{ color: "black" }}>Instagram</span>
            </StyledButton>
          );
        case "Play Store":
          return (
            <StyledButton
              style={{ background: "#E0E7E9" }}
              href={"https://play.google.com/store/search?q=yatripay&c=apps&hl=en_IN&gl=US"}
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <img src={logo} height={32} width={32}  /> */}
              <ShopIcon style={{height: '32px' , width: '32px', marginRight: '5px'}} />
              <span style={{ color: "gray" }}>Play Store</span>
            </StyledButton>
          );
        default :
          return <></>
      }
  }

  return (
    <SocialActivityCardContaianer>
      <TitleRow>
        <Title>Social Media Activity</Title>
      </TitleRow>
      <DataRow>The simple way to stake and earn</DataRow>
      <IconContainer>
        {props.icons?.length > 0  &&       
        (props.icons?.map((item: any)=> {
            return getShareIcon(item?.name, item?.logo)
        }))    
        }
      </IconContainer>
    </SocialActivityCardContaianer>
  );
};

export default SocialActivityCard;