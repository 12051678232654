import { TRANSACTION_CREATED } from "../types";

export interface TransactionReducerType {
  transactionCreated: boolean;
}

export const initialState: TransactionReducerType = {
  transactionCreated: false,
};

export default function transactionReducer(
  state = initialState,
  action: any
): TransactionReducerType {
  switch (action.type) {
    case TRANSACTION_CREATED:
      return {
        ...state,
        transactionCreated: action.payload,
      };
    default:
      return state;
  }
}
