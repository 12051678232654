import { Grid } from '@mui/material';
import styled from 'styled-components';

export const FlightHotelContainer = styled.div`
  display: flex;
  height: 100%;
  width: 60%;
  position: relative;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media(max-width: 600px){
    flex-direction: column;
  }

`;

export const StyledImageContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;


export const StyledImage = styled.img`
  width: 100%;
  // height: 100%;
`;

export const ImageButton = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: white;
  border-radius: 4px;
  padding: 10px 20px;
`;