import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Layout from '../components/common/Layout/Layout';
import { store } from '../uiapi/redux/store';

interface PrivateRouteProps {
  element: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {

  const token = store.getState().user?.token || '';
  let location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Layout>{element}</Layout>;
};

export default PrivateRoute;
