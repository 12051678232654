import React from 'react';

interface CircularProgressBarProps {
  percentage: number; // Percentage of completion (0-100)
  radius: number; // Radius of the circle
  strokeWidth: number; // Width of the border
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  percentage,
  radius,
  strokeWidth,
}) => {
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;
  const center = radius;
  const startAngle = -90; // Angle to start progress (12 o'clock position)

  // Calculate end angle based on percentage
  const endAngle = ((percentage / 100) * 360) + startAngle;

  // Convert degrees to radians
  const startRadians = (startAngle * Math.PI) / 180;
  const endRadians = (endAngle * Math.PI) / 180;

  // Calculate coordinates for the starting point
  const startX = center + radius * Math.cos(startRadians);
  const startY = center + radius * Math.sin(startRadians);

  // Calculate coordinates for the ending point
  const endX = center + radius * Math.cos(endRadians);
  const endY = center + radius * Math.sin(endRadians);

  return (
    <svg width={radius * 2} height={radius * 2} viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
      <circle
        cx={center}
        cy={center}
        r={radius - strokeWidth / 2}
        fill="#F2F2F2"
        stroke="#F2F2F2"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={center}
        cy={center}
        r={radius - strokeWidth / 2}
        fill="transparent"
        stroke="#595C5F"
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        transform={`rotate(-90, ${center}, ${center})`} // Rotate to start from the top center
      />
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="14px">
        {percentage}%
      </text>
      <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" fontSize="10px">per annum</text>
    </svg>
  );
};

export default CircularProgressBar;
