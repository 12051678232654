import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const TicketCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
    color: #212529 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
`;

const HelperText = styled.div`
    color: #878787 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
`;

const DataRow = styled.div`
  display: flex;
//   overflow: hidden;
  padding-top: 20px;
  height: 100%;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
`;

const DataItem = styled.div`
  flex-basis: calc(33.33% - 10px);
  margin-bottom: 10px;
  text-align: center;
  color: #878787;
  border-right: 2px solid #F2F2F2;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const FieldsContainer = styled.div`
   display: flex;
   position: relative;
   width: 50%;
   flex-direction: column;

   @media(max-width: 779px) {
    width: 100%;
    padding-right: unset;

   }
`;

const Input = styled.input`
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 40px 12px 12px; /* Add extra padding for the icon */
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  box-sizing: border-box; /* Ensure padding doesn't affect input width */

  &:focus {
    outline: none;
    border: 1px solid #bdbdbd;
  }

`;

const TextArea =styled.div`
  width: 50%;
  position: relative;

  textarea {
    margin-left: 20px;
    width: 89%;
    height: 70%;
    padding: 20px;
    border: 1px solid #ced4da;

    &:focus: {
        outline: none;
        border-color: #bdbdbd;
    }

  }


  @media(max-width: 779px) {
    width: 100%;

    textarea {
        margin-left: unset;
        width: 92%;

    

    }
}
`

const CreateTicket: React.FC = () => {
  const [data, setData] = useState<any>({
    totalTickets: '2',
    openTickets: '3',
    closedTickets: '45'
  });

  useEffect(() => {
    // Fetch data from backend API
    // fetchData();
  }, []);

  const fetchData = async () => {
    try {
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <TicketCard>
      <TitleRow>
        <Title>Create Ticket</Title>
      </TitleRow>
      <DataRow>
        <FieldsContainer>
            <Input placeholder='Type Subject' type='text'></Input>
            <Input placeholder='Subject Type' type='text'></Input>
            <Input type='file'></Input>
        </FieldsContainer>
        <TextArea>
            <textarea placeholder='Message'></textarea>
        </TextArea> 
      </DataRow>
    </TicketCard>
  );
};

export default CreateTicket;
