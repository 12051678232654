import { Grid } from "@mui/material";
import React from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import Disclaimer from "../../common/Disclaimer/Disclaimer";
import FAQ from "../../common/FAQ/FAQ";
import SwapCard from "./Cards/SwapCard";
import SwapHistoryCard from "./Cards/SwapHistoryCard";

export default function Swap() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadTitle>Swap</HeadTitle>
      </Grid>
      {/* <Grid item xs={12}>
        <SwapCard />
      </Grid> */}
      <Grid item xs={12}>
        <SwapHistoryCard />
      </Grid>
      <Grid item xs={12}>
        <Disclaimer />
      </Grid>
      <Grid item xs={12}>
        <FAQ code="swap"/>
      </Grid>
    </Grid>
  );
}
