import styled from "styled-components";

import { TableCell, TableContainer, TableRow } from "@mui/material";

export const StyledTableContainer = styled(TableContainer)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

export const StyledTableHeadCell = styled(TableCell)`
  && {
    font-weight: bold;
    padding: 20px;
    background-color: #fafafa;
    border-bottom: unset !important;
    white-space: nowrap;
  }
`;

export const StyledTableBodyCell = styled(TableCell)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }

  abbr {
    text-decoration: none;
  }
`;

export const Title = styled.div`
  padding: 20px;
  color: #212529 !important;
  text-align: left;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #fafafa; // Your desired row stripe color
  }

  &:hover {
    background-color: #F2F2F2;
  }
`;

export const NoDataContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 600px !important;
`;

export const StyledButton = styled.button<{background: string, color: string}>`
   border-radius: 50px;
   text-transform: capitalize;
   border: none;
   background-color: ${props => props.background} !important;
   color: ${props => props.color} !important;
   padding: 5px 10px;
`

export const StyledAmount = styled.div<{color: string}>`
  color: ${props => props.color} !important;
`;