import { containsPhoneNumber } from '../../../utils/utils';
import * as types from '../../interface/uiModelTypes';
import { store } from '../../redux/store';
import { axiosWrapper } from './axiosWrapper';


export async function getTransactionHistory(page: number) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/transactions/list/?page=${page}`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}


export async function getWalletBalance(ticker: string = 'ytp') {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/wallet/${ticker}/details/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function createStake(id: any,amt: any, agreement: any) {

    const formData = new FormData();
    formData.append('staking_type_id', id);
    formData.append('amount', amt);
    formData.append('agreement',agreement );

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/stakes/staking/create/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: formData,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function sendMoney(address: any,amt: any,ticker: any, pin: any, phn: any = 0) {

    const formData = new FormData();
    if(containsPhoneNumber(address)){
      formData.append('phone_no', address);
    } else {
      formData.append('address', address);
    }
    formData.append('amount', amt);
    formData.append('ticker',ticker);
    formData.append('transaction_pin',pin);

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/send/amount/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: formData,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getYTPHistory(ticker: string = 'ytp', page: number) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/buy-sell/coin/list/?page=${page}`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getReferredList() {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/promotion/referral/relationships/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export const getReferralCode = () => {

    const apiEndpoint: string = `/api/v1/promotion/referral/links/list/`;

    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export const resetPin = (pin: any) => {

    const apiEndpoint: string = `/api/v1/users/pin/reset/`;
    const formData = new FormData();

    formData.append('pin', pin)
    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'post',
        body: formData,
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}
