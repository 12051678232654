import React, { useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import { TransactionFields } from "../../../utils/utils";
import { getBannerImages } from "../../../uiapi/network/services/utilService";
import CommonTable from "../../common/CommonTable/CommonTable";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import FlightHotel from "./FlightHotel/FlightHotel";
import LockedStaking from "../../common/LockedStaking/LockedStaking";
import YTPSell from "../../common/YTPSell/YTPSell";
import { getTransactionHistory } from "../../../uiapi/network/services/walletService";
import {
  FormattedTransaction,
  ResponseType,
  TableResponseType,
  chartDataType,
} from "../../../uiapi/interface/uiModelTypes";
import LineChart from "./Graph";
import Banner from '../../../assets/images/Banner.png';
import { getGraphData } from "../../../uiapi/network/services/userService";
import Carousel from "../../common/Carousel/Carousel";
// import CryptoTableComponent from "./CryptoTable/CryptoTable";

const durations: any = {
  0: 5,
  1: 15,
  2: 60,
  3: 365,
};

const DashboardLayout: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [nextpage, setNextPage] = useState<number | null>(null);
  const [prevpage, setPrevPage] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(0);
  const [transactionData, setTransactionData] = useState<
    FormattedTransaction[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [chartData, setChartData] = useState<chartDataType[]>([]);

  const fetchGraphPoints = async (duration: number) => {
    let graph: chartDataType[] = [];
    const graphData = await getGraphData(duration);

    if (graphData && graphData.message === "success") {
      if (graphData?.data?.length > 0) {
        graphData.data.forEach((item: any) => {
          graph.push({
            x: item?.date,
            y: item?.price,
          });
        });
        setChartData(graph);
      } else {
        setChartData([]);
      }
    } else {
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    fetchGraphPoints(durations[newValue]);
  };

  const fetchData = async () => {
    try {

      const bannerImages: ResponseType = await getBannerImages();
      if (
        bannerImages &&
        bannerImages?.success &&
        bannerImages?.data?.length > 0
      ) {
        const imageUrls = bannerImages.data.map(
          (image: { slider: string }) => image.slider
        );
        setImages(imageUrls);
      } else {
        setImages([Banner]);
      }
    } catch (error) {
      setError("Failed to fetch data. Please try again later.");
    }
  };

  const fetchTransactionHistory = async (page: number) => {
    setLoading(true);
    try {
      const transactionDataResponse: TableResponseType = await getTransactionHistory(page);

      if (transactionDataResponse && !transactionDataResponse?.success) {
        setError("Failed to fetch data. Please try again later.");
      } else if (
        transactionDataResponse &&
        transactionDataResponse?.data?.length > 0
      ) {
        const formattedData: FormattedTransaction[] =
          transactionDataResponse.data.map((transaction: any) => ({
            Coin: transaction.coin.name,
            Category: transaction.category,
            "Total Amount": transaction.total_amount,
            Status: transaction.status || "",
            Confirmations: `${transaction.confirmations} +`,
            Explorer: transaction.explorer_link,
            TxType: transaction.trans_type,
            Txid: transaction.txid,
            Address: transaction.address,
            Date: transaction.created_at,
        }));
        setTransactionData(formattedData);
        setTotalCount(transactionDataResponse?.count)
        setNextPage(
          transactionDataResponse.next 
            ? parseInt(transactionDataResponse.next.match(/page=(\d+)/)?.[1] || '', 10) 
            : null
        );
        setPrevPage(
          transactionDataResponse.previous 
            ? (parseInt(transactionDataResponse.previous.match(/page=(\d+)/)?.[1] || '', 10) || 1) 
            : null
        );
      } else {
        setTransactionData([]);
      }
    } catch (error) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePrevPage = () => {
    prevpage && fetchTransactionHistory(prevpage);
  };

  const handleNextPage = () => {
    nextpage && fetchTransactionHistory(nextpage);
  };

  useEffect(() => {
    setError("");
    fetchData();
    fetchGraphPoints(5);
    fetchTransactionHistory(1);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadTitle>Dashboard</HeadTitle>
      </Grid>

      {/* Carousel and Graph Section */}
      <Grid container>
        <Grid item md={7} sm={12}>
          <Carousel images={images} />
        </Grid>
        <Grid item md={5} sm={12}>
          <div
            style={{
              height: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Days" />
              <Tab label="Week" />
              <Tab label="Month" />
            </Tabs>
          </div>
          <div style={{ height: "90%" }}>
            <LineChart data={chartData} title="Days" />
          </div>
        </Grid>
      </Grid>

      {/* Staking and Buy/Sell Section */}
      <Grid container item style={{paddingTop: '20px'}}>
        <Grid item md={7} sm={12} xs={12}>
          <LockedStaking />
        </Grid>
        <Grid item md={5} xs={12} sm={12}>
          <YTPSell />
        </Grid>
      </Grid>

      {/* Crypto Table Section */}
      {/* <Grid item xs={12}>
        <CryptoTableComponent />
      </Grid> */}

      {/* Travel Section */}
      <Grid container item xs={12} sm={12} justifyContent={"center"}>
        <FlightHotel />
      </Grid>

      {/* Transaction History Section */}
      <Grid item xs={12}>
        <CommonTable
          title="Transaction History"
          fields={TransactionFields}
          data={transactionData || []}
          loading={loading}
          error={error}
          handleNext={() => handleNextPage()}
          handlePrev={() => handlePrevPage()}
          prevPage={prevpage}
          nextPage={nextpage}
          totalCount={totalCount}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardLayout;
