import React from "react";
import { Grid, Link } from "@mui/material";
import './GuestLayout.css';
import Rocket from '../../../assets/images/rocket.svg'; // Correct path to rocket image
import FootLogo from '../../../assets/images/logo.svg';
import { About_Yatri, Hero_Sub_Heading } from "../../../utils/constants";
import { PageType } from "../../../utils/enums/enums";
import { useLocation, useNavigate } from "react-router-dom";
import LoginForm from "../Forms/LoginForm";
import RegisterForm from "../Forms/RegisterForm";
import OtpForm from "../Forms/OtpForm";
import CreatePassword from "../Forms/CreatePassword";
import { getFormHeader, getFormLinks } from "../../../utils/utils";
import ResetPassword from "../Forms/ResetPassword";

interface GuestLayoutProps {
  page: number;
}

function GuestLayout(props: GuestLayoutProps) {

  const navigate = useNavigate();

  const location = useLocation();
  const email = location.state?.email || '';

  const [isTablet, setIsTablet] = React.useState(window.matchMedia("(max-width: 1660px)").matches);

  function getView() {
    switch (props.page) {
      case PageType.Login:
        return <LoginForm />
      case PageType.Register:
        return <RegisterForm />
      case PageType.Verify:
        return <OtpForm email={email} />
      case PageType.CreatePassword:
        return <CreatePassword email={email} />
      case PageType.ResetPassword:
          return <ResetPassword />
      default:
        return <LoginForm />
    }
  }

  React.useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.matchMedia("(max-width: 1660px)").matches);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLink = () => {
    navigate(props.page === PageType.Login ? '/register' : '/login');
  }

  return (
    <div className="layout-container">
      <Grid container className="hero" alignItems={'center'}>
        <Grid item lg={4} md={12} className="heading-container">
          <div className="heading">
            YatriPay: <span>One World</span>,One Wallet
          </div>
          <div className="sub-heading">
            {Hero_Sub_Heading}
          </div>
        </Grid>
        <Grid item lg={8} md={12} className="form-container">
          {!isTablet && <img src={Rocket} alt="rocket" />}
          <div className="login-form-container">
            <div className="form-header">
              <h2>Welcome to <strong style={{ color: "#B11F72" }}>YatriPay</strong></h2>
              <Link onClick={handleLink} className="sign-up-link">
               {getFormLinks(props.page)}
              </Link>
            </div>
            <h1 className="form-title" style={{}}>
              {getFormHeader(props.page)}
            </h1>
            {(props.page === PageType.Verify) && <div style={{ marginBottom: '20px' }}>{`We’ve sent an SMS with an activation code to your email ${email || ''}`}</div>}
            {getView()}
          </div>
        </Grid>
      </Grid>
      <Grid container className="content-section">
        <Grid item lg={5} md={12} className="about-yatri">
          {About_Yatri}
          <div className="footer-logo">
            <img src={FootLogo} alt="logo"/>
            <p>&copy; {new Date().getFullYear()} YatriPay. All rights reserved.</p>
          </div>
        </Grid>

      </Grid>
    </div>
  )
}

export default GuestLayout;
