import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import Disclaimer from "../../common/Disclaimer/Disclaimer";
import FAQ from "../../common/FAQ/FAQ";
import ReferralCard from "./Cards/ReferralCard";
import { Title } from "../../common/CommonTable/CommonTable.styles";
import CommonTable from "../../common/CommonTable/CommonTable";
import { ReferredUsers, convertEpoch } from "../../../utils/utils";
import { getReferredList } from "../../../uiapi/network/services/walletService";
import { FormattedReferredList, ResponseType } from "../../../uiapi/interface/uiModelTypes";

export default function Referral() {

  const [refferedList, setReferredList] = useState<FormattedReferredList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const refferedData: ResponseType = await getReferredList();

      if (refferedData && refferedData?.success && refferedData.data?.length > 0) {
        const formattedData: FormattedReferredList[] = [];

        refferedData.data.map((data: any) => {          
          formattedData.push({
            'User': data.referred_to?.first_name || '',
            'Email Id': data.referred_to?.email || '',
            'Joining Date': data?.referred_to?.created_at,
            'Staking Hike Till': convertEpoch(data?.staking_hike_time),
            'Referral Reward Till': convertEpoch(data?.referral_time),
            'Action': 'Check Staking'
          });
        });
        setReferredList(formattedData);
      }
    } catch (error) {
        setError("Error fetching data");
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadTitle>Referral</HeadTitle>
      </Grid>
      <Grid item xs={12}>
        <ReferralCard />
      </Grid>
      <Grid item xs={12}>
        <CommonTable title="Referred Users" fields={ReferredUsers} data={refferedList} />
      </Grid>
      <Grid item xs={12}>
        <Disclaimer />
      </Grid>
      <Grid item xs={12}>
        <FAQ code="referral"/>
      </Grid>
    </Grid>
  );
}
