import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography, Box, CircularProgress, Alert } from '@mui/material';
import './form.css';
import { OtpData, ResponseType, VerifyEmailData } from '../../../uiapi/interface/uiModelTypes';
import { getOtp, getResetLink, validateOtp } from '../../../uiapi/network/services/userService';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '../../../uiapi/redux/actions/userActions';

// Create a type for the code state
type CodeState = string[];

interface OtpFormProps {
  email: string;
}

export default function OtpForm(props: OtpFormProps) {
  const [code, setCode] = useState<CodeState>(new Array(6).fill(''));
  const [timer, setTimer] = useState<number>(30);
  const [error, setError] = useState<string | null>(null); // New state for error
  const [serverError, setServerError] = useState<string | null>(null); // New state for error
  const [loading, setLoading] = useState(false); // New state for loading
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null); // Clear error on input change
    const newCode = [...code];
    newCode[index] = event.target.value;
    setCode(newCode);
    if (event.target.value.length === 1 && index < 5) {
      const nextSibling = document.getElementById(`code${index + 1}`) as HTMLInputElement;
      nextSibling?.focus();
    }
  };

  const handleResendCode = async () => {
    try {
      setCode(new Array(6).fill(''));
      setLoading(true); // Set loading to true when resending code
      const otp: ResponseType = await getResetLink(props.email);
      setTimer(30); // reset timer

      if (otp && otp?.success && otp?.data) {
        setError(null); // Clear any previous error
      } else {
        setError("Failed to resend code. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError("Failed to resend code. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  const handleVerify = async () => {
    try {
      setLoading(true); // Set loading to true when verifying code
      const enteredCode = parseInt(code.join(''));
      if (isNaN(enteredCode) || enteredCode.toString().length !== 6) {
        setError("Invalid OTP. Please enter a 6-digit code.");
        return;
      }

      const data: VerifyEmailData = {
        email: props.email || '',
        email_otp: enteredCode
      };

      const userData:ResponseType = await validateOtp(data);

      if (userData && userData?.success && userData?.data) {
        dispatch(setToken(userData.data?.token))
        navigate('/create-password',{ state: { email: props?.email || '' } });
      } else {
        setServerError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      // setError("Failed to verify code. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  return (
    <Grid justifyContent={'center'} style={{ display: 'grid' }}>
      <Grid container spacing={2} justifyContent="center">
        {code.map((_, index) => (
          <Grid item key={index} justifyContent={'center'} style={{ marginBottom: '20px' }}>
            <TextField
              id={`code${index}`}
              inputProps={{ maxLength: 1 }}
              onChange={handleChange(index)}
              value={code[index]}
              sx={{ width: '6ch', input: { textAlign: 'center' } }}
              error={!!error}
            />
          </Grid>
        ))}
      </Grid>
      {error && <Typography style={{ textAlign: 'center', marginTop: '20px' }} color="error" variant="body2">{error}</Typography>}
      <Button type="submit" variant="contained" fullWidth className="login-button" onClick={handleVerify} disabled={loading}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
      </Button>
      {serverError && <Alert color="error">{serverError}</Alert>}
      <Button onClick={handleResendCode} disabled={timer !== 0} className='send-code-link'>
        {loading ? 'Sending...' : `Send code again ${timer > 0 ? `00:${timer.toString().padStart(2, '0')}` : ''}`}
      </Button>
    </Grid>
  );
}
