import { Alert, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { getConversionValue, getCurrenciesValue } from "../../../../uiapi/network/services/userService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createSwap, getCoinList } from "../../../../uiapi/network/services/utilService";
import SwapIcon from "../../../../assets/images/switch-horizontal.png";
import { MuiButton } from "../../../common/Styles/CommonStyles";

const TabComponentContainer = styled.div`
  width: 100%;
`;


const ContentContainer = styled.div`
  display: flex;
  padding: 20px 25px;
  position: relative;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const ValueField = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

const Button = styled(MuiButton)`
  margin-left: 10px !important;
  padding: 10px 2% !important;

  @media (max-width: 650px) {
    margin-top: 20px !important;
    padding: 10px 5% !important;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  // width: 20px;
  color: pink;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  color: #b11f72;
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
  background-color: white;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 10000 !important;

  a {
    display: block;
    cursor: pointer;
    padding: 10px 20px;

    &:hover {
      background-color: #e0e7e9;
    }
  }
`;

export const SwapIconCont = styled.div`
  background-color: #ffeef8;
  border-radius: 8px;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const DataRow = styled.div`
  display: flex;
  padding-top: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding: 20px 25px;
`;


const DataItem = styled.div`
  margin-right: 100px;
  text-align: left;
  color: #878787;
`;

export interface CoinList {
    label: string,
    ticker: string,
    fees: number
  }

export function SwapTab() {
  const [formData, setFormData] = useState({
    from_amount: 0,
    to_amount: 0,
  });
  const [showFromDropdown, setFromDropdown] = useState<boolean>(false);
  const [showToDropdown, setToDropdown] = useState<boolean>(false);
  const [selectedFromCurrency, setSelectedFromCurrency] = useState("YTP");
  const [selectedToCurrency, setSelectedToCurrency] = useState("BTC");
  const [currencyDetail, setCurrencyDetail] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState("");
  const [coinList, setCoinList] = useState<CoinList[]>([]);

  const handleBlur = (e: any) => {
    // if (
    //   !e.currentTarget.contains(e.relatedTarget as Node) &&
    //   (showFromDropdown || showToDropdown)
    // ) {
    //   setFromDropdown(false);
    //   setToDropdown(false);
    // }
  };

  const handleChange = (e: any) => {
    setAlert("");
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToClick = (value: string) => {
    setSelectedToCurrency(value);
    setToDropdown(!showToDropdown);
  };

  const handleFromClick = (value: string) => {
    setSelectedFromCurrency(value);
    setFromDropdown(!showFromDropdown);
  };

  const getConversion = async () => {
    const currency = await getConversionValue(selectedFromCurrency);
    if (currency && currency?.success && currency?.data) {
      setCurrencyDetail(currency?.data);
      setFormData((prevData: any) => ({
        ...prevData,
        to_amount: currencyDetail?.[selectedToCurrency] * formData.from_amount,
      }));
    }
  };

  const handleSwap = async() => {
    if (formData.from_amount && formData.to_amount) {
       const swapdetails = await createSwap(formData.from_amount, selectedFromCurrency, selectedToCurrency);
       if(swapdetails && swapdetails?.success && swapdetails?.data) {
         setSuccess(true);
       }
    } else {
      setAlert("Please Fill all the fields. ");
    }
  };

  const getCoinsList = async () => {
    try {
      const coins = await getCoinList();
      if (coins && coins.success && coins?.data?.length > 0) {
        const list: CoinList[] = coins.data
          .slice(0, 10)
          .map((element: any) => ({
            label: element.name,
            ticker: element.ticker,
            fees: element.fees
          }));
        setCoinList(list);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCoinsList();
  }, []);

  useEffect(() => {
    getConversion();
  }, [selectedFromCurrency]);

  useEffect(() => {
    setFormData((prevData: any) => ({
      ...prevData,
      to_amount: (
        currencyDetail?.[selectedToCurrency] * formData.from_amount
      ).toFixed(2),
    }));
  }, [formData.from_amount]);

  return (
    <TabComponentContainer>
      <div>
      {alert && <Alert severity="info" color="error" style={{ width: '-webkit-fill-available', justifyContent: 'center'}}>{alert}</Alert>}
        <ContentContainer>
          <ValueField>
            <TextField
              className="text-field"
              label="From"
              variant="outlined"
              fullWidth
              margin="dense"
              type="text"
              value={formData.from_amount || ""}
              name="from_amount"
              onChange={handleChange}
            />
            <Container tabIndex={1} onBlur={handleBlur}>
              <DropdownContainer
                onClick={() => setFromDropdown(!showFromDropdown)}
              >
                {" "}
                {selectedFromCurrency} <KeyboardArrowDownIcon />
              </DropdownContainer>
              {showFromDropdown && (
                <DropdownContent>
                  {coinList &&
                    coinList.length > 0 &&
                    coinList.map((item, index) => {
                      return (
                        <a
                          key={index}
                          onClick={() => handleFromClick(item.ticker)}
                        >
                          {" "}
                          {item.ticker}
                        </a>
                      );
                    })}
                </DropdownContent>
              )}
            </Container>
          </ValueField>
          <SwapIconCont>
            <img src={SwapIcon} alt="swap" />
          </SwapIconCont>
          <ValueField>
            <TextField
              className="text-field"
              label="To"
              variant="outlined"
              fullWidth
              margin="dense"
              type="text"
              name="to_amount"
              value={formData.to_amount || ""}
              onChange={handleChange}
              disabled
            />
            <Container tabIndex={1} onBlur={handleBlur}>
              <DropdownContainer onClick={() => setToDropdown(!showToDropdown)}>
                {" "}
                {selectedToCurrency} <KeyboardArrowDownIcon />
              </DropdownContainer>
              {showToDropdown && (
                <DropdownContent>
                  {coinList &&
                    coinList.length > 0 &&
                    coinList.map((item, index) => {
                      return (
                        <a
                          key={index}
                          onClick={() => handleToClick(item.ticker)}
                        >
                          {" "}
                          {item.ticker}
                        </a>
                      );
                    })}
                </DropdownContent>
              )}
            </Container>
          </ValueField>
          <Button onClick={handleSwap}>Swap</Button>
        </ContentContainer>
        <DataRow>
          <DataItem>
            Price{" "}
            <div style={{ color: "black", fontWeight: "600" }}>
              {formData.from_amount || 0} {selectedFromCurrency} ={" "}
              {formData.to_amount || 0} {selectedToCurrency}
            </div>
          </DataItem>
          <div
            style={{
              backgroundColor: "#F2F2F2",
              width: "2px",
              height: "40px",
              marginRight: "70px",
            }}
          ></div>
          <DataItem>
            Fees{" "}
            <div style={{ color: "black", fontWeight: "600" }}>
              {coinList?.map((item)=>{
                if(item.ticker === selectedFromCurrency){
                  return (<>{item?.fees || 0}</>)
                } 
              })}
              <span style={{ color: "#878787", fontWeight: "400" }}> {selectedFromCurrency} </span>
            </div>
          </DataItem>
        </DataRow>
      </div>
      <Alert severity="info" color="warning" style={{ width: '-webkit-fill-available', borderRadius: '0px 0px 8px 8px', justifyContent: 'center'}}>The ultimate price and output is determined by our systems at the time of your swap.</Alert>
    </TabComponentContainer>
  );
}
