import styled from "styled-components";

export const DisclaimerContainer = styled.div`
    border-radius: 8px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: Poppins;
`;

export const DisclaimerContent = styled.ul`
    padding-top: 20px;
    li {
        font-family: Poppins;
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        color: #878787;
    }
`;
