import React from "react";

export function getOffersDom() {
  return (
    <>
      <h3> Exciting News for YatriPay APP Users!  </h3>
      <ul>
        <li>
          YatriPay is thrilled to present the iPhone Giveaway! Participate now
          by completing just 7 tasks and stand a chance to WIN an iPhone!
        </li>
        <li>
          But that's not all! Every participant receives a cash prize, and with
          each task completed, earn up to 30,000 YTP!
        </li>
        <li>
          Don't miss out on this incredible opportunity – dive into the iPhone
          pool with YatriPay NOW!
        </li>
        <li>Hurry, the excitement awaits! #YatriPayiPhoneGiveaway</li>
      </ul>
    </>
  );
}
