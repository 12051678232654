import React, { useEffect, useState } from "react";
import {
  getStakingDataBasedOnType,
  getStakingList,
  getStakingTypeDetails,
} from "../../../uiapi/network/services/utilService";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import {
  LockedStakingContainer,
  Title,
  Content,
  YatriPayText,
  ProgressBarContainer,
  DaysContainer,
  Day,
  Statistics,
  Stat,
  ModalTitle,
  AvailableBalanceButton,
  BuySubscriptionContainer,
  RightContent,
  ModalRightTitle,
  RowContainer,
  RowLabel,
  RowValue,
  LeftContent,
  TextFieldContainer,
  MinMaxStake,
  StyledTextField,
  AgreementLink,
  MuiButton,
} from "./LockedStaking.styles";
import { Alert, Box, Checkbox, Modal, useTheme } from "@mui/material";
import CrossIcon from "../../../assets/images/Cross.svg";
import {
  ModalDescriptionContainer,
  SubmitButton,
} from "../YTPSell/YTPSell.styles";
import { SubTitle } from "../FAQ/FAQ.styles";
import Dropdown from "../../common/Dropdown/Dropdown";
import { convertEpoch } from "../../../utils/utils";
import { createStake, getWalletBalance } from "../../../uiapi/network/services/walletService";
import { InputFields, InputTextSupport, ValueField } from "../LockedStakingCard/LockedStakingCard.styles";
import { ResponseType } from "../../../uiapi/interface/uiModelTypes";


interface SummaryDate {
  [key: string]: any; // Define the type of values in summaryDate
}

interface Option {
  label: string;
  value: string;
  selected?: boolean;
}

const LockedStaking: React.FC = () => {
  const theme = useTheme();
  const [availableBalance, setAvailableBalance] = useState<any>({});
  const [selectedDuration, setSelectedDuration] = useState(7);
  const [lockAmount, setLockAmount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [availableValidities, setAvailableValidities] = useState<number[]>([]);
  const [availableSubTypes, setAvailableSubTypes] = useState<Option[]>([]);
  const [activeStakingDetails, setActiveStakingDetails] = useState({
    subscriber: 0,
    rewardsEarner: 0,
    quotaLeft: 0,
    progress: 0,
    min_stake: 0,
    id: 0,
    max_stake: 0
  });
  const [summaryData, setSummaryData] = useState<SummaryDate>({});
  const [selectedDropdown, setSelectedDropdown] = useState<string>('');
  const [serverError, setServerError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  interface ModalData {
    'subscription_type': string,
    'subscription_validity': number,
    'min_stake': number,
    'max_stake': number,
    'subscription_price': number,
    'start_date': string,
    'end_date':  string,
    'return_period': number,
    'per_annum': number,
    'estimated_return': number,
  }

  const [modalData, setModalData] = useState<ModalData>({
    'subscription_type': '',
    'subscription_validity': selectedDuration,
    'subscription_price': 0,
    'start_date': '',
    'end_date': '',
    'estimated_return': 0,
    'max_stake': 0,
    'min_stake': 0,
    'return_period': 0,
    'per_annum': 0
  });

  let totalAmount:number =  (lockAmount - (-summaryData?.['Subscription Price']));
  let returnAmount:number =  ((lockAmount/100)*(modalData.per_annum/365)*modalData?.subscription_validity) - (-lockAmount) || 0;


  async function fetchAvailableBalance() {
    const walletBalance:ResponseType = await getWalletBalance();

    if(walletBalance && walletBalance?.success && walletBalance?.data) {
      if(walletBalance?.data) {
        setAvailableBalance(walletBalance.data);
      }
    }
  }

  async function getStakingData(duration: number) {
    const stakingList:ResponseType = await getStakingList();

    if (stakingList && stakingList?.success && stakingList?.data?.length > 0) {
      let validity: number[] = [];
      let subscriptionType: Option[] = [];
      stakingList.data.forEach((element: any) => {
        validity.push(element?.validity);
        subscriptionType.push({
          label: element?.name,
          value: element.validity
        });
      });
      setAvailableSubTypes(subscriptionType);
      setAvailableValidities(validity);

      const stakingTypeDetails:ResponseType = await getStakingTypeDetails(duration);

      if (stakingTypeDetails && stakingTypeDetails?.success && stakingTypeDetails?.data) {
        setActiveStakingDetails({
          subscriber: stakingTypeDetails.data.subscribers,
          quotaLeft: stakingTypeDetails.data.quota_left,
          rewardsEarner: stakingTypeDetails.data.referral_reward,
          progress: stakingTypeDetails.data.per_annum,
          min_stake: stakingTypeDetails.data.min_stake,
          max_stake: stakingTypeDetails.data.max_stake,
          id: stakingTypeDetails.data.id
        });
        setModalData({
         'subscription_type': stakingTypeDetails.data?.name,
         'subscription_validity': stakingTypeDetails.data?.validity,
         'subscription_price': stakingTypeDetails.data?.price,
         'start_date': convertEpoch(stakingTypeDetails.data?.start_at),
         'end_date': convertEpoch(stakingTypeDetails.data?.deactive_at || ''),
         'estimated_return': stakingTypeDetails.data?.return_period,
         'max_stake': stakingTypeDetails.data?.max_stake,
         'min_stake': stakingTypeDetails.data?.min_stake,
         'return_period': stakingTypeDetails.data?.return_period,
         'per_annum': stakingTypeDetails.data?.per_annum
        })
        setSummaryData({
          "Start Date": stakingTypeDetails.data.start_at || '',
          "End Date": stakingTypeDetails.data?.deactive_at || '',
          "Return Period": stakingTypeDetails?.data?.return_period,
          "Per Annum": stakingTypeDetails.data?.per_annum || '',
          "Estimated Return": ((lockAmount/100)*(stakingTypeDetails.data?.per_annum/365)*stakingTypeDetails.data?.validity) + lockAmount,
          "Subscription Price": stakingTypeDetails.data?.price,
          "Locked Amount": 0,
          "Transaction Fees": 0,
          "Total Amount": 0
        });
        setSelectedDropdown(stakingTypeDetails.data?.name);
      }
    }
  }

  const handleStakeNowClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setServerError("");
    setRememberMe(false);
    setLockAmount(0);
    setModalOpen(false);
  };

  const handleLockAmount = (e: any) => {
    setServerError("");
    setLockAmount(e.target.value);

  }

  const handleCreateStake = async() => {
    const response:ResponseType = await createStake(activeStakingDetails.id, totalAmount, rememberMe);

    if(response && response?.success && response?.data) {
      setModalOpen(false);
    } else {
      setServerError(response.message || 'Something went wrong!');
    }
  }

  const handleCheckbox = (e: any) => {
    setRememberMe(e.target.checked || false);
  }

  const handleDataChange = async(selected: Option) => {
    setSelectedDropdown(selected.label);
    setSelectedDuration(Number(selected.value))
    setServerError("");
  }

  useEffect(() => {
    getStakingData(selectedDuration);
  }, [selectedDuration]);

  useEffect(()=>{
    fetchAvailableBalance();
  },[]);

  return (
    <LockedStakingContainer>
      <Title>Staking</Title>
      <Content>
        <YatriPayText>YatriPay</YatriPayText>
        <ProgressBarContainer>
          <CircularProgressBar
            percentage={activeStakingDetails.progress}
            radius={50}
            strokeWidth={5}
          />
        </ProgressBarContainer>
        <DaysContainer>
          {availableValidities.map((days) => (
            <Day
              key={days}
              onClick={() => setSelectedDuration(days)}
              selected={selectedDuration === days}
            >
              {`${days} Days`}
            </Day>
          ))}
        </DaysContainer>
        <MuiButton onClick={handleStakeNowClick}>Stake Now</MuiButton>
      </Content>
      <Statistics>
        <Stat>
          <div>Subscriptions</div>
          <div>{activeStakingDetails.subscriber}</div>
        </Stat>
        <Stat>
          <div>Rewards Earned</div>
          <div>{activeStakingDetails.rewardsEarner.toFixed(2)} YTP</div>
        </Stat>
        <Stat>
          <div>Quota Left</div>
          <div>{activeStakingDetails.quotaLeft.toFixed(2)} YTP</div>
        </Stat>
      </Statistics>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            border: "none",
            boxShadow: 24,
            padding: "20px 60px",
            borderRadius: "8px",
            width: "40%",
            [theme.breakpoints.down(1300)]: {
              width: "60%",
            }, 
            [theme.breakpoints.down(1200)]: {
              height: "80vh",
              overflow: "auto",
            },
          }}
        >
          <img
            src={CrossIcon}
            onClick={handleModalClose}
            style={{ cursor: "pointer", position: "absolute", right: 10 }}
          />
          <ModalTitle id="modal-title">Buy Subscription for Staking</ModalTitle>
          <div>
            <AvailableBalanceButton>
              Available Balance : {availableBalance?.balance?.toFixed(2) || 0} YTP
            </AvailableBalanceButton>
          </div>
          <BuySubscriptionContainer>
            <LeftContent>
              <Dropdown
                width={"100%"}
                options={availableSubTypes}
                placeholder={"Subscription Type"}
                selected={selectedDropdown}
                handleDataChange={handleDataChange}
              />
              <TextFieldContainer>
                <ValueField>
                    <InputFields
                      type="text"
                      placeholder="Subscription Validity"
                      disabled
                    />
                    <InputTextSupport>{`${modalData && modalData?.subscription_validity || 0} Days`}</InputTextSupport>
                </ValueField>
              </TextFieldContainer>
              <MinMaxStake>
                <span>Min. Stake : {modalData?.min_stake}</span>
                <span>Max. Stake :  {modalData?.max_stake}</span>
              </MinMaxStake>
              <TextFieldContainer>
                <ValueField>
                    <InputFields
                      type="text"
                      placeholder="Subscription Price"
                      disabled
                    />
                    <InputTextSupport>{`${modalData.subscription_price === 0 ? 'Free' : modalData.subscription_price }`}</InputTextSupport>
                </ValueField>
              </TextFieldContainer>
              <TextFieldContainer>
                <StyledTextField
                  className="text-field"
                  label="Lock Amount"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="number"
                  name="amount"
                  onChange={handleLockAmount}
                />
              </TextFieldContainer>
              <ModalDescriptionContainer style={{padding: '20px 0px'}}>
                Available Quota: {activeStakingDetails.quotaLeft.toFixed(2)} YTP
              </ModalDescriptionContainer>
            </LeftContent>
            <RightContent>
              <ModalRightTitle>Summary</ModalRightTitle>
              {Object.entries(summaryData).map(([key, value]) => (
              <RowContainer key={key}>
                  <RowLabel>{key}</RowLabel>
                  <RowValue>{(key === "Start Date" || key === "End Date") ? ( value ? convertEpoch(value) : "") : (key === "Return Period" ? ('Daily') : 
                   (key === "Per Annum" ? `${value} %` : ( key === "Locked Amount" ?  `${lockAmount} YTP`  : ( key === "Total Amount" ?  `${totalAmount} YTP` : (key === "Estimated Return" ?   returnAmount.toFixed(4)  : `${value} YTP`))))) }</RowValue>
              </RowContainer>
             ))}
            </RightContent>
          </BuySubscriptionContainer>
          { serverError && <Alert  style={{ textAlign: 'center', marginTop: '10px' }} color="error"> {serverError} </Alert>}
          <SubTitle style={{ textAlign: "center", padding: "20px " }}>
            <Checkbox name="rememberMe" onChange={handleCheckbox} />I have read and I
            agree to <AgreementLink href={`${process.env.REACT_APP_Agreement}`} target="_blank">YatriPay Staking Service Agreement</AgreementLink>
          </SubTitle>
          <SubmitButton disabled={!rememberMe} onClick={handleCreateStake}>Confirm</SubmitButton>
        </Box>
      </Modal>
    </LockedStakingContainer>
  );
};

export default LockedStaking;
