import React, { useState } from "react";
import styled from "styled-components";
import {
  createKYC,
} from "../../../../uiapi/network/services/userService";
import { RowContainer } from "../Settings";
import Upload from "../../../../assets/images/File_Upload.svg";
import Success from "../../../../assets/images/success.svg";
import {
  KYCDisclaimerList,
  KYCDisclaimerTitle,
} from "../../../../utils/constants";
import Disclaimer from "../../../common/Disclaimer/Disclaimer";
import KYCIcon from "../../../../assets/images/kyc.svg";
import Male from '../../../../assets/images/male.svg';
import Female from '../../../../assets/images/female.svg';
import { Alert, TextField } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SelfieComponent from "./Camera";
import { KYCData } from "../../../../uiapi/interface/requestBodyTypes";
import './Calendar.css';
import { ResponseType } from "../../../../uiapi/interface/uiModelTypes";
import CountrySelect from "../../../common/Dropdown/CountryDropdown";
import { CountryType } from "../../../../utils/domUtils";

export const CardContainer = styled.div`
  padding: 20px 20px;
  background-color: #ffffff; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-top: 20px;
`;

export const Title = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
`;

export const SubTitle = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: #878787;
  margin-top: 5px;
`;

export const Button = styled.button`
  && {
    background-color: #b11f72;
    color: #fff;
    display: block;
    border: none;
    height: 52px;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    font-family: poppins;
    font-size: 14px;
    width: 100px;
    margin-right: 10px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const TabContainer = styled.div`
  @media (max-width: 500px) {
    margin-top: 20px;
  }
`;

interface TabProps {
  active: boolean;
}

const TabTitle = styled.div<TabProps>`
  color: ${(props) => (props.active ? "#B11F72" : "#595C5F")};
  text-align: center;
  border: ${(props) =>
    props.active ? "1px solid #B11F72" : "1px solid #F2F2F2"};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
`;

export const UploadDocumentContainer = styled.div`
  border: 1px solid #f2f2f2;
  background: #ffffff;
  border-radius: 8px;
  padding: 0px 60px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #b11f72;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
`;

interface checkboxLabel {
    checked: boolean
}

const CheckboxLabel = styled.div<checkboxLabel>`
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  background-color: ${(props) => (props.checked ? "#e0e0e0" : "#ffffff")};
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CheckboxImage = styled.img`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
`;

const FileInput = styled.input`
  display: none;
`;

const KYCImg = styled.div`
  display: inline-block;
  margin-left: 20px;
`;

const KYCSubmittedContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UploadedFile = styled.div`
  color: black;
`;

const SelfieContainer = styled.div`
  display: flex;
  padding-top: 10px;

  @media(max-width: 1400px) {
    flex-direction: column;
    align-items: center;
  }
`;

interface KYCProps {
    isKycSubmitted: boolean;
    kycStatus: boolean;
}

export default function KYC(props: KYCProps) {
  const [activeTab, setActiveTab] = useState("Bio Data");
  const [idProof, setIdproof] = useState<any>(null);
  const [selfie, setSelfie] = useState<any>(null);
  const [addressProof, setAddressproof] = useState<any>(null);
  const [selectedGender, setSelectedGender] = useState("male");
  const [kycSubmitted, setKycSubmitted] = useState(false);
  const [errroFields, setErrorFields] = useState("");
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    country: "",
  });

  const handleSelfie = (data: any) => {
    setSelfie(data);
  }

  const handleGenderChange = (gender: string) => {
    setErrorFields("");
    setSelectedGender(gender);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorFields("");
    e.persist();
    setInputValues((inputValues) => ({
      ...inputValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setErrorFields("");

    if(inputValues.first_name != '' && inputValues.last_name != '' && inputValues.dob != "" && inputValues.country != "" && selfie && idProof && addressProof) {

        const data: KYCData = {
            first_name: inputValues.first_name,
            last_name: inputValues.last_name,
            dob: inputValues.dob,
            gender: selectedGender,
            country: inputValues.country,
            id_proof: idProof,
            address_proof: addressProof,
            selfie: selfie,
        };

        const response:ResponseType = await createKYC(data);

        if (response && response?.success && response.data) {
            setKycSubmitted(true);
        } else {
            setErrorFields(response?.message || 'Something went wrong!');
        }
    } else {
        setErrorFields("Please fill all the fields.")
    }
  };

  const handleNext = (tab: string) => {
      // setActiveTab(activeTab === "Bio Data" ? "ID Card" : (activeTab === "ID Card" ? "Address Proof" : "Selfie with ID Card"));

      switch(tab){
        case "ID Card":
          return (
             (inputValues.first_name && inputValues.last_name && inputValues.dob && inputValues.country ? setActiveTab(tab) : setErrorFields("Please fill all the fields."))
          )
        case "Address Proof":
          return (
             (idProof ? setActiveTab(tab) : setErrorFields("Please fill all the fields."))
          )
        case "Selfie with ID Card":
          return (
              (addressProof ? setActiveTab(tab) : setErrorFields("Please fill all the fields."))
          )
      }
  };

  const handleUploadIdCard = (event: any) => {
    const uploadedFile = event.target.files[0];
    setIdproof(uploadedFile);
  };

  const handleUploadAddress= (event: any) => {
    const uploadedFile = event.target.files[0];
    setAddressproof(uploadedFile);
  };

  const handlePrevious = (tab: string) => {
    setErrorFields("");
    setActiveTab(tab);
  }

  const handleCountryChange = (selectedCountry: CountryType) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      country: selectedCountry?.label || '',
    }));
  };

  const getActiveElement = () => {
    switch (activeTab) {
      case "Bio Data":
        return (
          <div>
            <SubTitle>Personal Information</SubTitle>
            <RowContainer>
              <TextField
                className="text-field"
                label="First Name"
                variant="outlined"
                fullWidth
                margin="dense"
                name="first_name"
                type="text"
                value={inputValues.first_name}
                onChange={handleInputChange}
                />
              <TextField
                style={{ marginLeft: "20px" }}
                className="text-field"
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="dense"
                name="last_name"
                type="text"
                value={inputValues.last_name}
                onChange={handleInputChange}
              />
            </RowContainer>
            <RowContainer>
              <TextField
                className="text-field"
                label="Date of Birth"
                variant="outlined"
                style={{width: '50%', marginTop: 'unset'}}
                margin="dense"
                name="dob"
                type="date"
                InputLabelProps={{ shrink: true, required: true }}
                value={inputValues.dob}
                onChange={handleInputChange}
              />
              <CountrySelect onCountryChange={handleCountryChange} />
            </RowContainer>
            <CheckboxContainer onClick={() => handleGenderChange('male')}>
                <CheckboxLabel checked={selectedGender === "male"}>
                <CheckboxImage src={Male} alt={"male"} />
                 Male
                </CheckboxLabel>
            </CheckboxContainer>
            <CheckboxContainer onClick={() => handleGenderChange('female')}>
                <CheckboxLabel checked={selectedGender === "female"}>
                <CheckboxImage src={Female} alt={"female"} />
                 Female
                </CheckboxLabel>
            </CheckboxContainer>
            <Button onClick={() => handleNext("ID Card")}>Next</Button>
          </div>
        );
      case "Selfie with ID Card":
        return (
          <div>
            <SubTitle>Selfie with ID Card</SubTitle>
            <SelfieContainer>
              <SelfieComponent handleSelfie={handleSelfie} />
              <KYCImg>
                <img height={"100%"} src={KYCIcon} alt="kyc" />
              </KYCImg>
            </SelfieContainer>
            <div style={{display: 'flex'}}>
              <Button onClick={() => handlePrevious('Address Proof')}>Previous</Button>
              <Button style={{ marginBottom: "20px" }} onClick={handleSubmit}>
                Submit
              </Button>
            </div>
            <Disclaimer title={KYCDisclaimerTitle} list={KYCDisclaimerList} />
          </div>
        );
      case "ID Card":
        return (
          <div>
            <SubTitle>ID Card</SubTitle>
            <div>
              <label htmlFor="fileInput">
                <UploadDocumentContainer style={{ width: "30%" }}>
                  <img src={Upload} alt="upload" />
                  <div>{!idProof ? 'Upload Document here' : 'Change File'}</div>
                  {idProof &&
                    <UploadedFile>{idProof?.name}</UploadedFile>
                  }
                  
                </UploadDocumentContainer>
              </label>
              <FileInput
                id="fileInput"
                type="file"
                onChange={handleUploadIdCard}
              />
            </div>
            <div style={{display: 'flex'}}>
              <Button onClick={() => handlePrevious('Bio Data')}>Previous</Button>
              <Button style={{ marginBottom: "20px" }} onClick={() => handleNext("Address Proof")}> Next</Button>
            </div>
            <Disclaimer title={KYCDisclaimerTitle} list={KYCDisclaimerList} />
          </div>
        );
      case "Address Proof":
        return (
            <div>
            <SubTitle>Address Proof</SubTitle>
            <div>
                <label htmlFor="fileInput">
                <UploadDocumentContainer style={{ width: "30%" }}>
                    <img src={Upload} alt="upload" />
                    <div>{!addressProof ? 'Upload Document here' : 'Change File'}</div>
                    {addressProof &&
                    <UploadedFile>{addressProof?.name}</UploadedFile>
                    }
                </UploadDocumentContainer>
                </label>
                <FileInput
                id="fileInput"
                type="file"
                onChange={handleUploadAddress}
                />
            </div>
            <div style={{display: 'flex'}}>
              <Button onClick={() => handlePrevious('ID Card')}>Previous</Button>
              <Button style={{ marginBottom: "20px" }} onClick={() => handleNext("Selfie with ID Card")}> Next</Button>
            </div>
            <Disclaimer title={KYCDisclaimerTitle} list={KYCDisclaimerList} />
            </div>
        );
      default:
        return <></>;
    }
  };

  return (
      <CardContainer>
        <TitleRow>
          <Title>Identity Verification - KYC</Title>
          {(kycSubmitted || props.isKycSubmitted) ? (
            <KYCSubmittedContainer>
              {props.kycStatus ? <img src={Success} /> : <ErrorOutlineIcon color="warning" style={{marginRight: '5px'}} />}
              {props.kycStatus ? "Your Identity verification has been completed" : "Your Identity verification is in progress."}
            </KYCSubmittedContainer>
          ) : (
            <TabContainer style={{ float: "right" }}>
              <TabTitle
                active={activeTab === "Bio Data"}
              >
                Bio Data
              </TabTitle>
              <TabTitle
                active={activeTab === "ID Card"}
                style={{ marginLeft: "10px" }}
              >
                ID Card
              </TabTitle>
              <TabTitle
                active={activeTab === "Address Proof"}
                style={{ marginLeft: "10px" }}
              >
                Address Proof
              </TabTitle>
              <TabTitle
                active={activeTab === "Selfie with ID Card"}
                style={{ marginLeft: "10px" }}
              >
                Selfie with ID Card
              </TabTitle>
            </TabContainer>
          )}
        </TitleRow>
        {errroFields && <Alert color="error" severity="error"> {errroFields} </Alert>}
        {(!kycSubmitted && !props.isKycSubmitted) && getActiveElement()}
      </CardContainer>
  );
}
