import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CopyIcon from "../../../assets/images/Copy.svg";
import QRCode from "../../../assets/images/QRcode.svg";
import {
  CardContainer,
  StyledTabs,
  StyledTab,
  TabPanelContainer,
  Input,
  PaymentButton,
  InputContainer,
  PaymentButtonContainer,
  AvailableBalanceButton,
  ModalDescriptionContainer,
  ValueField,
  CopyIconImage,
  UPIIDContainer,
  QRCodeWrapper,
  InputFields,
  SubmitButton,
  QRImage,
  BankLink,
} from "./YTPSell.styles";
import { Container, DropdownContainer, DropdownContent, ModalTitle, MuiButton } from "../Styles/CommonStyles";
import CrossIcon from "../../../assets/images/Cross.svg";
import { SubTitle } from "../FAQ/FAQ.styles";
import { getYTPData } from "../../../uiapi/network/services/userService";
import {
  BuyCoinCreateData,
  buyCoinCreate,
  buyCoinRequest,
  getQRCode,
  sellCoinCreate,
  sellCoinRequest,
} from "../../../uiapi/network/services/transactionService";
import { Alert, TextField, useTheme } from "@mui/material";
import { BuyYTPHelper, SellYTPHelper } from "../../../utils/constants";
import { Title } from "../CommonTable/CommonTable.styles";
import { getWalletBalance } from "../../../uiapi/network/services/walletService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserReducerType } from "../../../uiapi/redux/reducers/userReducers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ResponseType } from "../../../uiapi/interface/uiModelTypes";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`buy-sell-tabpanel-${index}`}
      aria-labelledby={`buy-sell-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <TabPanelContainer>
            <InputContainer>{children}</InputContainer>
          </TabPanelContainer>
        </Box>
      )}
    </div>
  );
}

const YTPSell: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const userState: UserReducerType = useSelector((state: any) => state.user);
  const [value, setValue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState("");
  const [modalError, setModalError] = useState("");
  const [upiId, setUpiId] = useState("");
  const [qrImage, setQrImage] = useState<string>('');
  const [transactionId, setTransactionId] = useState("");
  const [file, setFile] = useState<any>(null);
  const [YTPvalue, setYTPValue] = useState<number>(0.52);
  const [payableAmount, setPayableAmount] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [bankDetails, setBankDetails] = useState<any>({});
  const [walletData, setWalletData] = useState<any>({});
  const [activeButton, setActiveButton] = useState("Express");
  const [showFiatDropdown, setFiatDropdown] = useState<boolean>(false);
  const [activeFiat, setActiveFiat] = useState<string>('');
  const [fiatValue, setFiatValue] = useState<any>("");

  const activeFiatValue = (userState?.fiatRate?.[activeFiat] || '');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setAmount(0);
    setFiatValue('');
    setError("");
    setBankDetails({});
    setPayableAmount("");
    setValue(newValue);
  };

  const handleInputChange = (name: string, value: any) => {
    switch (name) {
      case "amount":
        setAmount(value);
        setPayableAmount((value * YTPvalue).toFixed(2) + (` ${userState?.activeCurrency}` || ''));
        setFiatValue(((value * activeFiatValue)));
        break;
      case "upiId":
        setUpiId(value);
        break;
      case "txId":
        setTransactionId(value);
        break;
      case "fiat":
        setFiatValue(value);
        setPayableAmount(((value * (1/userState?.fiatRate?.[activeFiat]))*YTPvalue).toFixed(2) + (` ${userState?.activeCurrency}` || ''));
        setAmount(value/(activeFiatValue));
        break;
      default:
        break;
    }
  };

  const handleUploadImage = (event: any) => {
    const uploadedFile = event.target.files[0];
    // Accessing the uploaded file
    setFile(uploadedFile);
  };

  const handleBuyYTP = async () => {
    if(amount) {
      const ytpDetails:ResponseType = await buyCoinRequest(amount, userState?.activeCurrency);

      if (ytpDetails.success && ytpDetails.data) { 
        setError("");
        setModalError("");
        setUpiId(ytpDetails.data?.upi_id || '');
        setOpenModal(true);
  
        if (ytpDetails.data?.upi_id) {
          const params = ytpDetails.data?.upi_id?.split("@") || "";

          const upiDetails = await getQRCode(
            params[0],
            ytpDetails.data?.upi_id
          );

          if (upiDetails) {
            setQrImage(upiDetails);
          }
        } else {
          setBankDetails(ytpDetails?.data);
        }

      } else {
        setError(ytpDetails?.message);
      }
    } else {
    }
  }; 

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  const handleSwap = () => {
    navigate('/swap');
  }

  const handleSellYTP = async () => {
    const ytpDetails:ResponseType = await sellCoinRequest(amount, userState?.activeCurrency);

    if (ytpDetails && ytpDetails?.success && ytpDetails?.data) {
      setBankDetails(ytpDetails.data?.bank_details || {});
      setError("");
      setModalError("");
      setOpenModal(true);
    } else {
      setError(ytpDetails?.message || '');
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const fetchData = async () => {
    try {
      const walletBalance:ResponseType = await getWalletBalance();

      if (walletBalance && walletBalance?.success && walletBalance?.data) {
        //Update state with fetched data
        setWalletData(walletBalance.data);
      }
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = () => {
    setYTPValue(userState?.fiatRate?.[userState.activeCurrency] || 0);
    setPayableAmount((amount * userState?.fiatRate?.[userState.activeCurrency]).toFixed(2) + (` ${userState?.activeCurrency}` || ''));
  }

  const handleSubmit = async (value: number) => {
    if (value === 0) {
      let data: BuyCoinCreateData = {
        buy_amount: `${amount}`,
        txid: transactionId,
        upi_id: upiId,
        screenshot: file,
        fiat: userState?.activeCurrency,
        account_number: bankDetails?.account_number
      };

      const ytp:ResponseType = await buyCoinCreate(data);

      if(ytp && ytp?.success) {
          setOpenModal(false);
      } else {
          setModalError(ytp?.message || 'Something went wrong!');
      }
    } else {
      const sellYTP:ResponseType = await sellCoinCreate(amount, userState?.activeCurrency);

      if(sellYTP && sellYTP?.success){
        setOpenModal(false);

      } else {
        setModalError(sellYTP?.message || 'Something went wrong!');
      }
    }
  };

  const handleBlur = (e: any) => {
    if (
      !e.currentTarget.contains(e.relatedTarget as Node) &&
      (showFiatDropdown)
    ) {
      setFiatDropdown(false);
    }
  };

  const handleFiatCLick = (item: string) => {
    setActiveFiat(item);
    setFiatDropdown(false);
    setFiatValue(amount* userState?.fiatRate?.[item]);

  }

  const handleCopyText = (value: string) => {
    navigator.clipboard.writeText(value); // Copy input value to clipboard
  };

  const handleBankDetails = () => {
    navigate('/settings');
  }

  useEffect(() => {
    updateData();
  }, [openModal, userState.activeCurrency]);

  useEffect(()=> {
    fetchData();
  },[]);

  useEffect(()=> {
    setActiveFiat(Object.keys(userState?.fiatRate)?.[0] || '')
  },[userState?.fiatRate]);

  return (
    <CardContainer>
      {error && (
        <Alert
          style={{ textAlign: "center", marginBottom: "10px" }}
          color="error"
        >
          {" "}
          {error === "Bank details not found!" ? <>Bank details not found! <BankLink onClick={handleBankDetails}>Add Details</BankLink></> : error}
        </Alert>
      )}
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="buy sell tabs"
      >
        <StyledTab label="Buy YTP" />
        <StyledTab label="Sell YTP" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <Input
          type="number"
          onChange={(e: any) => handleInputChange("amount", e.target.value)}
          value={amount || ""}
          placeholder="Enter Amount in YTP"
        />
        <Input
          placeholder={`You will pay in ${userState.activeCurrency}`}
          value={payableAmount}
          disabled
          contentEditable={false}
        />
        <div style={{position: 'relative', width: '100%'}}>
        <Input
          type="text"
          onChange={(e: any) => handleInputChange("fiat", e.target.value)}
          value={fiatValue}
          placeholder={`Value in ${activeFiat}`}
        />
        <Container style={{right: '12%'}} tabIndex={1} onBlur={handleBlur}>
          <DropdownContainer
            onClick={() => setFiatDropdown(!showFiatDropdown)}
          >
            {" "}
            {activeFiat} <KeyboardArrowDownIcon />
          </DropdownContainer>
          {showFiatDropdown && (
              <DropdownContent>
                {Object.entries(userState?.fiatRate).map(([item], index) => {
                  return (
                    <>
                    {item !== userState?.activeCurrency &&
                      <a key={index} onClick={() => handleFiatCLick(item)}>
                        {" "}
                        {item}
                      </a>
                    }
                    </>
                  );
                })}
            </DropdownContent>
          )}
        </Container>
        </div>
        <PaymentButtonContainer>
          <PaymentButton
            onClick={() => handleButtonClick("Express")}
            isActive={activeButton === "Express"}
          >
            Express
          </PaymentButton>
          <PaymentButton
            onClick={() => handleSwap()}
            isActive={activeButton === "Swap"}
          >
            Swap
          </PaymentButton>
        </PaymentButtonContainer>
        <MuiButton onClick={handleBuyYTP} disabled={!amount}>Buy Now</MuiButton>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Input
          type="number"
          value={amount || ""}
          onChange={(e: any) => handleInputChange("amount", e.target.value)}
          placeholder="Enter Amount in YTP"
        />
        <Input
          placeholder={`You will pay in ${userState.activeCurrency}`}
          value={payableAmount}
          contentEditable={false}
          disabled
        />
          <div style={{position: 'relative', width: '100%'}}>
        <Input
          type="text"
          onChange={(e: any) => handleInputChange("fiat", e.target.value)}
          value={fiatValue}
          placeholder={`Value in ${activeFiat}`}
        />
        <Container style={{right: '12%'}} tabIndex={1} onBlur={handleBlur}>
          <DropdownContainer
            onClick={() => setFiatDropdown(!showFiatDropdown)}
          >
            {" "}
            {activeFiat} <KeyboardArrowDownIcon />
          </DropdownContainer>
          {showFiatDropdown && (
              <DropdownContent>
                {Object.entries(userState?.fiatRate).map(([item], index) => {
                  return (
                    <>
                    {item !== userState?.activeCurrency &&
                      <a key={index} onClick={() => handleFiatCLick(item)}>
                        {" "}
                        {item}
                      </a>
                    }
                    </>
                  );
                })}
            </DropdownContent>
          )}
        </Container>
        </div>
        <PaymentButtonContainer>
          <PaymentButton
            onClick={() => handleButtonClick("Express")}
            isActive={activeButton === "Express"}
          >
            Express
          </PaymentButton>
          <PaymentButton
            onClick={() => handleSwap()}
            isActive={activeButton === "Swap"}
          >
            Swap
          </PaymentButton>
        </PaymentButtonContainer>
        <MuiButton onClick={handleSellYTP} disabled={!amount}>Sell Now</MuiButton>
      </TabPanel>

      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bgcolor: "white",
            border: "none",
            boxShadow: 24,
            padding: "20px 60px",
            borderRadius: "8px",
            width: "40%",
            height: upiId ? 'auto' : "80vh",
            overflow: "auto",
            [theme.breakpoints.down(1300)]: {
              width: "60%",
            }, 
            [theme.breakpoints.down(1200)]: {
              height: "70vh",
              overflow: "auto",
            },
            [theme.breakpoints.down(560)]: {
              padding: "20px 20px",
              width: "90%",
              height: "80vh",
            },
          }}
        >
          <img
            src={CrossIcon}
            onClick={handleModalClose}
            style={{ cursor: "pointer", position: "absolute", right: 10 }}
          />
          <ModalTitle id="modal-title">
            {value === 0 ? "Buy" : "Sell"} YTP
          </ModalTitle>
          {modalError && (
            <Alert
              style={{ textAlign: "center", marginBottom: "10px" }}
              color="error"
            >
              {" "}
              {modalError}{" "}
            </Alert>
          )}
          <div>
            <AvailableBalanceButton>
              Available Balance : {walletData?.balance?.toFixed(2) || 0} YTP
            </AvailableBalanceButton>
          </div>
          <ModalDescriptionContainer>
            <div>Price 1 YTP = {YTPvalue} {userState.activeCurrency || 'INR'}</div>
            <div>Buy Amount: {amount} YTP</div>
            <div>Total Payment Amount: {payableAmount} </div>
          </ModalDescriptionContainer>
          <SubTitle style={{ textAlign: "center", padding: "20px" }}>
            {value === 0 ? BuyYTPHelper : SellYTPHelper}
          </SubTitle>
          {value === 0 ? (
            <UPIIDContainer style={{display: upiId ? 'flex' : 'block'}}>
              <div>
                <ValueField>
                  <InputFields
                    type="text"
                    placeholder="Provide Transaction id"
                    onChange={(e: any) =>
                      handleInputChange("txId", e.target.value)
                    }
                  />
                </ValueField>
                <ValueField>
                  <InputFields
                    type="file"
                    placeholder="Upload Screenshot of payment"
                    onChange={(e: any) => handleUploadImage(e)}
                  />
                </ValueField>
              </div>
              {userState.activeCurrency === "INR" ?
              <QRCodeWrapper>
                <QRImage dangerouslySetInnerHTML={{ __html: qrImage }} />
                <ValueField>
                  {upiId}
                  <CopyIconImage
                    src={CopyIcon}
                    alt="copy"
                    onClick={() => handleCopyText(upiId)}
                  />
                </ValueField>
              </QRCodeWrapper> :  <>
              <Title>YatriPay Bank Account Details</Title>
              <ValueField>
                <TextField
                  className="text-field"
                  label="Account Holder Name"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    marginRight: '10px',
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                      marginRight: 'unset',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="acnm"
                  disabled
                  value={bankDetails?.account_holder_name}
                />
                <TextField
                  className="text-field"
                  label="Bank Name"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="bank name"
                  disabled
                  value={bankDetails?.bank_name}
                />
              </ValueField>
              <ValueField>
                <TextField
                  className="text-field"
                  label="Account Number"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    marginRight: '10px',
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                      marginRight: 'unset',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="bank name"
                  disabled
                  value={bankDetails?.account_number}
                />
                 <TextField
                  className="text-field"
                  label="IFSC/IBAN/BSB Code"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="IFSC"
                  disabled
                  value={bankDetails?.ifsc_code || bankDetails?.iban_code || bankDetails?.bsb_code || ''}
                />
              </ValueField>
            </>}
            </UPIIDContainer>
          ) : (
            <>
              <Title>Your Bank Account Details</Title>
              <ValueField>
                <TextField
                  className="text-field"
                  label="Account Holder Name"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    marginRight: '10px',
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                      marginRight: 'unset',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="acnm"
                  disabled
                  value={bankDetails?.account_holder_name}
                />
                 <TextField
                  className="text-field"
                  label="Bank Name"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="bank name"
                  disabled
                  value={bankDetails?.bank_name}
                />
              </ValueField>
              <ValueField>
                <TextField
                  className="text-field"
                  label="Account Number"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    marginRight: '10px',
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                      marginRight: 'unset',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="bank name"
                  disabled
                  value={bankDetails?.account_number}
                />
                <TextField
                  className="text-field"
                  label="IFSC/IBAN/BNB Code"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="IFSC"
                  disabled
                  value={bankDetails?.ifsc_code}
                />
              </ValueField>
              <ValueField>
                <TextField
                  className="text-field"
                  label="UPI ID"
                  variant="outlined"
                  sx={{
                    width: '48%', 
                    marginRight: '10px',
                    [theme.breakpoints.down(906)]: {
                      width: '100%',
                      marginRight: 'unset',
                    },           
                  }}
                  margin="dense"
                  type="text"
                  name="UpiID"
                  disabled
                  value={bankDetails?.upi_id}
                />
              </ValueField>
            </>
          )}
          <SubmitButton
            style={{ marginTop: value === 0 ? "" : "20px" }}
            onClick={() => handleSubmit(value)}
          >
            Submit
          </SubmitButton>
        </Box>
      </Modal>
    </CardContainer>
  );
};

export default YTPSell;
