import * as types from '../../interface/uiModelTypes';
import { store } from '../../redux/store';
import { axiosWrapper } from './axiosWrapper';

export async function getBannerImages() {

    const apiEndpoint: string = "/api/v1/core/banners/list/";

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
    };

    return axiosWrapper(request);

}

export async function getStakingList() {

    const apiEndpoint: string = "/api/v1/stakes/staking/types/list/?type_name=LOCKED";

    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getStakingTypeDetails(duration: number) {

    const apiEndpoint: string = `/api/v1/stakes/staking/type/${duration}/validity/details/`;

    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getAllStakingDetails() {

    const apiEndpoint: string = "/api/v1/stakes/staking/list/?package_type=LOCKED";

    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getLiveCrypto(currency: string) {

    const token = store.getState().user?.token || '';

    let params =  {
        'start' : '1',
        'limit' : '10',
        'convert' : `${currency}`,
        'sort' : 'market_cap',
        'sort_dir' : 'desc',
    }

    const apiEndpoint: string = `/api/v1/core/live/crypto/list/?limit=${params['limit']}&convert=${params['convert']}&sort=${params['sort']}&sort_dir=${params['sort_dir']}&start=${params['start']}`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}


export async function getCoinList() {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/finance/coin/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}


export async function getRewardsList(page: number) {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/promotion/rewards/list/?page=${page}`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}


export async function getStakingDataBasedOnType(name: string) {

    const apiEndpoint: string = `/api/v1/stakes/staking/type/${name}/name/details/`;

    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export const getFAQDetails = (code: string) => {

    const apiEndpoint: string = `/api/v1/core/faq/list/?code=${code}`;

    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getSwapHistoryList() {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/finance/swaps/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}


export async function createSwap(amt: number, buyCoin: string, sellCoin: string) {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/finance/swaps/create/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        body: {
            amount: amt,
            buy_coin: buyCoin,
            sell_coin:sellCoin
        },
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}


export async function getOffersList(status: boolean) {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/promotion/offers/list/?status=${status}`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getSocialList() {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/social/handle/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getTasksList() {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/promotion/task/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getSubTaskList(id: number) {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/promotion/sub-task/${id}/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function createTask(userId: number, taskId: number) {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/promotion/user-task/create/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        credentials: false,
        body: {
            user: userId ,
            task: taskId
        },
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getClaimNow(id: number) {

    const token = store.getState().user?.token || '';

    const apiEndpoint: string = `/api/v1/promotion/offers/referral/${id}/details/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}