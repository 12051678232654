import React from 'react';
import QRCode from 'qrcode.react';

interface Props {
    base64ImageData: any
}

const QRCodeComponent = (props: Props) => {
  // Decode base64 image data
  // const decodedImageData = atob(props.base64ImageData);

  return (
    <div>
      {/* <QRCode value={decodedImageData} /> */}
      <img height={200} width={200} src={props.base64ImageData || ''} />
    </div>
  );
};

export default QRCodeComponent;