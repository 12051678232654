import React, { useState } from "react";
import { Tab, Tabs } from "@mui/material";
import styled from "styled-components";

// Styled component for the container
const TabContainer = styled.div`
  width: 100%;
  color: black !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;

  .MuiTabs-indicator {
    background-color: #212529 !important;
  }

  .Mui-selected {
    color: black !important;
  }
`;

interface Props {
  tab: number;
}
const StyledTab = styled(Tab)<Props>`
  && {
    padding: 10px 0px !important;
    text-transform: capitalize !important;
    font-family: Poppins !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    letter-spacing: 0em !important;
    font-size: 18px !important;
    margin-left: ${props => props.tab === 0 ? "" : "20px" } !important;
  }
`;

interface CommonTabProps {
  tabNames: string[];
  tabComponents: any;
}

const CommonTab2: React.FC<CommonTabProps> = ({ tabNames, tabComponents }) => {

  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getComponent = (index: number) => {
    switch (index) {
      case 0:
        return <div>{tabComponents[0]}</div>;
      case 1:
        return <div>{tabComponents[1]}</div>;
      default:
        return null;
    }
  };

  return (
    <TabContainer>
      <Tabs value={value} onChange={handleChange} aria-label="tabs">
        {tabNames.map((name, index) => (
          <StyledTab tab={index} key={index} label={name} />
        ))}
      </Tabs>
      <div>{getComponent(value)}</div>
    </TabContainer>
  );
};

export default CommonTab2;
