import { Grid } from "@mui/material";
import React from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import Disclaimer from "../../common/Disclaimer/Disclaimer";
import FAQ from "../../common/FAQ/FAQ";
import StalkingCard from "./Cards/StalkingCard";
import StalkingPortfolio from "./Cards/StalkingPort";

export default function Stalking() {
    return(
        <Grid container>
            <Grid item xs={12}>
                <HeadTitle>Staking</HeadTitle>
            </Grid>
            <Grid item xs={12}>
                <StalkingCard />
            </Grid>
            <Grid item xs={12}>
                <StalkingPortfolio />
            </Grid>
            <Grid item xs={12}>
                <Disclaimer />
            </Grid>
            <Grid item xs={12}>
                <FAQ code="staking" />
            </Grid>
        </Grid>
    )
}