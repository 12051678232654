import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import CommonTable from "../../common/CommonTable/CommonTable";
import { TransactionFields } from "../../../utils/utils";
import TotalMoney from "./Cards/TotalMoney";
import DepositSendCard from "./Cards/DepositSendCard";
import CoinRewardTable from "./CoinRewardsTab/CoinRewardsTab";
import { FormattedTransaction, TableResponseType } from "../../../uiapi/interface/uiModelTypes";
import { getTransactionHistory, getWalletBalance } from "../../../uiapi/network/services/walletService";
import { getCoinList } from "../../../uiapi/network/services/utilService";
import { TransactionReducerType } from "../../../uiapi/redux/reducers/transactionReducers";
import { useSelector } from "react-redux";
import { TablePageRegex } from "../../common/Validation/validation";

export interface CoinList {
  label: string,
  ticker: string
}
export default function Wallet() {

    const transactionStates: TransactionReducerType = useSelector((state: any) => state.transaction);
    const [transactionData, setTransactionData] = useState<FormattedTransaction[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [data, setData] = useState<any>();
    const [alert, setAlert] = useState('');
    const [coinList, setCoinList] = useState<CoinList[]>([]);
    const [nextpage, setNextPage] = useState<number | null>(null);
    const [prevpage, setPrevPage] = useState<number | null>(null);
    const [totalCount, setTotalCount] = useState<number | null>(0);
    

    const getWalletDetails = async(ticker: string) => {
      const response = await getWalletBalance(ticker);
      if(response && response.data) {
        // Update state with fetched data
        setData(response.data);
      } else {
        setData({});
        setAlert(response);
      }
    }

    const getCoinsList = async () => {
      try {
        const coins = await getCoinList();
        if (coins && coins?.success && coins.data?.length > 0) {
          const list: CoinList[] = coins.data.slice(0, 10).map((element: any) => ({
            label: element.name,
            ticker: element.ticker,
          }));
          setCoinList(list);

        }
      } catch (error) {
      }
    };

    const fetchTransactionHistory = async (page: number) => {
      setLoading(true);
      try {
        setError("");
        const transactionDataResponse:TableResponseType = await getTransactionHistory(page);
        if (
          transactionDataResponse &&
          transactionDataResponse?.data?.length > 0
        ) {
          const formattedData: FormattedTransaction[] =
            transactionDataResponse.data.map((transaction: any) => ({
              Coin: transaction.coin.name,
              Category: transaction.category,
              "Total Amount": transaction.total_amount,
              Status: transaction?.status || "",
              Confirmations: `${transaction.confirmations} +`,
              Explorer: transaction.explorer_link,
              TxType: transaction.trans_type,
              Txid: transaction.txid,
              Address: transaction.address,
              Date: transaction.created_at,
            }));
          setTransactionData(formattedData);
          setTotalCount(transactionDataResponse?.count)
          setNextPage(
            transactionDataResponse.next 
              ? parseInt(transactionDataResponse.next.match(TablePageRegex)?.[1] || '', 10) 
              : null
          );
          setPrevPage(
            transactionDataResponse.previous 
              ? (parseInt(transactionDataResponse.previous.match(TablePageRegex)?.[1] || '', 10) || 1) 
              : null
          );
        } else {
          setTransactionData([]);
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    const handlePrevPage = () => {
      prevpage && fetchTransactionHistory(prevpage);
    };
  
    const handleNextPage = () => {
      nextpage && fetchTransactionHistory(nextpage);
    };

      
    useEffect(() => {
      fetchTransactionHistory(1);
      getWalletDetails('ytp');
      getCoinsList();
    }, [transactionStates?.transactionCreated]);

    return(
        <Grid container>
            <Grid item xs={12}>
                <HeadTitle>Wallet</HeadTitle>
            </Grid>
            <Grid item xs={12}>
                <TotalMoney />   
            </Grid>
            <Grid item xs={12}>
                <DepositSendCard data={data} callWallet={getWalletDetails} alert={alert} coinList={coinList} />   
            </Grid>
            <Grid item xs={12}>
                <CoinRewardTable />
            </Grid>
            <Grid item xs={12}>
              <CommonTable
                  title="Transaction History"
                  fields={TransactionFields}
                  data={transactionData}
                  loading={loading}
                  error={error}
                  handleNext={() => handleNextPage()}
                  handlePrev={() => handlePrevPage()}
                  prevPage={prevpage}
                  nextPage={nextpage}
                  totalCount={totalCount}
              />
            </Grid>
        </Grid>
    )
}