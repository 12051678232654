import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CopyIcon from "../../../../assets/images/Copy.svg"; // Import your copy icon image
import QRCode from "../../../../assets/images/QRcode.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Alert, Backdrop, Button, CircularProgress, Fade, Grid, Modal, TextField } from "@mui/material";
import { currencies } from "../../../../utils/domUtils";
import { CoinList } from "../Wallet";
import { getCurrenciesValue } from "../../../../uiapi/network/services/userService";
import { ModalBox, Title } from "../../Settings/Cards/Card";
import CrossIcon from "../../../../assets/images/Cross.svg";
import { sendMoney } from "../../../../uiapi/network/services/walletService";
import { ResponseType } from "../../../../uiapi/interface/uiModelTypes";
import { setTransactionCreated } from "../../../../uiapi/redux/actions/transactionAction";
import { useDispatch, useSelector } from "react-redux";
import { RedirectLinks } from "../../../common/Styles/CommonStyles";
import { useNavigate } from "react-router-dom";
import { UserReducerType } from "../../../../uiapi/redux/reducers/userReducers";
import { PinErrorMessage } from "../../../../utils/constants";

const SendCardContainer = styled.div`
  width: 100%;
`;

const TabComponentContainer = styled.div`
  padding: 20px 0px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const ValueField = styled.div`
  position: relative;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

const MuiButton = styled(Button)`
  background-color: #b11f72 !important;
  color: #fff !important;
  margin-left: 10px;
  display: block;
  border: none;
  height: 52px;
  min-width: unset !important;
  padding: 10px 2% !important;
  border-radius: 10px !important;
  cursor: pointer;
  font-weight: bold !important;
  font-family: poppins !important;
  font-size: 16px !important;

  @media (max-width: 650px) {
    margin-top: 20px;
    padding: 10px 5%;
  }

  &:hover {
    background-color: #b11f72 !important;

  }
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  // width: 20px;
  color: pink;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  color: #b11f72;
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
  background-color: white;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000 !important;

  a {
    display: block;
    cursor: pointer;
    padding: 10px 20px;

    &:hover {
      background-color: #e0e7e9;
    }
  }
`;



interface SendCardProps {
  coinList: CoinList[];
}

const SendCard = (props: SendCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState: UserReducerType = useSelector((state: any) => state.user);
  const [formData, setFormData] = useState({
    address: 0,
    crypto_amount: 0,
    flat_amount: 0,
    tr_pin: 0
  });
  const [code, setCode] = useState<string[]>(new Array(4).fill(''));
  const [error, setError] = useState<string | null>(null); // New state for error
  const [pinError, setPinError] = useState<string>(''); // New state for error
  const [showAmountDropdown, setAmountDropdown] = useState<boolean>(false);
  const [showFlatDropdown, setFlatDropdown] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState("YTP");
  const [selectedFlatAmount, setSelectedFlatAmount] = useState("INR");
  const [currencyDetail, setCurrencyDetail] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uiAlert, setAlert] = useState('');

  const handleClose = () => {
    setCode(new Array(4).fill(''));
    setOpen(false);
  };

  const handleBlur = (e: any) => {
    if (
      !e.currentTarget.contains(e.relatedTarget as Node) &&
      (showAmountDropdown || showFlatDropdown)
    ) {
      setFlatDropdown(false);
      setAmountDropdown(false);
    }
  };

  const handleChange = (e: any) => {
    setAlert('');
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFlatClick = (value: string) => {
    setSelectedFlatAmount(value);
    setFlatDropdown(!showFlatDropdown);
  };

  const handleAmountClick = (value: string) => {
    setSelectedAmount(value);
    setAmountDropdown(!showAmountDropdown);
  };

  const getCurrenciesDetail = async () => {
    const currency:ResponseType = await getCurrenciesValue(selectedAmount);

    if (currency && currency?.success && currency?.data) {
      setCurrencyDetail(currency.data);
      setFormData((prevData: any) => ({
        ...prevData,
        flat_amount:
          currencyDetail?.[selectedFlatAmount] * formData.crypto_amount,
      }));
    }
  };

  const handleSend = () => {
    if(formData.address && formData.crypto_amount && formData.flat_amount) {
      if(userState.userData?.pin_status) {
        setOpen(true);
      } else {
        setAlert(PinErrorMessage);
      }
    } else {
      setAlert("Please Fill all the fields. ")
    }
  };

  useEffect(() => {
    getCurrenciesDetail();
  }, [selectedAmount]);

  useEffect(() => {
    setError("");
    setCode(new Array(4).fill(''));
    setFormData((prevData: any) => ({
      ...prevData,
      flat_amount: (
        currencyDetail?.[selectedFlatAmount] * formData.crypto_amount
      ).toFixed(2),
    }));
  }, [formData.crypto_amount]);

  const handleConfirmPin = async() => {

    const enteredCode = parseInt(code.join(''));

    if(enteredCode) {
      setLoading(true);
      const sendData:ResponseType = await sendMoney(formData.address, formData.crypto_amount,selectedAmount,enteredCode );

      if(sendData.data && sendData.success) {
        setOpen(false);
        setOpen(false);
        setLoading(false);
        dispatch(setTransactionCreated(true));
      } else {
        setOpen(false);
        setError(sendData.message || '');
        setLoading(false);
      }
    } else {
      setPinError('Please fill all the fields.');
    }
  }

  const handlePinChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const newCode = [...code];
    newCode[index] = event.target.value;
    setCode(newCode);
    if (event.target.value.length === 1 && index < 5) {
      const nextSibling = document.getElementById(`code${index + 1}`) as HTMLInputElement;
      nextSibling?.focus();
    }
  };

  return (
    <SendCardContainer>
      {(uiAlert || error) && (
        <Alert style={{ marginTop: "20px" }} color="error">
          {(uiAlert === PinErrorMessage ? <>{uiAlert}. <RedirectLinks onClick={() => navigate('/settings')}>Set PIN.</RedirectLinks></> : uiAlert ) || error}
        </Alert>
      )}
      <TabComponentContainer>
        <div>
          <ContentContainer>
            <ValueField>
              <TextField
                className="text-field"
                label="Receiver Address/Phone number"
                variant="outlined"
                fullWidth
                margin="dense"
                type="text"
                name="address"
                value={formData.address || ""}
                onChange={handleChange}
              />
            </ValueField>
            <ValueField>
              <TextField
                className="text-field"
                label="Crypto Amount"
                variant="outlined"
                fullWidth
                margin="dense"
                type="text"
                value={formData.crypto_amount || ""}
                name="crypto_amount"
                onChange={handleChange}
              />
              <Container tabIndex={1} onBlur={handleBlur}>
                <DropdownContainer
                  onClick={() => setAmountDropdown(!showAmountDropdown)}
                >
                  {" "}
                  {selectedAmount} <KeyboardArrowDownIcon />
                </DropdownContainer>
                {showAmountDropdown && (
                  <DropdownContent>
                    {props?.coinList &&
                      props?.coinList.length > 0 &&
                      props?.coinList.map((item, index) => {
                        return (
                          <a
                            key={index}
                            onClick={() => handleAmountClick(item.ticker)}
                          >
                            {" "}
                            {item.ticker}
                          </a>
                        );
                      })}
                  </DropdownContent>
                )}
              </Container>
            </ValueField>
            <ValueField>
              <TextField
                className="text-field"
                label="Fiat Amount"
                variant="outlined"
                fullWidth
                margin="dense"
                type="text"
                name="flat_amount"
                value={formData.flat_amount || ""}
                onChange={handleChange}
              />
              <Container tabIndex={1} onBlur={handleBlur}>
                <DropdownContainer
                  onClick={() => setFlatDropdown(!showFlatDropdown)}
                >
                  {" "}
                  {selectedFlatAmount} <KeyboardArrowDownIcon />
                </DropdownContainer>
                {showFlatDropdown && (
                  <DropdownContent>
                    {currencies.map((item, index) => {
                      return (
                        <a key={index} onClick={() => handleFlatClick(item)}>
                          {" "}
                          {item}
                        </a>
                      );
                    })}
                  </DropdownContent>
                )}
              </Container>
            </ValueField>
            <MuiButton onClick={handleSend}>Send</MuiButton>
          </ContentContainer>
        </div>
      </TabComponentContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <ModalBox>
            <img
              src={CrossIcon}
              onClick={handleClose}
              style={{ cursor: "pointer", position: "absolute", right: 10 }}
            />
            <Title>Enter Transaction Pin</Title>
            <Grid container spacing={2} justifyContent="center" style={{marginTop: '10px'}}>
              {code.map((_, index) => (
                <Grid
                  item
                  key={index}
                  justifyContent={"center"}
                  style={{ marginBottom: "20px" }}
                >
                  <TextField
                    id={`code${index}`}
                    inputProps={{ maxLength: 1 }}
                    onChange={handlePinChange(index)}
                    value={code[index]}
                    variant="outlined"
                    fullWidth
                    error={!!pinError}
                    sx={{ width: "6ch", input: { textAlign: "center" } }}
                  />
                </Grid>
              ))}
            </Grid>
            {(pinError) && (
              <Alert color="error">
                {pinError}
              </Alert>
             )}
            <MuiButton style={{ margin: "auto !important", padding: "25px 30px !important", width: '150px'}} onClick={() => handleConfirmPin()} variant="contained" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirm'}
            </MuiButton>
          </ModalBox>
        </Fade>
      </Modal>
    </SendCardContainer>
  );
};

export default SendCard;
