import axios from 'axios';
import { axiosRequest } from '../../interface/uiModelTypes';

/**
 * This wrapper is used for all the api's call
 * @param request ${axiosRequest}
 * @returns the response from server
 */
export async function axiosWrapper(request: axiosRequest) {

    try {
        const res = await axios({
            method: request.method,
            url:  request.fullUrl ? request.fullUrl : `${process.env.REACT_APP_SERVER_URL}${request.url}`,
            data: request?.body,
            headers: request?.headers,
            withCredentials: request?.credentials,
            cancelToken: request?.cancelToken
        });
        return (res.data);
    } catch (err: any) {
        return err?.response?.data || 'error';
    }
}
