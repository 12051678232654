import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonTab2 from '../../../common/CommonTabs/CommonTab2';
import DepositCardComponent from './DepositCard';
import SendCard from './SendCard';
import { CoinList } from '../Wallet';

const DepositCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-top: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 10px;

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
    color: #212529 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
`;

const HelperText = styled.div`
    color: #212529 !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;

    span {
      color: #878787!important;
    }
`;

interface Props{
  data : any;
  callWallet: (data: string) => void;
  alert: string;
  coinList: CoinList[];
}

const DepositSendCard: React.FC<Props> = (props: Props) => {

  return (
    <DepositCard>
      <TitleRow>
        <HelperText>Available Balance: <span>{props.data?.balance?.toFixed(2) || 0} {props.data?.coin?.ticker} </span></HelperText>
      </TitleRow>
      <CommonTab2 tabNames={['Deposit','Send']} tabComponents={[<DepositCardComponent data={props.data} callWallet={props.callWallet} alert={props.alert} />, <SendCard coinList={props?.coinList || []}/>]} />
    </DepositCard>
  );
};

export default DepositSendCard;
