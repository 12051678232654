import styled from 'styled-components';

// Styled components
export const CarouselContainer = styled.div`
  height: 100%;

  overflow: hidden;
  position: relative;
`;

export const Slide = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  height: 100%;  
`;

export const Image = styled.img`
  // object-fit: cover;
  display: block;
  width: 100%;

`;

export const NavigationButton = styled.div`
  position: absolute;
  top: 50%;
  color: white !important;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  width: 30px;
  height: 30px;
  &:first-child {
    left: 30px;
  }
  &:last-child {
    right: 30px;
  }
`;