import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import CopyIcon from "../../../assets/images/Copy.svg";

interface TooltipProps {
    context : string ;
}


export default function CopyTooltip(props: TooltipProps) {

    const [showTooltip, setTooltip] = useState(false);

    const handleCopyCode = (value: string) => {
      // This will copy the given code
      navigator && navigator.clipboard.writeText(value)
        .then(() => {
          setTooltip(true);
          setTimeout(() => setTooltip(false), 500);
        })
        .catch((error) => {
        });
    };

    return (
        <div onClick={() => handleCopyCode(props.context)}>
            <Tooltip
                open={showTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="bottom"
                arrow
                title={'Copied!'}
            >
            <img style={{marginLeft: '5px', cursor: 'pointer'}}  src={CopyIcon} alt="copy" />

            </Tooltip>
        </div>
    )

}