import React from 'react';
import {
  StyledTableContainer,
  StyledTableHeadCell,
  StyledTableRow,
  NoDataContainer,
  Title,
  StyledTableBodyCell
} from './CommonTable.styles';
import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { StyledButton } from '../CommonTable/CommonTable.styles';
import { StakingStatus } from '../../../utils/enums/enums';
import { getColorBasedOnName, getColorBasedOnStatus } from '../../../utils/domUtils';

interface TableField {
  label: string;
  sorting?: boolean;
}

interface CommonTableProps {
  fields: TableField[];
  data: any[];
  noDataText?: string;
}

const CommonTable2: React.FC<CommonTableProps> = ({ fields, data, noDataText = 'No data found' }) => {

  const getStyledValue = (field: string, value: any) => {
    switch (field) {
      case "Status":
        return (
          <span style={{ color: getColorBasedOnName(value), fontWeight: 600 }}>
            {[value]}
          </span>
        );
      default:
        return (
          <div style={{maxWidth: '250px', textOverflow:'ellipsis', overflow: 'hidden'}}>
            <abbr title={value}>
              {value}
            </abbr>
          </div>
        );
    }
  };

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {fields.map((field, index) => (
              <StyledTableHeadCell key={index}>
                {field.label}
                {/* Add sorting icon and logic if field.sorting is true */}
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{position:'relative'}}>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {fields.map((field) => (
                  <StyledTableBodyCell key={field.label}>
                    {getStyledValue(field.label, row[field.label] )}
                  </StyledTableBodyCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
          <></>
          )}
        </TableBody>  
      </Table>
      {data.length === 0 && <NoDataContainer>
              {noDataText}
      </NoDataContainer>}
    </StyledTableContainer>
  );
};

export default CommonTable2;
