import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import CommonTab2 from '../../../common/CommonTabs/CommonTab2';
import CommonTable2 from '../../../common/CommonTable2/CommonTable';
import { CoinFields, RewardFields } from '../../../../utils/utils';
import { getCoinList, getRewardsList } from '../../../../uiapi/network/services/utilService';
import { FormattedCoin, FormattedRewards, ResponseType, TableResponseType } from '../../../../uiapi/interface/uiModelTypes';
import CommonTable from '../../../common/CommonTable/CommonTable';
import { TablePageRegex } from '../../../common/Validation/validation';


const CoinRewardContainer = styled.div`
  background-color: #ffffff;
  padding-top: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  // padding: 20px 25px;
  border-radius: 8px;
  margin-top: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
`;

const Title = styled.div`
    color: #212529 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
`;


const CoinRewardTable: React.FC = () => {
  const tabNames = ['Coin', 'Rewards'];
  const [coinData, setCoinData] = useState<FormattedCoin[]>([]);
  const [rewardsData, setRewardsData] = useState<FormattedRewards[]>([]);
  const [nextpage, setNextPage] = useState<number | null>(null);
  const [prevpage, setPrevPage] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(0);

  const getRewardsData = async(page: number) => {

    const rewardsData:TableResponseType = await getRewardsList(page);

      if(rewardsData && rewardsData?.success && rewardsData?.data?.length > 0) {
        const formattedData: FormattedRewards[] = rewardsData.data.map(
          (rewards: any) => (  
            {
              Coin: `${rewards.coin.name} (${rewards.coin.ticker})` ,
              'Reward Type' : rewards?.reward_type || '',
              'Amount': rewards?.amount || 0,
              'Status': rewards?.status || '',
              'Staking ID': rewards?.referrer_staking?.staking_number || '',
              'Username': rewards?.user?.first_name || '',
              'Staking Type': rewards?.staking_type || '',
              'Date/Time': rewards?.created_at || ''     
            }
          )
        );
        setRewardsData(formattedData);
        setTotalCount(rewardsData?.count)
        setNextPage(
          rewardsData.next 
            ? parseInt(rewardsData.next.match(TablePageRegex)?.[1] || '', 10) 
            : null
        );
        setPrevPage(
          rewardsData.previous 
            ? (parseInt(rewardsData.previous.match(TablePageRegex)?.[1] || '', 10) || 1) 
            : null
        );
      }
  }

  const getCoinsTableData = async() => {
    const coinData:TableResponseType = await getCoinList();

    if(coinData && coinData.data && coinData.data?.length > 0) {
      const formattedData: FormattedCoin[] = coinData.data.map(
        (coin: any) => (  
          {
            Coin: coin.name,
            'Available Balance' : coin.wallets_data?.[0]?.balance || "0.0000",
            'Welcome Bonus': coin.wallets_data?.[0]?.welcome_bonus || "0.0000",
            'Staking Reward': coin.wallets_data?.[0]?.staking_roi || "0.0000",
            'Total Balance': coin.wallets_data?.[0]?.balance || "0.0000"          
          }
        )
      );
      setCoinData(formattedData);
    }
  }

  const handlePrevPage = () => {
    prevpage && getRewardsData(prevpage);
  };

  const handleNextPage = () => {
    nextpage && getRewardsData(nextpage);
  };

  useEffect(()=> {
    getRewardsData(1);
    getCoinsTableData();
  },[])

  return (
    <CoinRewardContainer>
      <CommonTab2
        tabNames={tabNames}
        tabComponents={[
          <CommonTable 
            fields={CoinFields} 
            data={coinData} 
            title={""} 
             
             
          />,
          <CommonTable 
            fields={RewardFields} 
            data={rewardsData} 
            title={""} 
            handleNext={() => handleNextPage()}
            handlePrev={() => handlePrevPage()}
            prevPage={prevpage}
            nextPage={nextpage}
            totalCount={totalCount}
          />,
        ]}
      />
    </CoinRewardContainer>
  );
};


export default CoinRewardTable;
