import React, { useEffect, useState } from "react";
import { UserReducerType } from "../../../uiapi/redux/reducers/userReducers";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import { Grid, IconButton, Snackbar, TextField } from "@mui/material";
import AccountCard, { SubTitle } from "./Cards/Card";
import KYC from "./Cards/KYC";
import { forgotPassword, getKYCList } from "../../../uiapi/network/services/userService";
import { ChangePasswordData, ResponseType } from "../../../uiapi/interface/uiModelTypes";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetPin } from "../../../uiapi/network/services/walletService";
import BankAccountCard from "./Cards/BankAccountCard";
import { setPinStatus } from "../../../uiapi/redux/actions/userActions";

export const SettingsContainer = styled.div`
  padding: 20px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
`;

export const Title = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`;

export const AccountInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;

  img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
  }

  button {
    background-color: #b11f72;
    color: #fff;
    display: block;
    border: none;
    height: 52px;
    padding: 10px 5%;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    font-family: poppins;
    font-size: 16px;
    margin-top: 20px;
  }
`;

export const UserDataContainer = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  padding: 0% 5%;
`;

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const InputField = styled.input`
  width: 50%;
  padding: 10px;
  height: 30px;
  border-radius: 8px;
  border: 1.5px solid #e0e0e0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  &:focus {
    // border: none;
    outline: none;
  }
`;

interface FormData {
  password: string;
  confirm_password: string;
}

function Settings() {
  const dispatch = useDispatch();
  const userState: UserReducerType = useSelector((state: any) => state.user);
  const [isKycSubmitted, setKycSubmitted] = useState<boolean>(false);
  const [kycStatus, setKYCStatus] = useState(false);
  const [code, setCode] = useState<string[]>(new Array(4).fill(''));

  const [formData, setFormData] = useState<FormData>({
    confirm_password: "",
    password: ""
  });
  const [backendError, setBackendError] = useState(false);
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState(false); // New state for loading
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
      password: "",
      confirm_password: ""
  });

  const [close, setClose] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    // Clear validation error when user starts typing
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
    }));
    setBackendError(false);
  };

  const [inputValues, setInputValues] = useState({
    first_name: userState?.userData.first_name || "",
    last_name: userState?.userData.last_name || "",
    email: userState?.userData.email || "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setInputValues((inputValues) => ({
      ...inputValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePinChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCode = [...code];
    newCode[index] = event.target.value;
    setCode(newCode);
    if (event.target.value.length === 1 && index < 5) {
      const nextSibling = document.getElementById(`code${index + 1}`) as HTMLInputElement;
      nextSibling?.focus();
    }
    setClose(false);
  };

  const handleResetPin = async() => {
    const reset:ResponseType = await resetPin(parseInt(code.join('')));

    if(reset && reset?.success && reset?.data) {
      setSuccess(reset.message);
      dispatch(setPinStatus(true));
      setTimeout(() => {
        setSuccess(""); 
      }, 2500);
      setClose(true);
    }
  }

  const fetchData = async() => {
    const kycDetails: ResponseType = await getKYCList();

    if(kycDetails && kycDetails?.success && kycDetails.data?.length > 0) {
      setKycSubmitted(true);
      setKYCStatus(kycDetails.data?.[0]?.status || false);
    }
  }

  const handlePasswordChange = async() => {

    const data: ChangePasswordData = {
      password1: formData.password,
      password2: formData.confirm_password
    }
    const response: ResponseType = await forgotPassword(data);

    if(response && response?.success && response.data) {
      setClose(true);
      setSuccess(response?.message || '');
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadTitle>Settings</HeadTitle>
      </Grid>
      <Grid container item>
        <SettingsContainer>
          <Title>Account Information</Title>
          <AccountInfoContainer>
            <ImageContainer>
              <img src={userState.userData?.avatar || ""} alt="user" />
              {/* <button>Save</button> */}
            </ImageContainer>
            <UserDataContainer>
              <RowContainer>
                <InputField
                  type="text"
                  placeholder="First Name"
                  value={inputValues.first_name}
                  onChange={handleInputChange}
                  name="first_name"
                  disabled
                />
                <InputField
                  style={{ marginLeft: "20px" }}
                  type="text"
                  value={inputValues.last_name}
                  placeholder="Last Name"
                  onChange={handleInputChange}
                  name="last_name"
                  disabled
                />
              </RowContainer>
              <InputField
                style={{ width: "48%" }}
                type="text"
                placeholder="Email"
                value={inputValues.email}
                onChange={handleInputChange}
                name="email"
                disabled
              />
            </UserDataContainer>
          </AccountInfoContainer>
        </SettingsContainer>
      </Grid>
      <Grid item xs={12}>
        <KYC isKycSubmitted={isKycSubmitted} kycStatus={kycStatus} />
      </Grid>
      <Grid item xs={12}>
        <BankAccountCard />
      </Grid>
      {/* <Grid item xs={12}>
        <AccountCard
          title={"Two factor Verification"}
          description={
            "To enable app-based 2FA using an authentication app Google Authentication, enable and scan the qr code with the app on your phone."
          }
          buttonTitle={"Enabled 2FA"}
          component={
            <>
              <SubTitle>
                Google Authenicator : Open Google and scan your QR code Don't
                have Google Auth? Download
              </SubTitle>
              <div style={{ color: "#B11F72", marginTop: "5px" }}>
                Secret Key : 7HVTGWVVTIV5E5YM
              </div>
            </>
          }
        />
      </Grid> */}
      <Grid item xs={12}>
        <AccountCard
          close={close}
          title={"Change Password"}
          description={
            "Help keep your account safer by using a stronger password."
          }
          buttonTitle={"Change Password"}
          component={
            <>
              <TextField
                className="text-field"
                label="New password"
                variant="outlined"
                fullWidth
                margin="dense"
                name="password"
                type={showPassword ? "text" : "password"} 
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{ // Add InputProps to include the icon button
                    endAdornment: (
                        <IconButton onClick={togglePasswordVisibility} edge="end" tabIndex={-1}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    ),
                }}
            />
            <TextField
                className="text-field"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                margin="dense"
                type={showPassword ? "text" : "password"} 
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                error={!!errors.confirm_password}
                helperText={errors.confirm_password}
                InputProps={{ // Add InputProps to include the icon button
                    endAdornment: (
                        <IconButton onClick={togglePasswordVisibility} edge="end" tabIndex={-1}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    ),
                }}
            />
            </>
          }
          handleButton={handlePasswordChange}
        />
      </Grid>
      <Grid item xs={12}>
        <AccountCard
          close={close}
          title={"Set Transaction PIN"}
          description={"Will help you to make secure transaction."}
          buttonTitle={"Set Pin"}
          component={
            // <InputField
            //   type="number"
            //   style={{ width: "-webkit-fill-available" }}
            //   placeholder="Enter New Pin"
            //   onChange={hanldePinChange}
            // />
            <Grid container spacing={2} justifyContent="center" style={{marginTop: '10px'}}>
              {code.map((_, index) => (
                <Grid
                  item
                  key={index}
                  justifyContent={"center"}
                  style={{ marginBottom: "20px" }}
                >
                  <TextField
                    id={`code${index}`}
                    inputProps={{ maxLength: 1 }}
                    onChange={handlePinChange(index)}
                    value={code[index]}
                    variant="outlined"
                    fullWidth
                    sx={{ width: "6ch", input: { textAlign: "center" } }}
                  />
                </Grid>
              ))}
            </Grid>
          }
          handleButton={()=> handleResetPin()}
          success={success}
        />
      </Grid>
    </Grid>
  );
}

export default Settings;
