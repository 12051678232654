import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import CommonTable from "../../common/CommonTable/CommonTable";
import { YTPHistoryFields } from "../../../utils/utils";
import { FormattedYTPHistory } from "../../../uiapi/interface/uiModelTypes";
import { getYTPHistory } from "../../../uiapi/network/services/walletService";
import { TablePageRegex } from "../../common/Validation/validation";

export default function YTPHistory() {
  const [historyData, setHistoryData] = useState<FormattedYTPHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [nextpage, setNextPage] = useState<number | null>(null);
  const [prevpage, setPrevPage] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(0);

  const fetchData = async (page: number) => {
    setLoading(true);
    try {
      const history = await getYTPHistory('ytp',page);

      if (history && history?.data && history.data?.length > 0) {
        const formattedData: FormattedYTPHistory[] = [];

        history.data.map((data: any) => {
          formattedData.push({
              "Order ID": data?.order_number,
              Pair: data?.pair,
              Type: data?.type,
              Sell: data?.sell,
              Amount: data?.sell_amount,
              Buy: data?.buy,
              Price: data?.price,
              Fees: data?.fees,
              Status: data?.status || '',
              Txid: data?.txid,
              Screenshot: data?.screenshot
          });
        });
        setHistoryData(formattedData);
        setTotalCount(history?.count)
        setNextPage(
          history.next 
            ? parseInt(history.next.match(TablePageRegex)?.[1] || '', 10) 
            : null
        );
        setPrevPage(
          history.previous 
            ? (parseInt(history.previous.match(TablePageRegex)?.[1] || '', 10) || 1) 
            : null
        );
      }
    } catch (error) {
        setError("Error fetching data");
    } finally {
        setLoading(false);
    }
  };

  const handlePrevPage = () => {
    prevpage && fetchData(prevpage);
  };

  const handleNextPage = () => {
    nextpage && fetchData(nextpage);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadTitle>YTP History</HeadTitle>
      </Grid>
      <Grid item xs={12}>
        <CommonTable
          title="YTP History"
          fields={YTPHistoryFields}
          data={historyData}
          loading={loading}
          error={error}
          handleNext={() => handleNextPage()}
          handlePrev={() => handlePrevPage()}
          prevPage={prevpage}
          nextPage={nextpage}
          totalCount={totalCount}
        />
      </Grid>
    </Grid>
  );
}
