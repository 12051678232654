import React from "react";
import Travel from "../../../../assets/images/travel.svg";
import Hotel from "../../../../assets/images/hotel.svg";
import {
  FlightHotelContainer,
  ImageButton,
  StyledImage,
  StyledImageContainer,
} from "./FlightHotel.styles";
import { UserReducerType } from "../../../../uiapi/redux/reducers/userReducers";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

interface FlightHotelProps {}

const FlightHotel: React.FC<FlightHotelProps> = () => {
  const userState: UserReducerType = useSelector((state: any) => state.user);

  const handleBooking = (section: string) => {
    if (userState.userData.email && userState?.userData?.id) {
      let id = btoa(userState?.userData?.id.toString());
      let email = btoa(userState?.userData?.email);

      window.open(
        `https://travel.yatripay.com/?section=${section}&i=${id}&e=${email}`,
        "_blank"
      );
    }
  };

  return (
    <FlightHotelContainer>
        <StyledImageContainer style={{marginRight: '10px'}}>
          <ImageButton onClick={() => handleBooking("flight")}>Flight</ImageButton>
          <StyledImage
            src={Travel}
            alt="Flight"
            onClick={() => handleBooking("flight")}
          />
        </StyledImageContainer>
        <StyledImageContainer  style={{marginLeft: '10px'}}>
          <ImageButton onClick={() => handleBooking("hotel")}>Hotel</ImageButton>
          <StyledImage
            onClick={() => handleBooking("hotel")}
            src={Hotel}
            alt="Hotel"
          />
        </StyledImageContainer>
    </FlightHotelContainer>
  );
};

export default FlightHotel;
