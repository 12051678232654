import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonTab2 from "../../../common/CommonTabs/CommonTab2";
import { AllStalking, StalkingRewards, convertEpoch } from "../../../../utils/utils";
import { getAllStakingDetails, getStakingList } from "../../../../uiapi/network/services/utilService";
import { FormattedStakingRewards, FormattedStalking, Option, TableResponseType, StakingProgram } from "../../../../uiapi/interface/uiModelTypes";
import LockedStakingCard from "../../../common/LockedStakingCard/LockedStakingCard";
import { StakingCardContainer } from "../Stalking.styles";
import { getStakingRewardsList } from "../../../../uiapi/network/services/stakingService";
import CommonTable from "../../../common/CommonTable/CommonTable";
import { TablePageRegex } from "../../../common/Validation/validation";

const StalkPortCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TableTitle = styled.div`
  color: #B11F72 !important;
  text-align: left;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
`;

interface TabProps {
    active: boolean;
}

const TabTitle = styled.div<TabProps>`
  color: ${props => props.active ? "#B11F72" : "#595C5F"};
  text-align: center;
  border: ${props => props.active ? "1px solid #B11F72" : "1px solid #F2F2F2"};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  display: inline-block;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
`;

const StalkingPortfolio: React.FC = () => {

  const [activeTab, setActiveTab] = useState("All Staking");
  const [stalkingData, setStalkingData] = useState<FormattedStalking[]>([]);
  const [stakingRewardsData, setStakingRewardsData] = useState<any[]>([]);
  const [stakingCardData, setStakingCardData] = useState<StakingProgram[]>([]);
  const [availableSubTypes, setAvailableSubTypes] = useState<Option[]>([]);
  const [nextpage, setNextPage] = useState<number | null>(null);
  const [prevpage, setPrevPage] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(0);

  const handleTab = (value: string) => {
    setActiveTab(value)
  }

  async function getStakingData() {
    const stakingList: TableResponseType = await getStakingList();

    if (stakingList && stakingList.data && stakingList.data?.length > 0) {
      let stakingProgram:StakingProgram[] = [];
      let subscriptionType: Option[] = [];
      stakingList.data.forEach((element: StakingProgram) => {
        stakingProgram.push(element);
        subscriptionType.push({
          label: element?.name,
          value: `${element.validity}`
        }); 
      });
      setAvailableSubTypes(subscriptionType);
      setStakingCardData(stakingProgram);
    }
  }

  const fetchData = async () => {
    try {
      const stakingData:TableResponseType = await getAllStakingDetails();
  
      if (stakingData && stakingData?.success && stakingData.data?.length > 0) {
        const formattedData: FormattedStalking[] = []; 
        stakingData.data?.map((staking: any) => {
          formattedData.push({
            "Staking ID": staking?.staking_number,
            Coin: staking?.coin?.ticker,
            "Staking Type": staking?.staking_type,
            Amount: staking?.lock_amount,
            Duration: staking?.lock_period,
            "Est. Per Annum(%)": `${(staking?.per_annum)}%`,
            "Est. Maturity Amount": `${(staking?.maturity_amount).toFixed(2)}`,
            "Start Date": convertEpoch(staking?.start_at),
            "End Date": convertEpoch(staking?.expiring_at),
            "Reward Transfer Date": (staking?.yield_transfer_at),
            "Status": staking?.status || '',
            "Fees": staking?.price || '',    
          });
        });
        setStalkingData(formattedData);

      } else {
        console.log("No staking data available.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching staking data:", error);
    }
  };

  const getStakingRewards = async (page: number) => {
    try {
      const stakingData = await getStakingRewardsList();
  
      if (stakingData && stakingData?.success && stakingData.data?.length > 0) {
        const formattedData: FormattedStakingRewards[] = []; 
        stakingData.data?.map((staking: any) => {
          formattedData.push({
            "Staking ID": staking.staking_number,
            Coin: staking.coin,
            "Staking Type": staking.staking_type,
            Amount: staking.amount,
            "Last Update": staking.updated_at,  
          });
        });
        setStakingRewardsData(formattedData);
        // setTotalCount(stakingData?.count)
        // setNextPage(
        //   stakingData?.next 
        //     ? parseInt(stakingData?.next.match(TablePageRegex)?.[1] || '', 10) 
        //     : null
        // );
        // setPrevPage(
        //   stakingData?.previous 
        //     ? (parseInt(stakingData?.previous.match(TablePageRegex)?.[1] || '', 10) || 1) 
        //     : null
        // );
      } else {
        console.log("No staking data available.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching staking data:", error);
    }
  };

  // const handlePrevPage = () => {
  //   prevpage && getStakingRewards(prevpage);
  // };

  // const handleNextPage = () => {
  //   nextpage && getStakingRewards(nextpage);
  // };
  

  useEffect(()=>{
    getStakingData();
    fetchData();
    getStakingRewards(1);
  },[]);

  return (
    <StalkPortCard>
      <CommonTab2
        tabNames={["Staking", "My Portfolio"]}
        tabComponents={[
          <StakingCardContainer length={stakingCardData.length}>
            {stakingCardData?.map((data) => {
              return (
                <LockedStakingCard
                  data={data}
                  availableSubTypes={availableSubTypes}
                />
              );
            })}
          </StakingCardContainer>,
          <div style={{ marginTop: "20px" }}>
            <TableTitle>
              {activeTab}
              <span style={{ float: "right" }}>
                <TabTitle
                  active={activeTab === "All Staking"}
                  onClick={() => handleTab("All Staking")}
                >
                  All Staking
                </TabTitle>
                <TabTitle
                  active={activeTab === "Staking Rewards"}
                  onClick={() => handleTab("Staking Rewards")}
                  style={{ marginLeft: "10px" }}
                >
                  Staking Rewards
                </TabTitle>
              </span>
            </TableTitle>
            <CommonTable
              fields={activeTab === "All Staking" ? AllStalking : StalkingRewards}
              data={activeTab === "All Staking" ? stalkingData : stakingRewardsData}
              title={""}
              // handleNext={() => handleNextPage()}
              // handlePrev={() => handlePrevPage()}
              // prevPage={prevpage}
              // nextPage={nextpage}
              // totalCount={totalCount}
            />
          </div>,
        ]}
      />
    </StalkPortCard>
  );
};

export default StalkingPortfolio;
