import { ACTIVE_CURRENCY, FIAT_RATE, SET_PIN_STATUS, SET_TOKEN, SET_USER_DATA } from "../types";

export interface UserReducerType {
  token: string;
  userData: {
    id: number;
    first_name: string;
    last_name: string | null;
    email: string;
    email_verified_at: string;
    is_active: boolean;
    avatar: string;
    google2fa_qr_code: string;
    google2fa_enable: boolean;
    is_app_installed: boolean;
    phone_no: string | null;
    created_at: string;
    updated_at: string;
    referral_id: string | null;
    pin_status: boolean;
  };
  activeCurrency: string;
  fiatRate: any;
}

export const userInitialState: UserReducerType = {
  token: "",
  userData: {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    email_verified_at: "",
    is_active: false,
    avatar: "",
    google2fa_qr_code: "",
    google2fa_enable: false,
    is_app_installed: false,
    phone_no: "",
    created_at: "",
    updated_at: "",
    referral_id: "",
    pin_status: false
  },
  activeCurrency: "INR",
  fiatRate: {}
};

export default function userReducer(
  state = userInitialState,
  action: any
): UserReducerType {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case SET_PIN_STATUS:
      return {
        ...state, 
        userData: { 
          ...state.userData, // Keep other keys of 'userData' unchanged
          pin_status: action.payload, // Update specific key(s) in 'userData'
        },
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case ACTIVE_CURRENCY:
      return {
        ...state,
        activeCurrency: action.payload
      }
    case FIAT_RATE:
      return {
        ...state,
        fiatRate: action.payload
      }
    default:
      return state;
  }
}
