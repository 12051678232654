import styled from "styled-components";
import Banner from '../../../../assets/images/offer.svg';
import { Button } from "@mui/material";
import { getSubTaskColor } from "../../../../utils/domUtils";

export const TaskConatiner = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-bottom: 20px;
`;

export const TasksListContainer = styled.div`
  flex-direction: column;
  width: 50%;
  display: flex;
  padding: 20px;

  @media (max-width: 1200px) {
    width: -moz-available; /* For Firefox */
    width: -webkit-fill-available; /* For WebKit browsers like Safari */
    width: fill-available; /* For modern browsers */
  }
`;

interface Props {
  locked: boolean;
  completed: boolean;
}

export const DataRow = styled.div`
  display: flex !important;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 5px 0px;

  img {
  }
`;

export const TaskBox = styled.div<Props>`
  border-radius: 10px;
  padding: 15px 20px;
  color: ${({ completed }) => (completed ? "white" : "black")};
  background: ${({ locked, completed }) =>
    locked ? "#FEEFF7" : completed ? "#20BF55" : "#FFCCE9"};
  font-family: Poppins;
  font-size: 16px;
  width: 70%;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  cursor: ${({ locked }) => (locked ? "not-allowed" : "pointer")};
  pointer-events: ${({ locked }) => (locked ? "none" : "auto")};
  filter: ${({ locked }) => (locked ? "blur(3px)" : "none")};

  @media (max-width: 1300px) {
  }
`;

export const TaskHeplerText = styled.span`
  margin-right: 10px;
  display: flex;
  width: 60px;
  align-items: center;
  margin-left: 10px;
`;

export const TaskDetailsBox = styled.div`
  width: 50%;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: start;
  flex-direction: column;

  h3 {
    text-align: center;
    width: 100%;
  }

  ul {
    width: 90%;

  }
  button {
    float: right;
  }

  @media (max-width: 1200px) {
    width: -moz-available; /* For Firefox */
    width: -webkit-fill-available; /* For WebKit browsers like Safari */
    width: fill-available; /* For modern browsers */
    align-items: center;
  }
`;

export const TaskCompletionStatus = styled.div<Props>`
  margin-top: 15px;
  font-weight: bold;
  color: ${({ locked }) =>
    locked ? "green" : "red"}; // Green color for completed, red for incomplete
`;

export const OffersContainer = styled.div`
  width: 100%;
  position: relative;
  img {
    border-radius: 10px;
    width: -moz-available; /* For Firefox */
    width: -webkit-fill-available; /* For WebKit browsers like Safari */
    width: fill-available; /* For modern browsers */
    object-fit: contain;
  }
`;

export const BannerContainer = styled.div`
  background: url(${Banner});
  // background-position: center; 
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  justify-content: center;

  div {
    text-align: center;
    display: flex;
    padding: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    font-family: Poppins;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
  }
  h3 {
    font-size: 30px;
  }

  li {
    text-align: start;
    padding: 10px 20px;
    font-size: 20px !important;
  }
`;

export const StepsAway = styled.div<{isTaskDone: Boolean}>`
  border-radius: 10px;
  color: ${({ isTaskDone }) => (isTaskDone ? "white" : "black")};
  padding: 25px 90px;
  margin-top: 20px;
  text-align: center;
  background: ${({ isTaskDone }) => (isTaskDone ? "#20BF55" : "#FFCCE9")};
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  letter-spacing: 0em;
  margin: 20px auto 60px auto;

  @media (max-width: 1300px) {
  }
`;

export const TaskButton = styled(Button)<{ status: string }>`
  background-color: ${({ status }) => getSubTaskColor(status)} !important;
  color: #fff !important;
  display: block;
  border: none;
  height: 52px;
  padding: 10px 5% !important;
  border-radius: 10px !important;
  cursor: pointer;
  text-transform: Capitalize !important;
  font-weight: bold !important;
  font-family: poppins !important;
  font-size: 16px !important;
  margin-top: 10px !important;

  @media (max-width: 650px) {
    padding: 10px 5%;
  }

  &:hover {
    background-color: ${({ status }) => getSubTaskColor(status)} !important;
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.8;
  }
`;

