import { Grid, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeadTitle, MuiButton } from "../../common/Styles/CommonStyles";
import OfferCard from "./Cards/OfferCard";
import OffersData from "./Cards/OffersData";
import { getOffersList } from "../../../uiapi/network/services/utilService";
import { OfferList, ResponseType } from "../../../uiapi/interface/uiModelTypes";
import { parseDesc } from "../../../utils/utils";
import { BannerContainer } from "../OffersTask/Tasks/TaskCard.styles";
import { useNavigate } from "react-router-dom";
import { getOffersDom } from "../../../utils/commonDom";

export default function Offers() {

  const navigate = useNavigate();
  const [offers, setOffers] = useState<OfferList[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fetchData = async() => {
    const offersData:ResponseType = await getOffersList(true);
    let offer: OfferList[] = [];
    if(offersData && offersData?.success && offersData?.data?.length > 0) {
      // Push the offers data in array
      offersData.data.forEach((element: any) => {
        const { title, subTitle, list } = parseDesc(element.desc);     
        offer.push({
          title,
          subTitle,
          list,
          imgUrl: element.img,
          btnText: '',
          offerId: element?.id
        });
      });
      setOffers(offer);
    } else {
      setErrorMessage(offersData?.message || 'Something went wrong!')
    }
  }

  useEffect(()=>{
    fetchData();
  },[]);

  const handleStartedClick = () => {
     navigate('/offers-task');
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadTitle>Offers</HeadTitle>
      </Grid>
      <Grid item xs={12}>
        <OfferCard />
      </Grid>
      <Grid item xs={12} style={{paddingBottom: '20px'}}>
       <BannerContainer>
          <div>
              {getOffersDom()}
            <MuiButton onClick={handleStartedClick}>
              {" "}
              Let’s get started
            </MuiButton>
          </div>
        </BannerContainer>  
      </Grid>
      <Grid container spacing={2}>
        {offers && offers?.map((offer, index)=> {
            return(
              <Grid key={index} item sm={6} xs={12}>
                <OffersData
                  title={offer.title}
                  subtitle={offer.subTitle}
                  imageUrl={offer.imgUrl}
                  buttonText={"Claim Now"}
                  offerId={offer.offerId}
                />
              </Grid>
            )
        })}
      </Grid>
      {errorMessage && 
        <Snackbar
          open={true}
          autoHideDuration={6000}
          message={errorMessage}
          color="red"
        />
      }
    </Grid>
  );
}
