import { Grid } from "@mui/material";
import React, { ReactNode } from "react";
import Header from "../Header/Header";

export default function Layout({children}: {children: ReactNode}) {
    return(
        <Grid container>
            <Grid item xs={12}>
                <Header />
            </Grid>
            <Grid item xs={12} style={{padding: '3%'}}>
                {children}
            </Grid>
        </Grid>
    )
}