import styled from "styled-components";
import { MuiButton } from "../Styles/CommonStyles";

export const FAQContainer = styled.div`
  border-radius: 8px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  font-family: Poppins;
`;

export const DisclaimerContent = styled.ul`
  padding-top: 20px;
  li {
    font-family: Poppins;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #878787;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 2.39px;
  background: #f2f2f2;
`;

export const FAQItemWrapper = styled.div`
  //   border-bottom: 1px solid #ccc;
`;

export const Question = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  &:hover {
  }
`;

export const PlusMinusIcon = styled.div`
  margin-left: auto;
  color: #b11f72 !important;
`;

export const Answer = styled.div`
  padding: 10px 0px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #878787;
`;

export const ConnectContainer = styled.div`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px;
    flex-direction: column;
    background: #FBFAFA;
`;

export const ChatButton = styled(MuiButton)`
  margin-top: 30px !important;
`;

export const Title = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 10px;
  letter-spacing: 0em;

`;

export const SubTitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: #878787;
  margin-top: 5px;
`;
