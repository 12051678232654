import React from "react";
import { PageText, PaginationButton } from "../Styles/CommonStyles";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface PaginationProps {
  handleNextPage: () => void;
  handlePrevPage: () => void;
  prevPage: boolean;
  nextPage: boolean;
  pageNumber: number | null;
  totalCount: number | null;
}

export default function Pagination(props: PaginationProps) {

  return (
    <>
    {props.pageNumber && 
      <div style={{float: 'right'}}>
        <PageText> {props.totalCount && props.totalCount > 0 ? `Page ${props.pageNumber} of ${Math.floor(props.totalCount/10)}`: ''} </PageText> 
        <PaginationButton onClick={props.handlePrevPage} disabled={!props.prevPage}>
            <KeyboardArrowLeftIcon style={{ color: "white" }} />
        </PaginationButton>
        &nbsp;
        <PaginationButton onClick={props.handleNextPage} disabled={!props.nextPage}>
            <KeyboardArrowRightIcon  style={{ color: "white" }}  />
        </PaginationButton>
      </div>
    }
    </>
  );
}
