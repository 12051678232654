import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CopyIcon from "../../../../assets/images/Copy.svg";
import { getReferralCode } from "../../../../uiapi/network/services/walletService";
import "./ReferralCard.css";

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinIcon,
  WhatsappIcon,
  FacebookShareButton,
} from "react-share";
import CopyTooltip from "../../../common/CommonTable/CopyTooltip";
import { ResponseType } from "../../../../uiapi/interface/uiModelTypes";

const ReferralContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: #212529 !important;
  text-align: left;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

const CopyCode = styled.div`
  color: #878787 !important;
  padding-bottom: 5px;
  display: flex;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;

  @media(max-width: 400px) {
     display: unset;
  }

  img {
    margin-left: 10px;
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media(max-width: 732px) {
    flex-direction: column;
    margin-top: 10px;
    align-items: start;

    button {
      margin-top: 10px;

    }
  }
`;

const ReferralCard: React.FC = () => {
  const [code, setCode] = useState<string>("");

  const fetchdata = async () => {
    const referred:ResponseType = await getReferralCode();

    if (referred && referred?.success && referred?.data) {
      setCode(referred.data?.[0]?.code);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const handleCopyCode = (value: string) => {
    // This will copy the given code
    navigator &&
      navigator.clipboard
        .writeText(value)
        .then(() => {
          console.log("Referral link copied to clipboard:", value);
        })
        .catch((error) => {
          console.error("Unable to copy referral link:", error);
        });
  };

  return (
    <ReferralContainer>
      <TitleRow>
        <Title>Referral</Title>
        <CopyCode>
          {code}{" "}
          <CopyTooltip context={code} />
        </CopyCode>
      </TitleRow>
      <TitleRow>
        <Title>Referral Link</Title>
        <CopyCode>
          {`${process.env.REACT_APP_RegisterUrl}?ref=${code}`}{" "}
          <CopyTooltip context={`${process.env.REACT_APP_RegisterUrl}?ref=${code}`} />
        </CopyCode>
        <IconContainer>
          <LinkedinShareButton
            style={{ background: "#accfec" }}
            children={
              <>
                <LinkedinIcon size={32} round={true} />
                <span style={{ color: "#2B78B5" }}>LinkedIn</span>
              </>
            }
            url={`${process.env.REACT_APP_RegisterUrl}?ref=${code}`}
          />
          <WhatsappShareButton
            style={{ background: "#E8FFF2" }}
            children={
              <>
                <WhatsappIcon size={32} round={true} />
                <span style={{ color: "#7CD566" }}>WhatsApp</span>
              </>
            }
            url={`${process.env.REACT_APP_RegisterUrl}?ref=${code}`}
          />
          <FacebookShareButton
            style={{ background: "#b3d0ff" }}
            children={
              <>
                <FacebookIcon size={32} round={true} />
                <span style={{ color: "#0965FE" }}>Facebook</span>
              </>
            }
            url={`${process.env.REACT_APP_RegisterUrl}?ref=${code}`}
          />
          <TwitterShareButton
            style={{ background: "#b9dff8" }}
            children={
              <>
                <TwitterIcon size={32} round={true} />
                <span style={{ color: "#4AADED" }}>Twitter</span>
              </>
            }
            url={`${process.env.REACT_APP_RegisterUrl}?ref=${code}`}
          />
        </IconContainer>
      </TitleRow>
    </ReferralContainer>
  );
};

export default ReferralCard;
