import React, { useState } from "react";
import './form.css';
import { Alert, Button, CircularProgress, TextField } from "@mui/material";
import { isValidEmail } from "../Validation/validation";
import { OtpData, ResponseType } from "../../../uiapi/interface/uiModelTypes";
import { getOtp } from "../../../uiapi/network/services/userService";
import { useNavigate } from "react-router-dom";

interface FormData {
    name: string;
    email: string;
    referral: string;
}

interface Errors {
    name: string;
    email: string;
}

export default function RegisterForm() {

    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get('ref') || '';
    const [backendError, setBackendError] = useState("");
    const [formData, setFormData] = useState<FormData>({
        name: "",
        email: "",
        referral: paramValue
    });

    const [errors, setErrors] = useState<Errors>({
        name: "",
        email: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Clear validation error when user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const validateForm = (): boolean => {
        let isValid = true;
        const newErrors: Errors = {
            name: "",
            email: ""
        };

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }

        if (!formData.email.trim()) {
            newErrors.email = "Email is required";
            isValid = false;
        } 
        if (!isValidEmail(formData.email)) {
            newErrors.email = "Invalid email format";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); // Set loading to true when submitting

            const otpData: OtpData = {
                email: formData.email,
                first_name: formData.name,
                referral_id: formData.referral
            }

            try {
                const otp: ResponseType = await getOtp(otpData);

                if ((otp && otp?.success && otp?.data) || otp?.message === "Reset Email") {
                    navigate('/verify-email', { state: { email: formData.email } });
                } else {
                    setBackendError(otp?.message || '');
                }
            } catch (error) {
                console.error("An error occurred:", error);
            } finally {
                setLoading(false); // Set loading to false after the request completes
            }            
        }
    };

    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <TextField
                className="text-field"
                label="Your Name"
                variant="outlined"
                fullWidth
                margin="dense"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
            />
            <TextField
                className="text-field"
                label="Email Address"
                variant="outlined"
                fullWidth
                margin="dense"
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
            />
            <TextField
                className="text-field"
                label="Referral Code (optional)"
                variant="outlined"
                fullWidth
                margin="dense"
                type="text"
                name="referral"
                value={formData.referral}
                onChange={handleChange}
            />
            <Button type="submit" variant="contained" fullWidth className="login-button" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign Up'}
            </Button>

            {backendError && <Alert className="backend-error" color="error">{backendError || 'Something went wrong!'}</Alert>}
        </form>
    );
}
