import { PageType } from "./enums/enums"

export const getFormHeader = (page: PageType) => {
    switch (page) {
        case PageType.Login:
            return "Log in"
        case PageType.Register:
            return "Sign Up"
        case PageType.Verify:
            return "Enter Code"
        case PageType.CreatePassword:
            return "Set Password"
        case PageType.ResetPassword:
            return "Reset Password"
        default:
            return "Log in"
    }
}

export const getFormLinks = (page: PageType) => {
    switch (page) {
        case PageType.Login:
            return "No Account? Sign Up"
        case PageType.Register:
            return 'Have an Account ? Log In'
        case PageType.Verify:
            return "Have an Account ? Log In"
        case PageType.CreatePassword:
            return "Have an Account ? Log In"
        case PageType.CreatePassword:
            return "Have an Account ? Log In"
        default:
            return "Have an Account ? Log In"
    }
}


export const TransactionFields = [
    { label: 'Coin', sorting: true },
    { label: 'Category', sorting: true },
    { label: 'Total Amount', sorting: true },
    { label: 'Status', sorting: true },
    { label: 'Explorer', sorting: true },
    { label: 'TxType', sorting: true },
    { label: 'Txid', sorting: true },
    { label: 'Address', sorting: true },
    { label: 'Confirmations', sorting: true },
    { label: 'Date', sorting: true }
];

export const CoinFields = [
    { label: 'Coin', sorting: true },
    { label: 'Available Balance', sorting: true },
    { label: 'Welcome Bonus', sorting: true },
    { label: 'Staking Reward', sorting: true },
    { label: 'Total Balance', sorting: true }
];


export const RewardFields = [
    { label: 'Coin', sorting: true },
    { label: 'Reward Type', sorting: true },
    { label: 'Amount', sorting: true },
    { label: 'Status', sorting: true },
    { label: 'Staking ID', sorting: true },
    { label: 'Username', sorting: true },
    { label: 'Staking Type', sorting: true },
    { label: 'Date/Time', sorting: true }
];

export const AllStalking = [
    { label: 'Staking ID', sorting: true },
    { label: 'Coin', sorting: true },
    { label: 'Staking Type', sorting: true },
    { label: 'Amount', sorting: true },
    { label: 'Duration', sorting: true },
    { label: 'Est. Per Annum(%)', sorting: true },
    { label: 'Est. Maturity Amount', sorting: true },
    { label: 'Start Date', sorting: true },
    { label: 'End Date', sorting: true },
    { label: 'Reward Transfer Date', sorting: true },
    { label: 'Status', sorting: true },
    { label: 'Fees', sorting: true }
];

export const StalkingRewards = [
    { label: 'Staking ID', sorting: true },
    { label: 'Staking Type', sorting: true },
    { label: 'Coin', sorting: true },
    { label: 'Amount', sorting: true },
    { label: 'Last Update', sorting: true }
];


export const ReferredUsers = [
    { label: 'User', sorting: true },
    { label: 'Email Id', sorting: true },
    { label: 'Joining Date', sorting: true },
    { label: 'Staking Hike Till', sorting: true },
    { label: 'Referral Reward Till', sorting: true },
    { label: 'Action', sorting: true }
]

export const SocialHistory = [
    { label: 'Activity Number', sorting: true },
    { label: 'Coin', sorting: true },
    { label: 'Staking Hike ', sorting: true },
    { label: 'Min. Amount', sorting: true },
    { label: 'Social Handle', sorting: true },
    { label: 'Activity', sorting: true },
    { label: 'Status', sorting: true },
    { label: 'Reason', sorting: true },
    { label: 'Submit Date', sorting: true }
]

export const TicketHistory = [
    { label: 'Create Date', sorting: true },
    { label: 'Ticket No ', sorting: true },
    { label: 'Ticket Type ', sorting: true },
    { label: 'Status', sorting: true },
    { label: 'Last Update', sorting: true }
]

export const YTPHistoryFields = [
    { label: 'Order ID', sorting: true },
    { label: 'Pair', sorting: true },
    { label: 'Type', sorting: true },
    { label: 'Sell', sorting: true },
    { label: 'Amount', sorting: true },
    { label: 'Buy', sorting: true },
    { label: 'Price', sorting: true },
    { label: 'Fees', sorting: true },
    { label: 'Txid', sorting: true },
    { label: 'Screenshot', sorting: true },
    { label: 'Status', sorting: true },

];

export const SwapHistoryFields = [
    { label: 'Date/Time', sorting: true },
    { label: 'Order ID', sorting: true },
    { label: 'Pair', sorting: true },
    { label: 'Type', sorting: true },
    { label: 'Sell', sorting: true },
    { label: 'Amount', sorting: true },
    { label: 'Buy', sorting: true },
    { label: 'Price', sorting: true },
    { label: 'Fees', sorting: true },
    { label: 'Status', sorting: true }
];

export const convertEpoch = (epochTimestamp: number | any) => {
    // Create a new Date object with the epoch timestamp (in seconds)
    const date = new Date(epochTimestamp * 1000);

    // Extract the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Return the formatted date string
    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

enum ReturnPeriod {
    Daily,
}

export function isStringOrNumber(value: number | string) {
    return typeof value === 'string' || typeof value === 'number';
}

export const parseDesc = (desc: any) => {
    // Create a dummy div to parse HTML content
    const tempDiv:any = document.createElement('div');
    tempDiv.innerHTML = desc;

    // Extract content from HTML elements
    const title = tempDiv.querySelector('h4').textContent;
    const subTitle = tempDiv.querySelector('p').textContent;
    const listItems = tempDiv.querySelectorAll('ul li');
    const list = Array.from(listItems).map((li:any) => li.textContent);

    return {
      title,
      subTitle,
      list,
    };
  }

export function containsPhoneNumber(variable: any) {
    
    const phoneRegex = /^\d{10}$/;

    return phoneRegex.test(variable);
}



  