export const Hero_Sub_Heading = "Experience lightning-fast transactions with YatriPay, your gateway to decentralised global payment solutions.";

export const About_Yatri = "YatriPay empowers you with the ultimate travel wallet. Imagine a world without currency conversion hassles and exorbitant fees, where booking your dream adventure is as simple as tapping a button. That's the promise of YatriPay: a single, decentralized wallet designed to streamline your travels. Experience the exhilaration of super-fast transactions, sending and receiving funds globally without the usual delays. YatriPay's focus on peer-to-peer transfers means you can split dinner costs in Dubai or send money to loved ones back home instantly, all with zero fees. Ditch the complicated converters and relish the freedom of a wallet that understands the needs of modern explorers. Book flights, hotels, and unforgettable experiences directly with YatriPay and discover the true essence of seamless travel.";

export const Disclaimer1 = "The information on this site is provided for general purposes and is not an offer to sell, or a solicitation of any offer to buy, any interest or subscription in the YatriPay Invest Strategies listed on this website, regardless of whether these aforementioned strategies are provided and managed by YatriPay or a third party provider.";

export const Disclaimer2 = "The Information on this page and the YatriPay Stake (‘Subscription’) offered wherein is not directed at any person in any jurisdiction where it is illegal or unlawful to access and use such information or service. YAtriPay disclaims all responsibility if you access or download any Information in breach of any law or regulation of the country in which you reside.";

export const Disclaimer3 = "YTP stake Service is open only to registered users of YatriPay. The terms of service of YatriPay are applicable to the users of the YatriPay coin stake holders. Additionally, you will need to conclude a separate ‘YatriPay subcription Agreement’ before you could use this Service.";

export const Disclaimer4 = "All users of the YatriPay are required to inform themselves of and to comply with all applicable restrictions in their home country.";


export const BuyYTPHelper = "Please make payment to this QR code via any Express Application. Also submit your Transaction ID & its Screenshot.";
export const SellYTPHelper = "Sell Request will be process between 8:00 AM to 8:00 PM on working days.";

//KYC Disclaimer 

export const KYCDisclaimerTitle = "To avoid delays while getting your account verified, Please make sure of following things:";
export const KYCDisclaimerList = ['Accepted Documents: National ID, Driving License, Passport.', 'Chosen credential must not be expired.', 'Document should be in good condition and clearly visible.', 'Make sure that there is no light glare on the card.', 'Maximum file size of 2MB is allowed with these supported formats: JPEG, JPG, PNG.'];

export const PinErrorMessage = "Before making any transaction, Please set your Transaction PIN";