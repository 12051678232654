import styled from "styled-components";

interface StakingProps {
  length: number
}

export const StakingCardContainer = styled.div<StakingProps>`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 1600px) {
    flex-direction: column;
  }

`;
