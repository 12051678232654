import * as types from '../../interface/uiModelTypes';
import { store } from '../../redux/store';
import { axiosWrapper } from './axiosWrapper';


export async function buyCoinRequest(amount: number, fiat: string) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/buy/coin/request/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        credentials: false,
        body: {
            buy_amount: amount,
            fiat: fiat
        },
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export interface BuyCoinCreateData {
    buy_amount: string,
    txid: string,
    upi_id?: string,
    screenshot: any,
    fiat?: string,
    account_number?: string
}

export async function buyCoinCreate(data: BuyCoinCreateData) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/buy/coin/create/`;

    const formData = new FormData();
    formData.append('buy_amount', data.buy_amount);
    formData.append('txid', data.txid);
    data?.upi_id && formData.append('upi_id', data.upi_id);
    data?.fiat && formData.append('fiat', data.fiat);
    data?.account_number && formData.append('account_number', data.account_number);
    data.screenshot && formData.append('screenshot', data.screenshot) ;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        credentials: false,
        body: formData,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}


export async function sellCoinRequest(amount: number, fiat: string) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/sell/coin/request/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        credentials: false,
        body: {
            sell_amount: amount,
            fiat: fiat
        },
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}


export interface SellCoinCreateData {
    sell_amount: number,
}

export async function sellCoinCreate(amount: number, fiat: string) {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/finance/sell/coin/create/`;

    const request: types.axiosRequest = {
        method: 'post',
        url: apiEndpoint,
        credentials: false,
        body: {
            sell_amount: amount,
            fiat: fiat
        },
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export const getQRCode = (name:string, vpa:string) => {

    const apiEndpoint: string = `https://upiqr.in/api/qr?name=${name}&vpa=${vpa}`;

    const request: types.axiosRequest = {
        method: 'get',
        fullUrl: apiEndpoint,
        credentials: false
    };

    return axiosWrapper(request);

}