export enum PageType {
    Login,
    Register,
    ResetPassword,
    Verify,
    CreatePassword,
}

export enum StatusName {
    Completed,
    Pending,
    Failed,
    RUNNING,
    UNLOCKED
}

export enum TransactionStatus {
    Completed = 0,
    Pending = 1,
    Failed = 2
}

export enum TransactionCategory {
    Send = 0,
    Receive = 1,
    Pending = 2
}

export enum StakingStatus {
    COMPLETED,
    RUNNING,
    UNLOCKED
}

export enum SwapHistoryStatus {
    COMPLETED,
    Failed
}