import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Title } from './Card';
import { createBankDetails, getBankDetailsList } from '../../../../uiapi/network/services/userService';
import { BankAccountData } from '../../../../uiapi/interface/requestBodyTypes';
import Success from "../../../../assets/images/success.svg";
import { MuiButton } from '../../../common/Styles/CommonStyles';

interface FormData {
    bank_name: string;
    account_holder_name: string;
    account_number: string;
    ifsc_code: string,
    upi_id: string,
}

export const CardContainer = styled.div`
  padding: 20px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-top: 20px;
`;

export const Button = styled(MuiButton)`
  && {
    padding: 10px 20px !important;
    margin-top: 20px !important;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;


export default function BankAccountCard() {
    
    const [formData, setFormData] = useState<FormData>({
        'account_holder_name': '',
        'bank_name' : '',
        'account_number': '',
        "ifsc_code": '',
        'upi_id': ''
    });

    const [isBankDetailsAdded, setBankDetailsAdded] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleBankDetails = async () => {

        const data:BankAccountData = {
            account_holder_name: formData.account_holder_name,
            bank_name: formData.bank_name,
            account_number: formData.account_number,
            ifsc_code: formData.ifsc_code,
            upi_id: formData.upi_id
        }

        if(formData.account_holder_name && formData.account_number && formData.bank_name && formData.ifsc_code) {
            const sendBankDetails = await createBankDetails(data);

            if(sendBankDetails && sendBankDetails.success) {
                setBankDetailsAdded(true);
            }
        }

    }

    const fetchData = async() => {
        const bankDetails = await getBankDetailsList();
    
        if(bankDetails && bankDetails?.success && bankDetails?.data?.length > 0) {
          setBankDetailsAdded(true);
        }
    }

    useEffect(()=> {
        fetchData();
    },[]);  
    
    return(
        <CardContainer>
           <TitleRow>
           <Title>Bank Details</Title>
           {isBankDetailsAdded && 
            <Container>
              <img src={Success} /> Your Bank Details are already added.
            </Container>}
           </TitleRow>
           {!isBankDetailsAdded && 
           <>
           <TextField
                className="text-field"
                style={{marginTop: '30px'}}
                label="Account Holder Name"
                variant="outlined"
                fullWidth
                margin="dense"
                name="account_holder_name"
                type="text"
                // value={formData.account_holder_name || ''}
                onChange={handleChange}
            />
            <TextField
                className="text-field"
                label="Bank Name"
                variant="outlined"
                fullWidth
                margin="dense"
                name="bank_name"
                type="text"
                // value={formData.bank_name}
                onChange={handleChange}
            />
            <TextField
                className="text-field"
                label="Account Number"
                variant="outlined"
                fullWidth
                margin="dense"
                name="account_number"
                type="text"
                // value={formData.account_number}
                onChange={handleChange}
            />
            <TextField
                className="text-field"
                label="IFSC/IBAN/BSB Code"
                variant="outlined"
                fullWidth
                margin="dense"
                name="ifsc_code"
                type="text"
                // value={formData.ifsc_code}
                onChange={handleChange}
            />
            <TextField
                className="text-field"
                label="UPI Id"
                variant="outlined"
                fullWidth
                margin="dense"
                name="upi_id"
                type="text"
                // value={formData.upi_id}
                onChange={handleChange}
            />
            <Button onClick={handleBankDetails} >
                Save Account Details
            </Button>
            </>}
        </CardContainer>
    )
}