import React, { useEffect, useRef, useState } from 'react';
import Chart, { ChartOptions } from 'chart.js/auto';

interface LineChartProps {
  data: { x: string | number; y: number }[];
  title: string;
}

const LineChart: React.FC<LineChartProps> = ({ data, title }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);

  const colors = {
    purple: {
      default: "rgb(178, 31, 114)",
      half: "rgb(210, 116, 155)",
      quarter: "rgb(236, 186, 202)",
      zero: "rgb(255, 255, 255)",

    },
    indigo: {
      default: "rgba(80, 102, 120, 1)",
      quarter: "rgba(80, 102, 120, 0.25)"
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        let gradient = ctx.createLinearGradient(0, 25, 0, 300);
        gradient.addColorStop(0, colors.purple.half);
        gradient.addColorStop(0.35, colors.purple.quarter);
        gradient.addColorStop(1, colors.purple.zero);

        chartInstance.current = new Chart(ctx, {
          type: 'line',
          data: {
            xLabels: data.map(item => item.x),
            datasets: [{
              label: title,
              data: data.map(item => item.y),
              borderColor: '#b11f72',
              backgroundColor: gradient,
              tension: 0.4,
              borderWidth: 2,
              fill: true
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'category',
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 80,
                  minRotation: 15,
                  maxRotation: 15
                },
                grid: {
                  display: false // Remove horizontal grid lines
                }
              },
              y: {
                beginAtZero: true,
                grid: {
                  display: false // Remove horizontal grid lines
                }
              },
            },
            layout: {
              padding: {
                // top: 20,
                right: 20
              }
            },
            plugins: {
              legend: {
                display: false // Hide the legend
              }
            }
          }
        });
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, title]);    

  return (
    <div style={{ height: '100%' }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default LineChart;