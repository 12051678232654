import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import FAQ from "../../common/FAQ/FAQ";
import CommonTable from "../../common/CommonTable/CommonTable";
import { SocialHistory } from "../../../utils/utils";
import SocialActivityCard from "./Card/SocialActivityCard";
import { FormattedSocialActivity } from "../../../uiapi/interface/uiModelTypes";
import { getSocialMediaHistory } from "../../../uiapi/network/services/stakingService";
import { getSocialList } from "../../../uiapi/network/services/utilService";

export default function SocialActivity() {

    const [socialActivityData, setSocialActivityData] = useState<FormattedSocialActivity[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [data, setData] = useState<any>();
    const [icons, setIconsList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            setError("");
            const response = await getSocialMediaHistory();
            if (response && response?.data?.length > 0) {
              const formattedData: FormattedSocialActivity[] = response.data.map(
                (activity: any) => ({
                    "Activity Number": activity?.social_activity?.activity_number || '',
                    Coin: activity?.social_activity?.coin || 0,
                    "Total Amount": 0,
                    "Staking Hike": activity?.social_activity?.staking_hike || '',
                    "Min. Amount":  activity?.social_activity?.min_amount || '',
                    "Social Handle":  activity?.social_activity?.social_handle?.name || '',
                    "Activity": activity?.social_activity?.activity_name || '',
                    "Status": activity?.status || '',
                    "Reason": activity?.reason || '-',
                    "Submit Date": activity?.created_at || '',
                })
              );
              setSocialActivityData(formattedData);
            } else {
              setSocialActivityData([]);
            }
          } catch (error) {
            setError("Failed to fetch data. Please try again later.");
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
        getSocialIconList();
    }, []);

    const getSocialIconList = async () => {
        try {
          const icons = await getSocialList();
          if (icons && icons.data && icons.data.length > 0) {
            setIconsList(icons.data);
  
          }
        } catch (error) {
        }
    };

    return(
        <Grid container>
            <Grid item xs={12}>
                <HeadTitle>Social Activity</HeadTitle>
            </Grid>
            <Grid item xs={12}>
                <SocialActivityCard icons={icons} />
            </Grid>
            <Grid item xs={12}>
                <CommonTable fields={SocialHistory} data={socialActivityData} title={"Social Activity History"} />
            </Grid>
            <Grid item xs={12}>
                <FAQ code="social"/>
            </Grid>
        </Grid>
    )
}