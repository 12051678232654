import React, { useState } from "react";
import './form.css';
import { Alert, Button, CircularProgress, TextField } from "@mui/material";
import { getResetLink } from "../../../uiapi/network/services/userService";
import { ResponseType } from "../../../uiapi/interface/uiModelTypes";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../Validation/validation";

export default function ResetPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [backendError, setBackendError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEmail(value)
        setError('');
        setBackendError("");
    };

    const handleSubmit = async () => {
        setBackendError("");
        if (isValidEmail(email)) {
            setLoading(true);
            try {
                const response: ResponseType = await getResetLink(email);

                if (response && response?.success) {
                    navigate('/verify-email', { state: { email: email } });
                } else {
                    setBackendError(response?.message || 'Something went wrong!')
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
            finally {
                setLoading(false);
            }
        } else {
            setError("Please enter the valid email id.");        
        }
    };

    return (
        <div>
            <div className="login-form">
                <TextField
                    className="text-field"
                    label="Your Email"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    name="email"
                    type="text"
                    value={email}
                    onChange={handleChange}
                    error={!!error}
                    helperText={error}
                />
            </div>
            <Button onClick={handleSubmit} variant="contained" fullWidth className="login-button" disabled={loading}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Get Reset Link'}
            </Button>

            {backendError && <Alert className="backend-error" color="error">Something went wrong</Alert>}
        </div>
    );
}
