import React, { useState } from 'react';
import {
  StyledTableContainer,
  StyledTableHeadCell,
  StyledTableRow,
  NoDataContainer,
  Title,
  StyledTableBodyCell,
  StyledButton
} from './CommonTable.styles';
import { Table, TableHead, TableBody, TableRow } from '@mui/material';
import { Loader } from '../Styles/CommonStyles';
import { getBackgroundBasedOnCategory, getColorBasedOnCategory, getColorBasedOnName } from '../../../utils/domUtils';
import { TransactionCategory } from '../../../utils/enums/enums';
import CopyTooltip from './CopyTooltip';
import Pagination from '../Pagination/Pagination';

interface TableField {
  label: string;
  sorting?: boolean;
}

interface CommonTableProps {
  fields: TableField[];
  data: any[];
  title: string;
  noDataText?: string;
  loading?: boolean;
  error?: string;
  handlePrev?: () => void;
  handleNext?: () => void;
  prevPage?: number | null;
  nextPage?: number | null;
  totalCount?: number | null;
}

const CommonTable: React.FC<CommonTableProps> = ({ fields, data, title, noDataText = 'No data found', loading = false, error, handlePrev, handleNext, prevPage, nextPage, totalCount }) => {

  const getStyledValue = (field: string, value: any, category: string) => {
    const commonStyles = { maxWidth: '200px', textOverflow: 'ellipsis', overflow: 'hidden' };

    switch (field) {
      case 'Category':
        return (
          <StyledButton
            background={getBackgroundBasedOnCategory(
              value === 'send' ? TransactionCategory.Send : TransactionCategory.Receive
            )}
            color={getColorBasedOnCategory(value === 'send' ? TransactionCategory.Send : TransactionCategory.Receive)}
          >
            {value}
          </StyledButton>
        );
      case 'Status':
        return (
          <span style={{ color: getColorBasedOnName(value), fontWeight: 600 }}>{[value]}</span>
        );
      case 'Address':
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ color: category === 'send' ? '#EB5757' : '#219653' }}>
              {category === 'send' ? 'Sent to:' : 'Received from:'} &nbsp;
            </div>
            <abbr style={commonStyles} title={value}>
              {value}
            </abbr>
            <div>
              <CopyTooltip context={value} />
            </div>
          </div>
        );
      case 'Explorer':
        return (
          <div style={commonStyles}>
            <a href={value} target="_blank" style={{ cursor: 'pointer', color: 'inherit' }}>
              <abbr title={value}>{value}</abbr>
            </a>
          </div>
        );
      default:
        return (
          <div style={commonStyles}>
            <abbr title={value}>{value}</abbr>
          </div>
        );
    }
  };

  return (
    <>
    <StyledTableContainer>
      {title && <Title>{title}</Title>}
      <Table>
        <TableHead>
          <TableRow>
            {fields.map((field, index) => (
              <StyledTableHeadCell key={index}>
                {field.label}
              </StyledTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ position: "relative" }}>
          {!loading &&
            !error &&
            data.length > 0 &&
            data.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {fields.map((field, fieldIndex) => (
                  <StyledTableBodyCell key={fieldIndex}>
                    {getStyledValue(
                      field.label,
                      row[field.label],
                      row["Category"]
                    )}
                  </StyledTableBodyCell>
                ))}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      {!loading && !error && data.length === 0 && (
        <NoDataContainer>{noDataText}</NoDataContainer>
      )}
      {loading && (
        <NoDataContainer>
          {" "}
          <Loader />
        </NoDataContainer>
      )}
      {!loading && error && <NoDataContainer>{error}</NoDataContainer>}
    </StyledTableContainer>
    {handleNext && handlePrev && (
      <Pagination
        handleNextPage={() => handleNext()}
        handlePrevPage={() => handlePrev()}
        prevPage={(prevPage ? true : false) || false }
        nextPage={(nextPage ? true : false) || false }
        pageNumber={nextPage ? (nextPage - 1) : null}
        totalCount={totalCount ? totalCount : null}
      />
    )}
    </>
  );
};

export default CommonTable;
