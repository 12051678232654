import React, { useState } from "react";
import styled from "styled-components";

const OfferCardContaianer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: #212529 !important;
  text-align: left;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

const DataRow = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OfferCard: React.FC = () => {

  return (
    <OfferCardContaianer>
      <TitleRow>
        <Title>Rewards</Title>
      </TitleRow>
      <DataRow>Get up to 10,000 YTP in rewards</DataRow>
    </OfferCardContaianer>
  );
};

export default OfferCard;
