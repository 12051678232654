import * as types from '../../interface/uiModelTypes';
import { store } from '../../redux/store';
import { axiosWrapper } from './axiosWrapper';

export const getStakingOverviewDetails = () => {

    const apiEndpoint: string = `/api/v1/stakes/staking/overview/details/`;

    const token = store.getState().user?.token || '';

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);

}

export async function getStakingRewardsList() {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/stakes/staking-rois/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}

export async function getSocialMediaHistory() {

    const token = store.getState().user?.token || '';
    
    const apiEndpoint: string = `/api/v1/social/activity/history/list/`;

    const request: types.axiosRequest = {
        method: 'get',
        url: apiEndpoint,
        credentials: false,
        headers: {
            'Authorization': `Token ${token}`
        }
    };

    return axiosWrapper(request);
}