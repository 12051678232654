import { Button } from '@mui/material';
import styled from 'styled-components';

export const HeadTitle = styled.div`
  font-family: Poppins;
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
`;

export const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #B11F72; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  text-align: center;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  padding-bottom: 20px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 2.39px;
    background: #F2F2F2;
`;


//Modal 

export const ModalTitle = styled.div`
  color: #212529 !important;
  text-align: center;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

export const MuiButton = styled(Button)`
  background-color: #b11f72 !important;
  color: #fff !important;
  display: block;
  border: none;
  height: 52px;
  min-width: unset !important;
  padding: 10px 2% !important;
  border-radius: 10px !important;
  cursor: pointer;
  text-transform: Capitalize !important;
  font-weight: bold !important;
  font-family: poppins !important;
  font-size: 16px !important;

  @media (max-width: 650px) {
    padding: 10px 5%;
  }

  &:hover {
    background-color: #b11f72 !important;

  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;


export const Container = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: pink;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  color: #b11f72;
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
  background-color: white;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000 !important;

  a {
    display: block;
    cursor: pointer;
    padding: 10px 20px;

    &:hover {
      background-color: #e0e7e9;
    }
  }
`;

//Main Card 

export const CardContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
`;

export const CardTitleRow = styled.div`
  display: flex;  
  padding: 40px 0px;
  justify-content: space-between;

  @media(max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  color: #212529 !important;
  text-align: left;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

export const RedirectLinks = styled.a`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;

`;

export const PaginationButton = styled(Button)`
  && {
    background-color: #b11f72 !important;
    min-width: unset !important;
    padding: 10px !important;
    border-radius: 10px !important;
    box-sizing: border-box !important;

    &:hover {
      background-color: #b11f72 !important;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }
`;

export const PageText = styled.div`
  margin-right: 10px;
  display: inline-block;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #b11f72 !important;
  letter-spacing: 0em;

`;