import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getWalletBalance } from '../../../../uiapi/network/services/walletService';
import { ResponseType } from '../../../../uiapi/interface/uiModelTypes';

const TotalMoneyCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
    color: #212529 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
`;

const HelperText = styled.div`
    color: #878787 !important;
    text-align: left;
    padding-bottom: 20px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
`;

const DataRow = styled.div`
  display: flex;
  padding-top: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DataItem = styled.div`
  flex-basis: calc(20% - 10px);
  margin-bottom: 10px;
  text-align: center;
  color: #878787;
  border-right: 2px solid #F2F2F2;

  &:first-child {
    text-align: left;
  }

  &:nth-child(2) {
    text-align: justify;

  }

  &:nth-child(3) {
    text-align: justify;
  }

  &:last-child {
    text-align: right;
    border-right: unset;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

interface MoneyProps {
  
}

const TotalMoney: React.FC<MoneyProps> = (props) => {
  const [data, setData] = useState<any>({
    lastUpdated: '',
    buyBalance: 0,
    availableBalance: 0,
    lockBalance: 0,
    sellBalance: 0,
  });

  const getWalletDetails = async(ticker: string) => {
    const response:ResponseType = await getWalletBalance(ticker);
    if(response && response?.success && response?.data) {
      setData({
        lastUpdated: response.data?.updated_at,
        buyBalance: response.data?.buy_amount,
        availableBalance: response.data?.balance,
        lockBalance: response.data?.lock_balance,
        sellBalance: response.data?.sell_amount
      });
    }
  }

  useEffect(() => {
    getWalletDetails('ytp');
  }, []);

  return (
    <TotalMoneyCard>
      <TitleRow>
        <Title>Total Balance</Title>
        <HelperText>Last Updated: {data.lastUpdated}</HelperText>
      </TitleRow>
      <DataRow>
        <DataItem>YTP Available balance <div style={{color: "black",fontWeight:'600'}}>{data.availableBalance.toFixed(2)}<span style={{color: '#878787',fontWeight:'400'}}> YTP</span></div></DataItem>
        <DataItem>YTP Lock balance <div style={{color: "black",fontWeight:'600'}}>{data.lockBalance.toFixed(2)}<span style={{color: '#878787',fontWeight:'400'}}> YTP</span></div></DataItem>
        <DataItem>YTP Buy balance <div style={{color: "black",fontWeight:'600'}}>{data.buyBalance.toFixed(2)}<span style={{color: '#878787',fontWeight:'400'}}> YTP</span></div></DataItem>
        <DataItem>YTP Sell balance <div style={{color: "black",fontWeight:'600'}}>{data.sellBalance.toFixed(2)}<span style={{color: '#878787',fontWeight:'400'}}> YTP</span></div></DataItem>
      </DataRow>
    </TotalMoneyCard>
  );
};

export default TotalMoney;
