import React from 'react';
import { Navigate } from "react-router-dom";
import { store } from '../uiapi/redux/store';

interface GuestRouteProps {
    children: React.ReactNode;
  }
  
const GuestRoute: React.FC<GuestRouteProps> = ({ children }) => {
    const isAuthenticated = store.getState()?.user?.token || '';
      
    if (isAuthenticated) {
      return <Navigate to="/dashboard" replace />;
    }
  
    return <>{children}</>;
};

export default GuestRoute;
