import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 0px 3%;
`;

export const LeftGrid = styled.div`
  display: flex;
  flex: 1;
  align-items: center;  
`;

export const Logo = styled.img`
  margin-right: 40px;
  cursor: pointer;
  @media(max-width: 660px) {
    display: none;
  }
`;


export const SmallLogo = styled.img`
  display: none;
  @media(max-width: 660px) {
    display: block;
  }
`;

export const MobileNav = styled.div`
  display: none;
  position: absolute;
  top: 80px;
  right: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  padding-top: 10px;

  @media (max-width: 1323px) {
    display: flex;
  }
`;

export const NavItem = styled.div`
  padding: 10px 10px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer !important;
  color: #333;
  &.active {
    font-weight: bold;
    border-bottom: 2px solid black;
  }
`;

export const RightGrid = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;  
  @media(max-width: 1250px) {
    display: none;
  }
`;

export const CurrencyDisplay = styled.div`
  background-color: #E0E7E9;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  margin-left: 10px;

  @media (max-width: 570px) { 
    //  display: unset;
    pading: 0px 5px;
  }



`;

export const CurrencyDisplayContent = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
  background-color: white;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;

  a {
    display: block;
    cursor: pointer;
    padding: 10px 20px;

    &:hover {
      background-color: #E0E7E9;
    }
  }
`;

export const NotificationBell = styled.img`
  cursor: pointer;
  @media(max-width: 460px) {
    display: none;
  }
`;

export const Divider = styled.div`
  width: 2px;
  height: 40px;
  background-color: #E0E7E9;
  margin: 0px 20px;

  @media(max-width: 570px) {
    display: none;
  }
`

export const ProfilePic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 50%;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none !important;
  }

  @media(max-width: 570px) {
    width: 30px;
    margin-left: 10px;
    height: 30px;
  }
`;

export const ArrowIcon = styled.div`
    @media(max-width: 570px) {
      display: none;
    } 
`;


export const UserName = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 5px;
    @media(max-width: 570px) {
      display: none;
    }

  }

  @media(max-width: 570px) {
    margin-left: unset;
  }
  
`;

export const UserDropdown = styled.div`
  position: absolute;
  top: 60px;
  background-color: white;
  color: #333;
  width: 200px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;

  a {
    padding: 10px 20px;
    cursor: pointer;
    display: block;
    border-radius: 8px;

    &:hover {
      background-color: #FFD4ED;
      color: #B11F72;
    }
  }

  @media(max-width: 600px) {
    right: 5px;
  }
  
`;

export const KebabMenu = styled.div`
  cursor: pointer;
  margin-left: 10px;
  display: flex;

  @media (min-width: 1250px) {
    display: none;
  }
`;
