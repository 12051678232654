import React, { useEffect, useState } from 'react';
import { CarouselContainer, NavigationButton, Image, Slide } from './Carousel.styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// Add interfaces for props
interface CarouselProps {
  images: string[]; // Array of image URLs
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % images.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + images.length) % images.length);
  };

  useEffect(()=> {

    const interval = setInterval(()=> {
      handleNext();
    }, 3000);

    return () => {
      clearInterval(interval);
    }

  },[images]);

  return (
    <CarouselContainer>
      <NavigationButton onClick={handleBack}> <ArrowBackIosNewIcon /></NavigationButton>
      <Slide>
        <Image src={images[activeStep]} alt={`Slide ${activeStep + 1}`} />
      </Slide>
      <NavigationButton onClick={handleNext}><ArrowForwardIosIcon /></NavigationButton>      
    </CarouselContainer>
  );
};

export default Carousel;
