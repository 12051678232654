import React, { useState } from "react";
import styled from "styled-components";
import { Backdrop, Button, Divider, Fade, Modal } from "@mui/material";
import { getClaimNow } from "../../../../uiapi/network/services/utilService";
import { ResponseType } from "../../../../uiapi/interface/uiModelTypes";
import CrossIcon from '../../../../assets/images/Cross.svg';
import { useNavigate } from "react-router-dom";
interface OffersDataProps {
  title: string;
  subtitle: string;
  imageUrl: string;
  buttonText: string;
  offerId: number;
}

const CardContainer = styled.div`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  min-height: 250px;
  font-family: Poppins;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: end;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const Image = styled.img`
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  margin-right: 20px;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;

  &:last-child {
    justify-content: end;
  }
`;

const Title = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const MuiButton = styled(Button)`
  justify-content: end !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  cursor: pointer;
  font-size: 16px !important;
  display: flex !important;
  background-color: #b11f72 !important;
  color: white !important;
  border: none;
  font-weight: bold !important;
  text-transform: capitalize !important;

  &:hover {
    background-color: #b11f72 !important;
    
  }
`;

const Description = styled.div`
  display: flex;
  padding: 30px;

`;

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 12px 23px 0px #3e49540a;
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 8px;
`;

const OffersData: React.FC<OffersDataProps> = ({
  title,
  subtitle,
  imageUrl,
  buttonText,
  offerId
}) => {

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState(false);

  const handleClaimNow = async () => {
    try {
      const response:ResponseType = await getClaimNow(offerId);

      if(response && response?.success && response?.data) {
        setOpen(true);
        setDescription(response.data?.content || '');
      }
    } catch(err) {

    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  function handleInvite() {
    navigate('/referral');
  }

  return (
    <>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <ModalBox style={{width: '30%' }}>
            <img
              src={CrossIcon}
              onClick={handleClose}
              style={{ cursor: "pointer", position: "absolute", right: 10}}
            />
            <Title> Claim Process</Title>
            <Divider style={{marginTop: '20px'}} />
            <div>
              <Description>
                {description}
              </Description>
              <MuiButton style={{ float: "right" }} onClick={() => handleInvite()}>
                  Invite More
              </MuiButton>
            </div>
          </ModalBox>
        </Fade>
    </Modal>
    <CardContainer>
      <TopContainer>
        <Image src={imageUrl} alt="Offer" />
        <TextContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextContainer>
      </TopContainer>
      <BottomContainer>
        <MuiButton onClick={handleClaimNow}>{buttonText}</MuiButton>
      </BottomContainer>
    </CardContainer>
    </>
  );
};

export default OffersData;
