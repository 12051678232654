import { Grid } from "@mui/material";
import React from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import CommonTable from "../../common/CommonTable/CommonTable";
import { TicketHistory } from "../../../utils/utils";
import Ticket from "./Cards/Ticket";
import CreateTicket from "./Cards/CreateTicket";

export default function SupportCenter() {
    return(
        <Grid container>
            <Grid item xs={12}>
                <HeadTitle>Support Center</HeadTitle>
            </Grid>
            <Grid item xs={12}>
                <Ticket />   
            </Grid>
            <Grid item xs={12}>
                <CommonTable title='Tickets History' fields={TicketHistory} data={[]} />
            </Grid>
            <Grid item xs={12}>
                <CreateTicket />
            </Grid>
        </Grid>
    )
}