import React, { useState } from "react";
import './form.css';
import { Button, TextField, CircularProgress, Typography, IconButton, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CreatePasswordData, ResponseType } from "../../../uiapi/interface/uiModelTypes";
import { createPassword } from "../../../uiapi/network/services/userService";
import { isValidPassword } from "../Validation/validation";
import { useDispatch } from "react-redux";
import { setToken, setUserData } from "../../../uiapi/redux/actions/userActions";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface FormData {
    password: string;
    confirm_password: string;
}

interface CreatePasswordProps {
    email: string
}

export default function CreatePassword(props: CreatePasswordProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<FormData>({
        confirm_password: "",
        password: ""
    });
    const [backendError, setBackendError] = useState(false);

    const [loading, setLoading] = useState(false); // New state for loading
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({
        password: "",
        confirm_password: ""
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Clear validation error when user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        setBackendError(false);
    };

    const validateForm = (): boolean => {
        let isValid = true;
        const newErrors = {
            password: "",
            confirm_password: ""
        };

        if (!isValidPassword(formData.password)) {
            newErrors.password = "Invalid password format";
            isValid = false;
        }

        if (formData.password !== formData.confirm_password) {
            newErrors.confirm_password = "Passwords do not match";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setBackendError(false);

        if (validateForm()) {
            const passData: CreatePasswordData = {
                email: props.email || '',
                password: formData.password,
                password2: formData.confirm_password
            }

            try {
                setLoading(true); // Set loading to true when creating password
                const userData: ResponseType = await createPassword(passData);

                if (userData && userData?.success && userData?.data) {
                    dispatch(setToken(userData?.data?.token));
                    dispatch(setUserData(userData?.data?.user))
                    navigate('/dashboard');
                } else {
                    setBackendError(true);
                }
            } catch (error) {
                console.error("An error occurred during creating password:", error);
                // Handle other errors, if needed
            } finally {
                setLoading(false); // Set loading to false after the request completes
            }
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <TextField
                className="text-field"
                label="Create a password"
                variant="outlined"
                fullWidth
                margin="dense"
                name="password"
                type={showPassword ? "text" : "password"} 
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{ // Add InputProps to include the icon button
                    endAdornment: (
                        <IconButton onClick={togglePasswordVisibility} edge="end" tabIndex={-1}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    ),
                }}
            />
            <TextField
                className="text-field"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                margin="dense"
                type={showPassword ? "text" : "password"} 
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                error={!!errors.confirm_password}
                helperText={errors.confirm_password}
                InputProps={{ // Add InputProps to include the icon button
                    endAdornment: (
                        <IconButton onClick={togglePasswordVisibility} edge="end" tabIndex={-1}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    ),
                }}
            />
            <Button type="submit" variant="contained" fullWidth className="login-button" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Password'}
            </Button>
            {backendError && <Alert color="error" > Something went wrong!</Alert>}
        </form>
    );
}
