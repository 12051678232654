import { ACTIVE_CURRENCY, FIAT_RATE, SET_PIN_STATUS, SET_TOKEN, SET_USER_DATA } from "../types";

export const setUserData = (data: any) => {
    return {
        type: SET_USER_DATA,
        payload: data
    }
} 

export const setPinStatus = (data: boolean) => {
    return {
        type: SET_PIN_STATUS,
        payload: data
    }
} 

export const setToken = (data: string) => {
    return {
        type: SET_TOKEN,
        payload: data
    }
} 

export const setActiveCurrencyInStore = (data: string) => {
    return {
        type: ACTIVE_CURRENCY,
        payload: data
    }
} 

export const setFiatRates = (data: any) => {
    return {
        type: FIAT_RATE,
        payload: data
    }
} 