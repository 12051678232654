import React, { useEffect, useState } from "react";
import styled from "styled-components";
import QR from "../../../../assets/images/QRcode.svg";
import {
  Modal,
  Backdrop,
  Fade,
  Alert,
} from "@mui/material";
import CrossIcon from '../../../../assets/images/Cross.svg';
import { MuiButton } from "../../../common/Styles/CommonStyles";

export const CardContainer = styled.div`
  padding: 20px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-top: 20px;
`;

export const Title = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
`;

export const SubTitle = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: #878787;
  margin-top: 5px;
`;

export const Button = styled(MuiButton)`
  && {
    padding: 10px 20px !important;
    margin-top: 30px !important;
  }
`;

export const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 12px 23px 0px #3e49540a;
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 8px;
`;

export const Container = styled.div`
  padding: 20px 0px;
  // min-width: 500px;
  position: relative;

  @media (max-width: 600px) {
    min-width: unset;
  }
`;

export const TwoFactor = styled.div`
  display: flex;
  align-items: end;

  div {
    padding: 0px 20px;

    span {
      display: inline-block;
      padding-bottom: 10px;
    }

    input {
      width: 250px;
      height: 40px;
    }
  }
`;

interface AccountCardProps {
  title: string;
  description: string;
  buttonTitle: string;
  component: any;
  handleButton?: () => void;
  close?:boolean,
  success?: string
}

export default function AccountCard(props: AccountCardProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if(props.handleButton) {
      props.handleButton(); 
    }
  };

  useEffect(()=> {
    if(props.close) {
      handleClose();
    }
  },[props?.close, props?.success]);

  return (
    <>
      <CardContainer>
      {props.success && <Alert  style={{ textAlign: 'center', marginTop: '10px' }} color="success"> {props.success} </Alert>}

        <Title>{props.title}</Title>
        <SubTitle>{props.description}</SubTitle>
        <Button onClick={handleOpen}>{props.buttonTitle}</Button>
      </CardContainer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <ModalBox>
            <img
              src={CrossIcon}
              onClick={handleClose}
              style={{ cursor: "pointer", position: "absolute", right: 10 }}
            />
            <Title>{props.title}</Title>
            <Container>{props.component}</Container>
            {props.title !== "Two factor Verification" ? (
              <Button style={{ float: "right" }} onClick={() => handleConfirm()}>
                Confirm
              </Button>
            ) : (
              <TwoFactor>
                <img src={QR} />
                <div>
                  <span>Enter your 2FA code</span>
                  <input type="text" placeholder="Enter code" />
                </div>
                <Button onClick={() => setOpen(false)}>Confirm</Button>
              </TwoFactor>
            )}
          </ModalBox>
        </Fade>
      </Modal>
    </>
  );
}
