import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './reducers/userReducers';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { SET_USER_DATA } from './types';
import thunk from 'redux-thunk';
import { createStateSyncMiddleware } from 'redux-state-sync';
import transactionReducer from './reducers/transactionReducers';

// Define your root reducer
const rootReducer = combineReducers({
  user: userReducer,
  transaction: transactionReducer
});

// Define your persistConfig
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

// Define your stateSyncConfig
const stateSyncConfig = {
  whitelist: [SET_USER_DATA],
};

// Define your middleware array
const middleware = [thunk, createStateSyncMiddleware(stateSyncConfig)];

// Define your persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create your store
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

// Log when the state is being persisted
persistStore(store, null, () => {
  console.debug('Redux state persisted');
});

export { store };
