import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getStakingOverviewDetails } from "../../../../uiapi/network/services/stakingService";
import { ResponseType } from "../../../../uiapi/interface/uiModelTypes";

const StalkingCardContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px 25px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: #212529 !important;
  text-align: left;
  padding-bottom: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
`;

const DataRow = styled.div`
  display: flex;
  padding-top: 40px;
  flex-wrap: wrap;
`;

const DataItem = styled.div`
  margin-right: 100px;
  text-align: left;
  color: #878787;
`;

interface StakingOverview {
  Subscribers: number,
  'Total Value Locked': number

}

const StalkingCard: React.FC = () => {
  const [data, setData] = useState<StakingOverview>({
    Subscribers: 0,
    'Total Value Locked': 0
  });

  const fetchdata = async() => {
    const stakingOverviewDetails:ResponseType = await getStakingOverviewDetails();

    if(stakingOverviewDetails && stakingOverviewDetails?.success && stakingOverviewDetails?.data) {
      setData({
        Subscribers: stakingOverviewDetails.data.subscribers || 0,
        'Total Value Locked': stakingOverviewDetails.data.total_value_locked || 0
      });
    }
  }

  useEffect(()=> {
    fetchdata();
  },[]);

  return (
    <StalkingCardContainer>
      <TitleRow>
        <Title>Total YatriPay Savings</Title>
      </TitleRow>
      <DataRow>
        <DataItem>
          Subscribers{" "}
          <div style={{ color: "black", fontWeight: "600" }}>
            {data.Subscribers}
          </div>
        </DataItem>
        <div style={{backgroundColor: '#F2F2F2', width: '2px', height: '40px', marginRight: '70px'}}>
        </div>
        <DataItem>
          Total Value Locked (TVL){" "}
          <div style={{ color: "black", fontWeight: "600" }}>
            {data["Total Value Locked"]}
            <span style={{ color: "#878787", fontWeight: "400" }}> YTP</span>
          </div>
        </DataItem>
      </DataRow>
    </StalkingCardContainer>
  );
};

export default StalkingCard;
