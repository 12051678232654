import { Grid } from "@mui/material";
import React from "react";
import { HeadTitle } from "../../common/Styles/CommonStyles";
import TaskCard from "../OffersTask/Tasks/TaskCard";

export default function OffersTask() {

  return (
    <Grid container>
      <Grid item xs={12}>
        <HeadTitle>Tasks</HeadTitle>
      </Grid>
      <Grid item xs={12}>
        <TaskCard />   
      </Grid>
    </Grid>
  );
}
