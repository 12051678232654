import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import CommonTab2 from '../../../common/CommonTabs/CommonTab2';
import CommonTable2 from '../../../common/CommonTable2/CommonTable';
import { SwapHistoryFields } from '../../../../utils/utils';
import { getSwapHistoryList } from '../../../../uiapi/network/services/utilService';
import { FormattedSwapHistory } from '../../../../uiapi/interface/uiModelTypes';
import { SwapTab } from './SwapTab';


const SwapHistoryContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
`;

const TitleRow = styled.div`
  display: flex;
  padding: 20px 25px;

  justify-content: space-between;
`;

const SwapHistoryCard: React.FC = () => {
  const tabNames = ['Swap', 'History'];
  const [historyData, setHistoryData] = useState<FormattedSwapHistory[]>([]);

  const getTableData = async() => {
    const historyData = await getSwapHistoryList();

    if(historyData && historyData?.data?.length > 0) {
      const formattedData: FormattedSwapHistory[] = [];
      
      historyData.data.map((data: any) => {
        formattedData.push({
            'Date/Time': data.created_at,
            'Order ID': data?.order_number,
            Pair: data?.pair,
            Type: data?.type,
            Sell: data?.sell,
            Amount: data?.sell_amount,
            Buy: data.buy,
            Price: data.price,
            Fees: data.fees,
            Status: data?.status ? "Completed" : "Failed"
        })

      })
      setHistoryData(formattedData);
    }
  }

  useEffect(()=> {
    getTableData();
  },[])

  return (
    <SwapHistoryContainer>
      <TitleRow>
      </TitleRow>
      <CommonTab2 tabNames={tabNames} tabComponents={[<SwapTab />,<CommonTable2 fields={SwapHistoryFields} data={historyData} />]} />
    </SwapHistoryContainer>
  );
};


export default SwapHistoryCard;
